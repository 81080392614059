/**
 * IMPORTS
 */
import {createBrowserHistory} from 'history';


/**
 * EXPORTS
 */
export default createBrowserHistory({
    basename: '/pecazap',
});
