/**
 * TYPES
 */
import {IAction} from 'src/contexts/index.d';


/**
 * EXPORTS
 */

/**
 * Actions types.
 */
export enum types
{
    SHOW_CONTACTS_SIDEBAR = '@screen/SHOW_CONTACTS_SIDEBAR',
    HIDE_CONTACTS_SIDEBAR = '@screen/HIDE_CONTACTS_SIDEBAR',
    SHOW_INFO_SIDEBAR = '@screen/SHOW_INFO_SIDEBAR',
    HIDE_INFO_SIDEBAR = '@screen/HIDE_INFO_SIDEBAR',
    SHOW_CUSTOMER_SIDEBAR = '@screen/SHOW_CUSTOMER_SIDEBAR',
    HIDE_CUSTOMER_SIDEBAR = '@screen/HIDE_CUSTOMER_SIDEBAR',
    SHOW_PROFILE = '@screen/SHOW_PROFILE',
    HIDE_PROFILE = '@screen/HIDE_PROFILE',
    SHOW_SIDEBAR = '@screen/SHOW_SIDEBAR',
    HIDE_SIDEBAR = '@screen/HIDE_SIDEBAR',
}

export interface IShowContactsSidebar extends IAction
{
    type: typeof types.SHOW_CONTACTS_SIDEBAR
}

export interface IHideContactsSidebar extends IAction
{
    type: typeof types.HIDE_CONTACTS_SIDEBAR
}

export interface IShowInfoSidebar extends IAction
{
    type: typeof types.SHOW_CUSTOMER_SIDEBAR
}

export interface IHideInfoSidebar extends IAction
{
    type: typeof types.HIDE_CUSTOMER_SIDEBAR
}

export interface IShowProfile extends IAction
{
    type: typeof types.SHOW_PROFILE
}

export interface IHideProfile extends IAction
{
    type: typeof types.HIDE_PROFILE
}

export interface IShowSidebar extends IAction
{
    type: typeof types.SHOW_SIDEBAR
}

export interface IHideSidebar extends IAction
{
    type: typeof types.HIDE_SIDEBAR
}

export type IScreenActions = IShowContactsSidebar |
                             IHideContactsSidebar |
                             IShowInfoSidebar |
                             IHideInfoSidebar |
                             IShowCustomerSidebar |
                             IHideCustomerSidebar |
                             IShowProfile |
                             IHideProfile |
                             IShowSidebar |
                             IHideSidebar
