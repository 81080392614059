/**
 * EXPORTS
 */
export enum loginErrors
{
    AUTHENTICATION = 'authentication',
    AUTHORIZATION = 'authorization',
    UNAVAILABLE = 'unavailable',
    UNKNOWN = 'unknown'
}

export enum Statuses
{
    AWAY = 'away',
    DISCONNECTED = 'disconnected',
    ONLINE = 'online'
}

export interface INotification extends NotificationOptions
{
    title: string
}

export interface ICompanyConfig
{
    visibility: {
        customers: {
            noWallet: boolean;
            onlyOwn: boolean;
        }
    }
}

export interface IConfig
{
    answerAlert: number;
    messages: IQuickMessage[];
}

export interface IQuickMessage
{
    body: string;
    key: string;
}

export interface IPopupMessage
{
    level: string;
    message: string;
    title: string;
}

export interface IUserState
{
    company: number;
    companyConfig: ICompanyConfig;
    config: IConfig;
    hasLoadConfigError: boolean;
    hasStartError: boolean;
    hasUpdateConfigError: boolean;
    id: string;
    isAdmin: boolean;
    isAuthorized: boolean;
    isAuthorizing: boolean;
    isLoadingConfig: boolean;
    isStarting: boolean;
    isUpdatingConfig: boolean;
    loginError: loginErrors | null;
    name: string;
    notification: INotification | null;
    organization?: string;
    password: string;
    popup: IPopupMessage | null;
    photo?: string;
    rooms: string[];
    serverUrl: string;
    started: boolean;
    status: Statuses;
    username: string;
}
