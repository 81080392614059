/**
 * IMPORTS
 */
import React from 'react';
import {useEffect} from 'react';
import Metadata from 'src/config/Metadata';
import Routes from 'src/config/routes';
import {createLogger} from 'src/logger';
import 'antd/dist/antd.css';
import 'draft-js/dist/Draft.css';
import 'src/components/style.scss';

/**
 * CODE
 */

// load config before app
Metadata.load().then(() =>
{
    // initialize logger
    createLogger(Metadata.config.logger);
})

/**
 * I render the App component.
 *
 * :returns: App component
 */
function App ()
{
    // return App component
    return (
        <Routes />
    );
}


/**
 * EXPORTS
 */
export default App;
