/**
 * TYPES
 */
import {IAccountState} from 'src/aggregates/accounts/state.d';


/**
 * CONSTANTS AND DEFINITIONS
 */
const initialState: IAccountState = {
    byId: {},
    channels: {
        byType: {},
        hasLoadingError: false,
        isLoading: false,
    },
    hasError: false,
    isLoading: false,
    templates: {},
};


/**
 * EXPORTS
 */
export default initialState;
