/**
 * IMPORTS
 */
import {roundToNearestMinutes} from 'date-fns';


/**
 * CONSTANTS AND DEFINITIONS
 */
const ROUNDED_MINUTES = 30;


/**
 * CODE
 */

/**
 * I round dates
 *
 * :param date: date in milliseconds
 * :param round: time to round in minutes
 *
 * :returns: rounded date in milliseconds
 */
function getRoundedDate(date: number, round?: number): number
{
    // set options
    const options = {nearestTo: round ?? ROUNDED_MINUTES};

    // return rounded date
    return roundToNearestMinutes(new Date(date), options).getTime();
}


/**
 * EXPORTS
 */
export default getRoundedDate;
