/**
 * IMPORTS
 */
import {ApplicationError} from 'src/aggregates/err/applicationerror';


/**
 * CODE
 */

/**
 * I am a login with token error code error.
 */
class LoginTokenError extends ApplicationError
{
}


/**
 * EXPORTS
 */
export {
    LoginTokenError,
};
