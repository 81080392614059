/**
 * IMPORTS
 */
import {gql} from '@apollo/client';


/**
 * EXPORTS
 */

/**
 * I am a query to seek the company's configuration
 */
export const FETCH_COMPANY_CONFIG = gql`
query CompanyConfig {
    companyConfig {
        __typename
        ...on CompanyConfig {
            dashboards {
                agents
                conversations
                login
                nps
                realtime
            }
        }
        ...on Error {
            code
            info
        }
    }
}
`;


/**
 * I am a query fetch user config
 */
export const FETCH_USER_CONFIG = gql`
query UserConfig {
    userConfig {
        __typename
        ...on UserConfig {
            answerAlert
            messages {
                key
                body
            }
        }
        ...on Error {
            code
            info
        }
    }
}
`;
