/**
 * IMPORTS
 */
import {Progress} from 'antd';
import React from 'react';
import {useEffect} from 'react';
import {useCountUp} from 'react-countup';
import * as Styles from 'src/components/Shared/LoadingProgress/styles';


/**
 * TYPES
 */
import {ILoadingProgressProps}
    from 'src/components/Shared/LoadingProgress/index.d';


/**
 * CODE
 */

/**
 * I render the Loading Progress component.
 *
 * :param props: necessary component props
 *
 * :returns: Loading Progress component
 */
function LoadingProgress({
    duration = 0.5,
    info,
    percent,
    start = 0,
}: ILoadingProgressProps): JSX.Element
{
    // get counter helper
    const counter = useCountUp({
        duration,
        end: start,
        start,
    });

    // update counter on progress change
    useEffect((): void =>
    {
        counter.update(percent);
    }, [percent]);

    // return Loading Progress component
    return (
        <Styles.Container>
            <Progress
                percent={Number(counter.countUp)}
                strokeColor={{
                    0: '#00A7CF',
                    100: '#CCDC29',
                }}
                type="circle"
            />
            {info && <Styles.Info>{info}</Styles.Info>}
        </Styles.Container>
    );
}


/**
 * EXPORTS
 */
export default LoadingProgress;
