/**
 * IMPORTS
 */
import {gql} from '@apollo/client';


/**
 * I am a mutation to create NPS feedback
 */
export const CREATE_NPS_FEEDBACK = gql`
    mutation createNPSFeedback(
        $company: Int!
        $date: String!
        $name: String!
        $response: String
        $role: String!
        $score: Int
        $server: String!
        $user: String!
    ) {
        createNPSFeedback(
            company: $company,
            date: $date,
            name: $name,
            response: $response,
            role: $role,
            score: $score,
            server: $server,
            user: $user
        ) {
            id
        }
    }
`;


/**
 * I am a mutation to update user config
 */
export const UPDATE_USER_CONFIG = gql`
    mutation updateUserConfig($input: UserConfigInput!) {
        updateUserConfig(input: $input) {
            __typename
            ...on UpdateUserConfig {
                answerAlert
                messages {
                    body
                    key
                }
            }
            ...on Error {
                code
                info
            }
        }
    }
`;

