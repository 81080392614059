/**
 * TYPES
 */
import {IChannelByType} from 'src/aggregates/accounts/state.d';
import {IDefaultTemplate} from 'src/aggregates/accounts/state.d';
import {ITemplate} from 'src/aggregates/accounts/state.d';
import {ITemplateConfig} from 'src/aggregates/accounts/state.d';
import {IEvent} from 'src/aggregates/index.d';
import {_aggregates} from 'src/aggregates/index.d';


/**
 * EXPORTS
 */

/**
 * Events types.
 */
export enum types
{
    ACCOUNT_ADDED = 'ACCOUNT_ADDED',
    ACCOUNT_CHANNELS_LOADED = 'ACCOUNT_CHANNELS_LOADED',
    ACCOUNT_CHANNELS_LOADING = 'ACCOUNT_CHANNELS_LOADING',
    ACCOUNT_CHANNELS_NOT_LOADED = 'ACCOUNT_CHANNELS_NOT_LOADED',
    ACCOUNT_LOADED = 'ACCOUNT_LOADED',
    ACCOUNT_LOADING = 'ACCOUNT_LOADING',
    ACCOUNT_NOT_LOADED = 'ACCOUNT_NOT_LOADED',
    ACCOUNT_TEMPLATES_LOADED = 'ACCOUNT_TEMPLATES_LOADED',
}


/**
 * Events interfaces.
 */
export interface IAccountAdded extends IEvent
{
    _aggregate: _aggregates.ACCOUNTS;
    _name: types.ACCOUNT_ADDED;
    channel: string;
    configuredTemplates: ITemplateConfig[];
    defaultTemplate: IDefaultTemplate | null;
    name: string;
    uid: string;
    type: types.ACCOUNT_ADDED;
}

export interface IAccountChannelsLoaded extends IEvent
{
    _aggregate: _aggregates.ACCOUNTS;
    _name: types.ACCOUNT_CHANNELS_LOADED;
    channels: IChannelByType;
    type: types.ACCOUNT_CHANNELS_LOADED;
}

export interface IAccountChannelsLoading extends IEvent
{
    _aggregate: _aggregates.ACCOUNTS;
    _name: types.ACCOUNT_CHANNELS_LOADING;
    type: types.ACCOUNT_CHANNELS_LOADING;
}

export interface IAccountChannelsNotLoaded extends IEvent
{
    _aggregate: _aggregates.ACCOUNTS;
    _name: types.ACCOUNT_CHANNELS_NOT_LOADED;
    type: types.ACCOUNT_CHANNELS_NOT_LOADED;
}

export interface IAccountLoaded extends IEvent
{
    _aggregate: _aggregates.ACCOUNTS;
    _name: types.ACCOUNT_LOADED;
    type: types.ACCOUNT_LOADED;
}

export interface IAccountLoading extends IEvent
{
    _aggregate: _aggregates.ACCOUNTS;
    _name: types.ACCOUNT_LOADING;
    type: types.ACCOUNT_LOADING;
}

export interface IAccountNotLoaded extends IEvent
{
    _aggregate: _aggregates.ACCOUNTS;
    _name: types.ACCOUNT_NOT_LOADED;
    type: types.ACCOUNT_NOT_LOADED;
}

export interface IAccountTemplatesLoaded extends IEvent
{
    _aggregate: _aggregates.ACCOUNTS;
    _name: types.ACCOUNT_TEMPLATES_LOADED;
    filteredTemplates: ITemplate[];
    templates: ITemplate[];
    type: types.ACCOUNT_TEMPLATES_LOADED;
}
