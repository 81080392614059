/**
 * IMPORTS
 */
import {FETCH_CONTACT_TIMELINE} from 'src/aggregates/timelines/queries';
import {FETCH_CONVERSATION_MESSAGES} from 'src/aggregates/timelines/queries';
import graphis from 'src/services/graphis';
import Store from 'src/store';


/**
 * TYPES
 */
import {IFetchContactTimelineResponse}
    from 'src/aggregates/timelines/queries.d';
import {IFetchConversationMessagesResponse}
    from 'src/aggregates/timelines/queries.d';
import {IMessage as IGraphisMessage} from 'src/aggregates/timelines/queries.d';
import {ITimelineConversation} from 'src/aggregates/timelines/state.d';
import {ITimelineConversationWithContact}
    from 'src/aggregates/timelines/utils.d';


/**
 * CODE
 */

/**
 * I get a contact timeline conversations.
 *
 * :param account: contact account
 * :param company: contact company
 * :param uid: contact uid
 *
 * :returns: promise with conversations on timeline or null
 */
async function getContactTimeline (
    account: number,
    company: number,
    uid: string,
): Promise<ITimelineConversationWithContact[] | null>
{
    // initialize response
    let response: IFetchContactTimelineResponse;

    // fetch contact timeline
    try
    {
        response = await graphis.query(
            FETCH_CONTACT_TIMELINE,
            {account, company, uid},
        );
    }

    // fetch failed: log and return null
    catch (e)
    {
        console.error(e); // eslint-disable-line no-console
        return null;
    }

    // return contact timeline including account, remote and calculating end
    return response.data.contactTimeline.map(timeline => ({
        ...timeline,
        account,
        end: timeline.end * 1000 || null,
        remote: uid,
    }));
}


/**
 * I get a conversation of timeline array given its id.
 *
 * :param id: conversation id
 *
 * :returns: conversation or undefined
 */
function getConversation (id: number): ITimelineConversation | undefined
{
    // get timelines state
    const {timeline} = Store.getState();

    // get timeline array
    const {timeline: conversations} = timeline;

    // return conversation
    return conversations.find((item) => item.id === id);
}


/**
 * I get timeline array index given a conversation id.
 *
 * :param id: conversation id
 * :param conversations: timeline conversations array
 *
 * :returns: timeline array index or -1 on conversation not found
 */
function getIndex (id: number, conversations: ITimelineConversation[]): number
{
    return conversations.findIndex((conversation) => conversation.id === id);
}


/**
 * I get a conversation messages.
 *
 * :param company: company id
 * :param id: conversation id
 *
 * :returns: Promise with messages array or null
 */
async function getMessages (company: number,
                            id: number): Promise<IGraphisMessage[] | null>
{
    // initialize response
    let response: IFetchConversationMessagesResponse;

    // fetch messages
    try
    {
        response = await graphis.query(
            FETCH_CONVERSATION_MESSAGES,
            {company, id},
        );
    }

    // fetch failed: log and return null
    catch (e)
    {
        console.error(e); // eslint-disable-line no-console
        return null;
    }

    // fetch failed: return null
    if (response.data.conversation === null)
    {
        return null;
    }

    // fetch succeeded: return conversation messages
    return response.data.conversation.messages;
}


/**
 * EXPORTS
 */
export {
    getContactTimeline,
    getConversation,
    getIndex,
    getMessages,
};
