/**
 * TYPES
 */
import {IAgentState} from 'src/aggregates/agents/state.d';


/**
 * CODE
 */

/**
 * Agents initial state.
 */
const initialState: IAgentState = {
    byId: {},
    hasLoadError: false,
    isLoading: false,
    metrics: {
        end: null,
        hasLoadError: false,
        isLoading: false,
        login: {
            byAgent: {},
            byDate: {},
            statusChanges: [],
        },
        start: null,
    },
};


/**
 * EXPORTS
 */
export default initialState;
