/**
 * TYPES
 */
import {IActionsMap} from 'src/contexts/index.d';
import {IAction} from 'src/contexts/index.d';
import {Reducer} from 'src/contexts/index.d';


/**
 * CODE
 */

/**
 * I create a reducer with provided actions map.
 *
 * :param actionsMap: IActionsMap
 *
 * :returns: Reducer
 */
function createReducer<StateType, ActionsType extends IAction>(
    actionsMap: IActionsMap<StateType, ActionsType>,
): Reducer<StateType, ActionsType>
{
    return (state, action) =>
    {
        // get handler by action type
        const reducer = actionsMap[action.type];


        // check if has a handle to the action type
        if (reducer === undefined)
        {
            return state;
        }

        // return edited state
        return reducer(state, action);
    };
}


export default createReducer;
