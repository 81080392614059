/**
 * IMPORTS
 */
import {ApplicationError} from 'src/aggregates/err/applicationerror';


/**
 * CODE
 */

/**
 * I am a pre-authentication error code error.
 */
class PreAuthenticationError extends ApplicationError
{
}


/**
 * EXPORTS
 */
export {
    PreAuthenticationError,
};
