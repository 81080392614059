/**
* TYPES
*/
import {_aggregates} from 'src/aggregates/index.d';
import {IEvent} from 'src/aggregates/index.d';
import {ISurvey} from 'src/aggregates/surveys/state.d';
import {ISurveyAnswer} from 'src/aggregates/surveys/state.d';


/**
* EXPORTS
*/
export enum types
{
    SURVEYS_ANSWERS_LOADED = 'SURVEYS_ANSWERS_LOADED',
    SURVEYS_ANSWERS_LOADING = 'SURVEYS_ANSWERS_LOADING',
    SURVEYS_ANSWERS_NOT_LOADED = 'SURVEYS_ANSWERS_NOT_LOADED',
    SURVEYS_LOADED = 'SURVEYS_LOADED',
    SURVEYS_LOADING = 'SURVEYS_LOADING',
    SURVEYS_NOT_LOADED = 'SURVEYS_NOT_LOADED',
    SURVEYS_PERIOD_SET = 'SURVEYS_PERIOD_SET',
}

export interface ISurveysAnswersLoaded extends IEvent
{
    _aggregate: _aggregates.SURVEYS;
    _name: types.SURVEYS_ANSWERS_LOADED;
    answers: ISurveyAnswer[];
    nps: number;
    total: number;
    promoter: number;
    neutral: number;
    detractor: number;
    type: types.SURVEYS_ANSWERS_LOADED;
}

export interface ISurveysAnswersLoading extends IEvent
{
    _aggregate: _aggregates.SURVEYS;
    _name: types.SURVEYS_ANSWERS_LOADING;
    type: types.SURVEYS_ANSWERS_LOADING;
}

export interface ISurveysAnswersNotLoaded extends IEvent
{
    _aggregate: _aggregates.SURVEYS;
    _name: types.SURVEYS_ANSWERS_NOT_LOADED;
    type: types.SURVEYS_ANSWERS_NOT_LOADED;
}

export interface ISurveysLoaded extends IEvent
{
    _aggregate: _aggregates.SURVEYS;
    _name: types.SURVEYS_LOADED;
    surveys: ISurvey[];
    type: types.SURVEYS_LOADED;
}

export interface ISurveysLoading extends IEvent
{
    _aggregate: _aggregates.SURVEYS;
    _name: types.SURVEYS_LOADING;
    type: types.SURVEYS_LOADING;
}

export interface ISurveysNotLoaded extends IEvent
{
    _aggregate: _aggregates.SURVEYS;
    _name: types.SURVEYS_NOT_LOADED;
    type: types.SURVEYS_NOT_LOADED;
}

export interface ISurveysPeriodSet extends IEvent
{
    _aggregate: _aggregates.SURVEYS;
    _name: types.SURVEYS_PERIOD_SET;
    end: number;
    start: number;
    type: types.SURVEYS_PERIOD_SET;
}
