/**
* TYPES
*/
import {_aggregates} from 'src/aggregates/index.d';
import {IEvent} from 'src/aggregates/index.d';
import {IReducedGraphisTag} from 'src/aggregates/tags/handlers.d';


/**
* EXPORTS
*/
export enum types
{
    TAG_CREATED = 'TAG_CREATED',
    TAG_CREATING = 'TAG_CREATING',
    TAG_LOADED = 'TAG_LOADED',
    TAG_LOADING = 'TAG_LOADING',
    TAG_NOT_CREATED = 'TAG_NOT_CREATED',
    TAG_NOT_LOADED = 'TAG_NOT_LOADED',
    TAG_NOT_UPDATED = 'TAG_NOT_UPDATED',
    TAG_UPDATED = 'TAG_UPDATED',
    TAG_UPDATING = 'TAG_UPDATING',
    TAGS_ERROR_RESET = 'TAGS_ERROR_RESET',
}

export interface ITagCreated extends IEvent
{
    _aggregate: _aggregates.TAGS;
    _name: types.TAG_CREATED;
    color: string;
    company: number;
    name: string;
    type: types.TAG_CREATED;
}


export interface ITagCreating extends IEvent
{
    _aggregate: _aggregates.TAGS;
    _name: types.TAG_CREATING;
    type: types.TAG_CREATING;
}

export interface ITagLoading extends IEvent
{
    _aggregate: _aggregates.TAGS;
    _name: types.TAG_LOADING;
    type: types.TAG_LOADING;
}

export interface ITagLoaded extends IEvent
{
    _aggregate: _aggregates.TAGS;
    _name: types.TAG_LOADED;
    tags: IReducedGraphisTag[];
    type: types.TAG_LOADED;
}

export interface ITagNotCreated extends IEvent
{
    _aggregate: _aggregates.TAGS;
    _name: types.TAG_NOT_CREATED;
    type: types.TAG_NOT_CREATED;
}

export interface ITagNotLoaded extends IEvent
{
    _aggregate: _aggregates.TAGS;
    _name: types.TAG_NOT_LOADED;
    type: types.TAG_NOT_LOADED;
}

export interface ITagNotUpdated extends IEvent
{
    _aggregate: _aggregates.TAGS;
    _name: types.TAG_NOT_UPDATED;
    type: types.TAG_NOT_UPDATED;
}

export interface ITagUpdated extends IEvent
{
    _aggregate: _aggregates.TAGS;
    _name: types.TAG_UPDATED;
    tag: IReducedGraphisTag;
    type: types.TAG_UPDATED;
}

export interface ITagUpdating extends IEvent
{
    _aggregate: _aggregates.TAGS;
    _name: types.TAG_UPDATING;
    type: types.TAG_UPDATING;
}

export interface ITagsErrorReset extends IEvent
{
    _aggregate: _aggregates.TAGS;
    _name: types.TAGS_ERROR_RESET;
    type: types.TAGS_ERROR_RESET;
}
