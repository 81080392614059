/**
 * TYPES
 */
import {_aggregates} from 'src/aggregates/index.d';
import {IEvent} from 'src/aggregates/index.d';


/**
 * EXPORTS
 */

/**
 * Events types.
 */
export enum types
{
    PREFERENCES_ADDED_AUTO_MESSAGE = 'PREFERENCES_ADDED_AUTO_MESSAGE',
    PREFERENCES_EMAIL_PAGE_SIZE_SET = 'PREFERENCES_EMAIL_PAGE_SIZE_SET',
    PREFERENCES_FEEDBACK_SET = 'PREFERENCES_FEEDBACK_SET',
    PREFERENCES_REMOVED_AUTO_MESSAGE = 'PREFERENCES_REMOVED_AUTO_MESSAGE',
    PREFERENCES_SET_CURRENT_AUTO_MESSAGE = 'PREFERENCES_SET_CURRENT_AUTO_MESSAGE',
    PREFERENCES_STARTED = 'PREFERENCES_STARTED',
    PREFERENCES_SWITCHED_MUTE_NOTIFICATIONS = 'PREFERENCES_SWITCHED_MUTE_NOTIFICATIONS',
    PREFERENCES_SWITCHED_PAUSED = 'PREFERENCES_SWITCHED_PAUSED',
    PREFERENCES_SWITCHED_WALLET_REMINDER = 'PREFERENCES_SWITCHED_WALLET_REMINDER',
    PREFERENCES_TAG_COLOR_SET = 'PREFERENCES_TAG_COLOR_SET',
    PREFERENCES_TOUR_DISABLED = 'PREFERENCES_TOUR_DISABLED',
}

/**
 * Events interfaces.
 */
export interface IPreferencesAddedAutoMessage extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_ADDED_AUTO_MESSAGE;
    message: string;
    messageId: number;
    type: types.PREFERENCES_ADDED_AUTO_MESSAGE;
}

export interface IPreferencesEmailPageSizeSet extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_EMAIL_PAGE_SIZE_SET;
    size: number;
    type: types.PREFERENCES_EMAIL_PAGE_SIZE_SET;
}

export interface IPreferencesFeedbackSet extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_FEEDBACK_SET;
    feedback: number;
    type: types.PREFERENCES_FEEDBACK_SET;
}

export interface IPreferencesRemovedAutoMessage extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_REMOVED_AUTO_MESSAGE;
    message: number;
    type: types.PREFERENCES_REMOVED_AUTO_MESSAGE;
}

export interface IPreferencesSetCurrentAutoMessage extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_SET_CURRENT_AUTO_MESSAGE;
    message: number;
    type: types.PREFERENCES_SET_CURRENT_AUTO_MESSAGE;
}

export interface IPreferencesStarted extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_STARTED;
    type: types.PREFERENCES_STARTED;
}

export interface IPreferencesSwitchedMuteNotifications extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_SWITCHED_MUTE_NOTIFICATIONS;
    muteNotifications: boolean;
    type: types.PREFERENCES_SWITCHED_MUTE_NOTIFICATIONS;
}

export interface IPreferencesSwitchedPaused extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_SWITCHED_PAUSED;
    paused: boolean;
    type: types.PREFERENCES_SWITCHED_PAUSED;
}

export interface IPreferencesSwitchedWalletReminder extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_SWITCHED_WALLET_REMINDER;
    type: types.PREFERENCES_SWITCHED_WALLET_REMINDER;
    walletReminder: boolean;
}

export interface IPreferencesTagColorSet extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_TAG_COLOR_SET;
    type: types.PREFERENCES_TAG_COLOR_SET;
    color: string;
    tag: number;
}

export interface IPreferencesTourDisabled extends IEvent
{
    _aggregate: _aggregates.PREFERENCES;
    _name: types.PREFERENCES_TOUR_DISABLED;
    type: types.PREFERENCES_TOUR_DISABLED;
}
