/**
 * CODE
 */

/**
 * I am a timeout wrapper.
 *
 * :param promise: promise to be timed out
 * :param time: timeout in ms
 *
 * :returns: timeout promise
 */
const timeout = async (promise: Promise<any>, time: number): Promise<any> =>
{
    // set timeout
    const timeout = new Promise((resolve, reject): any =>
        setTimeout((): void => reject(new Error()), time),
    );

    // return promise that return first
    return Promise.race([promise, timeout]);
};


/**
 * EXPORTS
 */
export default timeout;
