/**
 * IMPORTS
 */
import {AckTypes} from 'src/aggregates/conversations/message/messages.d';
import {_aggregates} from 'src/aggregates/index.d';
import {IEvent} from 'src/aggregates/index.d';
import {ITimeline} from 'src/aggregates/timelines/handlers.d';
import {IMessage} from 'src/aggregates/timelines/state.d';


/**
 * EXPORTS
 */
export enum types
{
    TIMELINE_ADDED = 'TIMELINE_ADDED',
    TIMELINE_ALL_CONTACT_MESSAGES_LOADED =
        'TIMELINE_ALL_CONTACT_MESSAGES_LOADED',
    TIMELINE_BY_CONVERSATION_CLEARED = 'TIMELINE_BY_CONVERSATION_CLEARED',
    TIMELINE_BY_CONVERSATION_LOADED = 'TIMELINE_BY_CONVERSATION_LOADED',
    TIMELINE_BY_CONVERSATION_MESSAGES_LOADED =
        'TIMELINE_BY_CONVERSATION_MESSAGES_LOADED',
    TIMELINE_BY_CONVERSATION_MESSAGES_LOADING =
        'TIMELINE_BY_CONVERSATION_MESSAGES_LOADING',
    TIMELINE_BY_CONVERSATION_MESSAGES_NOT_LOADED =
        'TIMELINE_BY_CONVERSATION_MESSAGES_NOT_LOADED',
    TIMELINE_BY_CONVERSATION_REMOVED = 'TIMELINE_BY_CONVERSATION_REMOVED',
    TIMELINE_ERROR_RESET = 'TIMELINE_ERROR_RESET',
    TIMELINE_FOCUSED = 'TIMELINE_FOCUSED',
    TIMELINE_LOADED = 'TIMELINE_LOADED',
    TIMELINE_LOADING = 'TIMELINE_LOADING',
    TIMELINE_MESSAGE_ACK_RECEIVED = 'TIMELINE_MESSAGE_ACK_RECEIVED',
    TIMELINE_MESSAGE_BY_ORIGINAL_ID_FETCHED =
        'TIMELINE_MESSAGE_BY_ORIGINAL_ID_FETCHED',
    TIMELINE_MESSAGE_BY_ORIGINAL_ID_FETCHING =
        'TIMELINE_MESSAGE_BY_ORIGINAL_ID_FETCHING',
    TIMELINE_MESSAGE_BY_ORIGINAL_ID_NOT_FETCHED =
        'TIMELINE_MESSAGE_BY_ORIGINAL_ID_NOT_FETCHED',
    TIMELINE_MESSAGE_ORIGINAL_ID_RECEIVED =
        'TIMELINE_MESSAGE_ORIGINAL_ID_RECEIVED',
    TIMELINE_MESSAGES_LOADED = 'TIMELINE_MESSAGES_LOADED',
    TIMELINE_MESSAGES_LOADING = 'TIMELINE_MESSAGES_LOADING',
    TIMELINE_MESSAGES_NOT_LOADED = 'TIMELINE_MESSAGES_NOT_LOADED',
    TIMELINE_NOT_LOADED = 'TIMELINE_NOT_LOADED',
    TIMELINE_REMOVED = 'TIMELINE_REMOVED',
    TIMELINE_TEMPLATE_SENT = 'TIMELINE_TEMPLATE_SENT',
    TIMELINE_TEMPLATE_SENDING = 'TIMELINE_TEMPLATE_SENDING',
    TIMELINE_TEMPLATE_NOT_SENT = 'TIMELINE_TEMPLATE_NOT_SENT',
}


export interface ITimelineAdded extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_ADDED;
    account: number;
    application: string | null;
    end: number | null;
    id: number;
    lastInteraction: number;
    remote: string;
    start: number;
    status: number | null;
    subject: string | null;
    type: types.TIMELINE_ADDED;
}

export interface ITimelineAllContactMessagesLoaded extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_ALL_CONTACT_MESSAGES_LOADED;
    type: types.TIMELINE_ALL_CONTACT_MESSAGES_LOADED;
}

export interface ITimelineByConversationCleared extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_BY_CONVERSATION_CLEARED;
    type: types.TIMELINE_BY_CONVERSATION_CLEARED;
}

export interface ITimelineByConversationLoaded extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_BY_CONVERSATION_LOADED;
    conversation: number;
    timeline: ITimeline[];
    type: types.TIMELINE_BY_CONVERSATION_LOADED;
}

export interface ITimelineByConversationMessagesLoaded extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_BY_CONVERSATION_MESSAGES_LOADED;
    conversation: number;
    messages: IMessage[];
    timeline: number;
    type: types.TIMELINE_BY_CONVERSATION_MESSAGES_LOADED;
}

export interface ITimelineByConversationMessagesLoading extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_BY_CONVERSATION_MESSAGES_LOADING;
    conversation: number;
    timeline: number;
    type: types.TIMELINE_BY_CONVERSATION_MESSAGES_LOADING;
}

export interface ITimelineByConversationMessagesNotLoaded extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_BY_CONVERSATION_MESSAGES_NOT_LOADED;
    conversation: number;
    timeline: number;
    type: types.TIMELINE_BY_CONVERSATION_MESSAGES_NOT_LOADED;
}

export interface ITimelineByConversationRemoved extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_BY_CONVERSATION_REMOVED;
    conversation: number;
    type: types.TIMELINE_BY_CONVERSATION_REMOVED;
}

export interface ITimelineErrorReset extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_ERROR_RESET;
    type: types.TIMELINE_ERROR_RESET;
}

export interface ITimelineFocused extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_FOCUSED;
    type: types.TIMELINE_FOCUSED;
}

export interface ITimelineLoaded extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_LOADED;
    timeline: ITimeline[];
    type: types.TIMELINE_LOADED;
}

export interface ITimelineLoading extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_LOADING;
    type: types.TIMELINE_LOADING;
}

export interface ITimelineMessageByOriginalIdFetched extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_MESSAGE_BY_ORIGINAL_ID_FETCHED;
    current: string | number | null;
    data: IMessage;
    message: number;
    type: types.TIMELINE_MESSAGE_BY_ORIGINAL_ID_FETCHED;
}

export interface ITimelineMessageByOriginalIdFetching extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_MESSAGE_BY_ORIGINAL_ID_FETCHING;
    current: string | number | null;
    message: number;
    type: types.TIMELINE_MESSAGE_BY_ORIGINAL_ID_FETCHING;
}

export interface ITimelineMessageByOriginalIdNotFetched extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_MESSAGE_BY_ORIGINAL_ID_NOT_FETCHED;
    current: string | number | null;
    message: number;
    type: types.TIMELINE_MESSAGE_BY_ORIGINAL_ID_NOT_FETCHED;
}

export interface ITimelineMessageOriginalIdReceived extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_MESSAGE_ORIGINAL_ID_RECEIVED;
    id: number;
    originalId: string;
    type: types.TIMELINE_MESSAGE_ORIGINAL_ID_RECEIVED;
}
export interface ITimelineMessagesLoaded extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_MESSAGES_LOADED;
    messages: IMessage[];
    type: types.TIMELINE_MESSAGES_LOADED;
}

export interface ITimelineMessagesLoading extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_MESSAGES_LOADING;
    type: types.TIMELINE_MESSAGES_LOADING;
}

export interface ITimelineMessagesNotLoaded extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_MESSAGES_NOT_LOADED;
    type: types.TIMELINE_MESSAGES_NOT_LOADED;
}

export interface ITimelineNotLoaded extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_NOT_LOADED;
    type: types.TIMELINE_NOT_LOADED;
}

export interface ITimelineMessageAckReceived extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_MESSAGE_ACK_RECEIVED;
    id: number;
    ack: AckTypes;
    type: types.TIMELINE_MESSAGE_ACK_RECEIVED;
}

export interface ITimelineRemoved extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_REMOVED;
    type: types.TIMELINE_REMOVED;
}

export interface ITimelineTemplateSending extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_TEMPLATE_SENDING;
    payload: IMessage,
    type: types.TIMELINE_TEMPLATE_SENDING;
}

export interface ITimelineTemplateSent extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_TEMPLATE_SENT;
    oldOriginalId: string,
    payload: IMessage,
    type: types.TIMELINE_TEMPLATE_SENT;
}

export interface ITimelineTemplateNotSent extends IEvent
{
    _aggregate: _aggregates.TIMELINES;
    _name: types.TIMELINE_TEMPLATE_NOT_SENT;
    originalId: string;
    type: types.TIMELINE_TEMPLATE_NOT_SENT;
}
