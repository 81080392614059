/**
 * IMPORTS
 */
import {IChatState} from 'src/aggregates/conversations/state.d';


/**
 * CODE
 */

/**
 * Chat initial state.
 */
const initialState: IChatState = {
    agents: [],
    byAgent: {},
    byCustomer: {},
    byId: {},
    connected: true,
    connecting: 0,
    conversation: {
        hasCreationError: false,
        hasError: false,
        hasFinishError: false,
        hasLoadByCustomerError: false,
        hasLoadByPeriodError: false,
        hasLoadError: false,
        hasLoadingPageError: false,
        hasMorePages: true,
        hasOnlineAgentsLoadError: false,
        hasTemplateSendError: false,
        hasTransferError: false,
        isCreating: false,
        isFetching: false,
        isFinishing: false,
        isLoading: false,
        isLoadingByCustomer: false,
        isLoadingByPeriod: false,
        isLoadingOnlineAgents: false,
        isLoadingPage: false,
        isTransferring: false,
    },
    creationFailureReason: '',
    current: null,
    currentAccount: null,
    currentChannel: 'null_null',
    currentContact: null,
    customers: {},
    errorMessage: '',
    feedbacks: [],
    finishedById: {},
    forwardingMessages: {},
    history: [],
    isForwarding: false,
    isSessionForciblyEnded: false,
    lastRedirect: null,
    metrics: {
        agents: {
            byId: {},
            hasTemplatesError: false,
            isTemplatesLoading: false,
        },
        answered: {
            conversations: [],
            count: 0,
        },
        chatbot: {
            conversations: [],
            count: 0,
        },
        contacts: [],
        endDate: null,
        finished: {
            conversations: [],
            count: 0,
        },
        hasError: false,
        incoming: {
            conversations: [],
            count: 0,
        },
        isLoading: false,
        lastEnd: null,
        lastStart: null,
        lost: {
            conversations: [],
            count: 0,
        },
        ongoing: {
            conversations: [],
            count: 0,
        },
        outgoing: {
            conversations: [],
            count: 0,
        },
        realTime: {
            accounts: {},
            apps: {},
            attending: {
                hasAgentAnswer: 0,
                incoming: 0,
                outgoing: 0,
                total: 0,
            },
            chatbot: {
                hasAgentAnswer: 0,
                incoming: 0,
                outgoing: 0,
                total: 0,
            },
            hasAgentAnswer: 0,
            hasError: false,
            incoming: 0,
            isFetching: false,
            outgoing: 0,
            queued: {
                hasAgentAnswer: 0,
                incoming: 0,
                outgoing: 0,
                total: 0,
            },
            total: 0,
        },
        startDate: null,
    },
    onQueue: 0,
    ongoing: [],
    page: 1,
    searchTerm: '',
    started: false,
    stopped: false,
    user: {
        session: null,
    },
};


/**
 * EXPORTS
 */
export default initialState;
