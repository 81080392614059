/**
 * IMPORTS
 */
import initialState from 'src/aggregates/agents/initialstate';
import Store from 'src/store';


/**
 * TYPES
 */
import * as Events from 'src/aggregates/agents/events.d';
import {IAgentReducer} from 'src/aggregates/agents/reducer.d';
import {IAgentState} from 'src/aggregates/agents/state.d';


/**
 * CODE
 */

/**
 * Agent events map.
 */
const eventsMap: IAgentReducer = {

    /**
     * I set agent status when agent have been added.
     *
     * :param state: agent state
     * :param event: agent added event
     *
     * :returns: agent state
     */
    [Events.types.AGENT_ADDED]:
        (state: IAgentState, event: Events.IAgentAdded): IAgentState =>
        {
            // get agent properties
            const {fullname, roles, uid} = event;

            // initialize agents by id
            const byId = {...state.byId};

            // add agent
            byId[uid] = {fullname, roles, uid};

            // return agent state
            return {
                ...state,
                byId,
            };
        },


    /**
     * I set agent status when agents have been loaded.
     *
     * :param state: agent state
     * :param event: agents loaded event
     *
     * :returns: agent state
     */
    [Events.types.AGENT_LOADED]:
        (state: IAgentState, event: Events.IAgentLoaded): IAgentState => ({
            ...state,
            hasLoadError: false,
            isLoading: false,
        }),


    /**
     * I set agent status when loading agents.
     *
     * :param state: agent state
     * :param event: agents loading event
     *
     * :returns: agent state
     */
    [Events.types.AGENT_LOADING]:
        (state: IAgentState, event: Events.IAgentLoading): IAgentState => ({
            ...state,
            hasLoadError: false,
            isLoading: true,
        }),


    /**
     * I set agent status when login metrics have been fetched.
     *
     * :param state: agent state
     * :param event: login metrics fetched event
     *
     * :returns: agent state
     */
    [Events.types.AGENT_LOGIN_METRICS_FETCHED]: (
        state: IAgentState,
        event: Events.IAgentLoginMetricsFetched,
    ): IAgentState => ({
        ...state,
        metrics: {
            ...state.metrics,
            hasLoadError: false,
            isLoading: false,
            login: event.metrics,
        },
    }),


    /**
     * I set agent status when fetching login metrics.
     *
     * :param state: agent state
     * :param event: login metrics fetching event
     *
     * :returns: agent state
     */
    [Events.types.AGENT_LOGIN_METRICS_FETCHING]: (
        state: IAgentState,
        event: Events.IAgentLoginMetricsFetching,
    ): IAgentState => ({
        ...state,
        metrics: {
            ...state.metrics,
            hasLoadError: false,
            isLoading: true,
        },
    }),


    /**
     * I set agent status when login metrics have not been fetched.
     *
     * :param state: agent state
     * :param event: login metrics not fetched event
     *
     * :returns: agent state
     */
    [Events.types.AGENT_LOGIN_METRICS_NOT_FETCHED]: (
        state: IAgentState,
        event: Events.IAgentLoginMetricsNotFetched,
    ): IAgentState => ({
        ...state,
        metrics: {
            ...state.metrics,
            hasLoadError: true,
            isLoading: false,
        },
    }),


    /**
     * I set agent status when metrics period have been updated.
     *
     * :param state: agent state
     * :param event: agents metrics period set event
     *
     * :returns: agent state
     */
    [Events.types.AGENT_METRICS_PERIOD_SET]: (
        state: IAgentState,
        event: Events.IAgentMetricsPeriodSet,
    ): IAgentState => ({
        ...state,
        metrics: {
            ...state.metrics,
            end: event.end,
            start: event.start,
        },
    }),


    /**
     * I set agent status when agents have not been loaded.
     *
     * :param state: agent state
     * :param event: agents not loaded event
     *
     * :returns: agent state
     */
    [Events.types.AGENT_NOT_LOADED]:
        (state: IAgentState, event: Events.IAgentNotLoaded): IAgentState => ({
            ...state,
            hasLoadError: true,
            isLoading: false,
        }),
};


/**
 * Agent reducer.
 */
const reducer = Store.createReducer(initialState, eventsMap);


/**
 * EXPORTS
 */
export default reducer;
