/**
 * IMPORTS
 */
import merge from 'lodash/merge';


/**
 * TYPES
 */
import {IConfig} from 'src/config/Metadata/index.d';


/**
 * CONSTANTS AND DEFINITIONS
 */
const CONFIG_URL = process.env.NODE_ENV === 'development'
    ? '/metadata.json'
    : '/pecazap/metadata.json';

const DEFAULT: IConfig = {
    auth: {
        timeout: 30,
    },
    customerSearch: {
        limit: 50,
    },
    feedback: {
        frequency: 15,
    },
    logger: {
        transports: {
            console: {
                level: 'DEBUG',
            },
        },
    },
    metrics: {
        realTime: {
            refresh: 10,
        },
    },
    servers: {
        'app.pecazap.com.br': [],
    },
};


/**
 * CODE
 */

/**
 * I am a Metadata class to load external config.
 */
class Metadata
{
    // config data
    public config: IConfig;

    /**
     * I load config from metadata JSON file
     *
     * :returns: loaded data
     */
    public async load (): Promise<void>
    {
        // try get config from json
        try
        {
            // get config from json
            const response = await fetch(CONFIG_URL);

            // set data with loaded config
            this.config = merge(DEFAULT, await response.json());
        }

        // can't get config: log error and set default values
        catch (error)
        {
            // set default values
            this.config = DEFAULT;

            // log error
            console.error(error);
        }

        // convert pre-authentication timeout to milliseconds
        this.config.auth.timeout = this.config.auth.timeout * 1000;
    }
}


/**
 * EXPORTS
 */
export default new Metadata();
