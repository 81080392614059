/**
 * TYPES
 */
import {_aggregates} from 'src/aggregates/index.d';
import {IUserConfigLoaded} from 'src/aggregates/user/events.d';
import {IUserConfigLoading} from 'src/aggregates/user/events.d';
import {IUserConfigNotUpdated} from 'src/aggregates/user/events.d';
import {IUserConfigUpdated} from 'src/aggregates/user/events.d';
import {IUserConfigUpdating} from 'src/aggregates/user/events.d';
import {IUserConfigNotLoaded} from 'src/aggregates/user/events.d';
import {IUserLoggedIn} from 'src/aggregates/user/events.d';
import {IUserLoggedOut} from 'src/aggregates/user/events.d';
import {IUserLoggingIn} from 'src/aggregates/user/events.d';
import {IUserNotLoggedIn} from 'src/aggregates/user/events.d';
import {IUserNotified} from 'src/aggregates/user/events.d';
import {IUserNotStarted} from 'src/aggregates/user/events.d';
import {IUserPopupCleared} from 'src/aggregates/user/events.d';
import {IUserPopupShown} from 'src/aggregates/user/events.d';
import {IUserServerUrlSet} from 'src/aggregates/user/events.d';
import {IUserStarted} from 'src/aggregates/user/events.d';
import {IUserStarting} from 'src/aggregates/user/events.d';
import {IUserStatusUpdated} from 'src/aggregates/user/events.d';
import {types} from 'src/aggregates/user/events.d';
import {ICompanyConfig} from 'src/aggregates/user/state.d';
import {IConfig} from 'src/aggregates/user/state.d';
import {loginErrors} from 'src/aggregates/user/state.d';
import {Statuses} from 'src/aggregates/user/state.d';


/**
 * CODE
 */

/**
 * I create user config loaded event.
 *
 * :param config: user config
 *
 * :returns: user config loaded event.
 */
function userConfigLoaded (config: IConfig): IUserConfigLoaded
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_CONFIG_LOADED,
        _target: null,
        config,
        type: types.USER_CONFIG_LOADED,
    };
}


/**
 * I create user config loading event.
 *
 * :returns: user config loading event
 */
function userConfigLoading (): IUserConfigLoading
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_CONFIG_LOADING,
        _target: null,
        type: types.USER_CONFIG_LOADING,
    };
}


/**
 * I create user config not loaded event.
 *
 * :returns: user config not loaded event.
 */
function userConfigNotLoaded (): IUserConfigNotLoaded
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_CONFIG_NOT_LOADED,
        _target: null,
        type: types.USER_CONFIG_NOT_LOADED,
    };
}


/**
 * I create user config not updated event.
 *
 * :returns: user config not updated event.
 */
function userConfigNotUpdated (): IUserConfigNotUpdated
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_CONFIG_NOT_UPDATED,
        _target: null,
        type: types.USER_CONFIG_NOT_UPDATED,
    };
}


/**
 * I create user config updated event.
 *
 * :param config: user config
 *
 * :returns: user config updated event.
 */
function userConfigUpdated (config: IConfig): IUserConfigUpdated
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_CONFIG_UPDATED,
        _target: null,
        config,
        type: types.USER_CONFIG_UPDATED,
    };
}


/**
 * I create user config updating event.
 *
 * :returns: user config updating event
 */
function userConfigUpdating (): IUserConfigUpdating
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_CONFIG_UPDATING,
        _target: null,
        type: types.USER_CONFIG_UPDATING,
    };
}


/**
 * I create an user logged in event.
 *
 * :param id: user id
 * :param company: user company
 * :param name: user full name
 * :param rooms: user rooms
 * :param username: username
 * :param isAdmin: whether the user is an administrator
 *
 * :returns: user logged in event
 */
function userLoggedIn (id: string,
                       company: number,
                       companyConfig: ICompanyConfig,
                       name: string,
                       rooms: string[],
                       username: string,
                       isAdmin: boolean): IUserLoggedIn
{
    // FIXME: remove id conversion from _target when updating event interface
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_LOGGED_IN,
        _target: Number(id),
        company,
        companyConfig,
        isAdmin,
        name,
        rooms,
        type: types.USER_LOGGED_IN,
        username,
    };
}


/**
* I create an user logged out event.
*
* :returns: user logged out event
*/
function userLoggedOut (): IUserLoggedOut
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_LOGGED_OUT,
        _target: null,
        type: types.USER_LOGGED_OUT,
    };
}


/**
 * I create an user logging in event.
 *
 * :returns: user logging in event
 */
function userLoggingIn (): IUserLoggingIn
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_LOGGING_IN,
        _target: null,
        type: types.USER_LOGGING_IN,
    };
}


/**
 * I create an user not logged in event.
 *
 * :param reason: error reason
 *
 * :returns: user not logged in event
 */
function userNotLoggedIn (reason: loginErrors): IUserNotLoggedIn
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_NOT_LOGGED_IN,
        _target: null,
        reason,
        type: types.USER_NOT_LOGGED_IN,
    };
}


/**
 * I create an user notified event.
 *
 * :param message: notification message
 * :param reference: notification reference
 * :param requireInteraction: whether notification should remain active until the
                              user clicks or dismisses it
 * :param title: notification title
 *
 * :returns: user notified event
 */
function userNotified (message: string,
                       reference: number,
                       requireInteraction: boolean,
                       title: string): IUserNotified
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_NOTIFIED,
        _target: null,
        message,
        reference,
        requireInteraction,
        title,
        type: types.USER_NOTIFIED,
    };
}

/**
 * I create an user popup cleared event.
 *
 * :returns: user popup cleared event
 */
function userPopupCleared (): IUserPopupCleared
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_POPUP_CLEARED,
        _target: null,
        type: types.USER_POPUP_CLEARED,
    };
}


/**
 * I create an user popup shown event.
 *
 * :param message: notification message
 * :param reference: notification reference
 * :param title: notification title
 *
 * :returns: user popup shown event
 */
function userPopupShown (level: string,
                         message: string,
                         title: string): IUserPopupShown
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_POPUP_SHOWN,
        _target: null,
        level,
        message,
        title,
        type: types.USER_POPUP_SHOWN,
    };
}


/**
 * I create an user not started event.
 *
 * :returns: user not started event
 */
function userNotStarted (): IUserNotStarted
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_NOT_STARTED,
        _target: null,
        type: types.USER_NOT_STARTED,
    };
}


/**
 * I create an user server url set event.
 *
 * :param serverUrl: server url to set
 *
 * :returns: user server url set event
 */
function userServerUrlSet (serverUrl: string): IUserServerUrlSet
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_SERVER_URL_SET,
        _target: null,
        serverUrl,
        type: types.USER_SERVER_URL_SET,
    };
}


/**
 * I create an user started event.
 *
 * :returns: user started event
 */
function userStarted (): IUserStarted
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_STARTED,
        _target: null,
        type: types.USER_STARTED,
    };
}


/**
 * I create an user starting event.
 *
 * :returns: user starting event
 */
function userStarting (): IUserStarting
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_STARTING,
        _target: null,
        type: types.USER_STARTING,
    };
}


/**
 * I create an user status updated event.
 *
 * :param autoAnswer: selected autoAnswer id
 * :param status: current user status
 *
 * :returns: user status updated event
 */
function userStatusUpdated (status: Statuses): IUserStatusUpdated
{
    return {
        _aggregate: _aggregates.USER,
        _name: types.USER_STATUS_UPDATED,
        _target: null,
        status,
        type: types.USER_STATUS_UPDATED,
    };
}


/**
 * EXPORTS
 */
export {
    userConfigLoaded,
    userConfigLoading,
    userConfigNotLoaded,
    userConfigNotUpdated,
    userConfigUpdated,
    userConfigUpdating,
    userLoggedIn,
    userLoggedOut,
    userLoggingIn,
    userNotLoggedIn,
    userNotified,
    userNotStarted,
    userPopupCleared,
    userPopupShown,
    userServerUrlSet,
    userStarted,
    userStarting,
    userStatusUpdated,
};
