/**
 * IMPORTS
 */
import initialState from 'src/aggregates/customers/initialstate';
import Store from 'src/store';


/**
 * TYPES
 */
import * as Events from 'src/aggregates/customers/events.d';
import {types as eventTypes} from 'src/aggregates/customers/events.d';
import {ICustomerReducer} from 'src/aggregates/customers/reducer.d';
import {ICustomer} from 'src/aggregates/customers/state.d';
import {ICustomerState} from 'src/aggregates/customers/state.d';


/**
 * CODE
 */

/**
 * Customer actions map.
 */
const actionsMap: ICustomerReducer = {

    /**
     * I update customer state on customer added event.
     *
     * :param state: customer state
     * :param event: customer added event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_ADDED]:
        (state: ICustomerState, event: Events.ICustomerAdded): ICustomerState =>
        {
            // get customer id
            const {_target: id} = event;

            // build the customer
            const customer: ICustomer = {
                address1: event.address1,
                address2: event.address2,
                agents: event.agents,
                calls: {},
                city: event.city,
                code: event.code,
                company: event.company,
                contacts: event.contacts,
                description: event.description,
                document: event.document,
                email: event.email,
                id,
                isEditing: false,
                name: event.name,
                organization: event.organization,
                state: event.state,
                tags: event.tags ?? [],
                unassociated: event.unassociated,
                zipcode: event.zipcode,
            };

            // create by contact index to new customer
            const byContact = customer.contacts.reduce(
                (state, {type_id, value}) =>
                    state.set(`${type_id}_${value}`, id),
                state.byContact,
            );

            // return customer state with new customer
            return {
                ...state,
                byContact,
                byId: state.byId.set(id, customer),
            };
        },


    /**
     * I update customer state on customer all pages loaded event.
     *
     * :param state: customer state
     * :param event: customer all pages loaded event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_ALL_PAGES_LOADED]: (
        state: ICustomerState,
        event: Events.ICustomerAllPagesLoaded,
    ): ICustomerState => ({
        ...state,
        hasLoadingError: false,
        hasMorePages: false,
        isLoading: false,
    }),


    /**
     * I clean whatsapp blocking flags on whatsapp-blocked event.
     *
     * :param state: customer state
     * :param event: whatsapp blocked event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_WHATSAPP_BLOCKED]:
        (state: ICustomerState,
         event: Events.ICustomerWhatsappBlocked): ICustomerState =>
        {
            return {
                ...state,
                blockWhatsappError: '',
                hasBlockWhatsappError: false,
                isBlockingWhatsapp: false,
            };
        },


    /**
     * I flag that state is blocking whatsapp contact on whatsapp-blocking event.
     *
     * :param state: customer state
     * :param event: whatsapp blocking event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_WHATSAPP_BLOCKING]:
        (state: ICustomerState,
         event: Events.ICustomerWhatsappBlocking): ICustomerState =>
        {
            return {
                ...state,
                blockWhatsappError: '',
                hasBlockWhatsappError: false,
                isBlockingWhatsapp: true,
            };
        },


    /**
     * I flag state that could not block whatsapp contact.
     *
     * :param state: customer state
     * :param event: whatsapp not blocked event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_WHATSAPP_NOT_BLOCKED]:
        (state: ICustomerState,
         event: Events.ICustomerWhatsappNotBlocked): ICustomerState =>
        {
            return {
                ...state,
                blockWhatsappError: event.error,
                hasBlockWhatsappError: true,
                isBlockingWhatsapp: false,
            };
        },


    /**
     * I flag state that could not unblock whatsapp contact.
     *
     * :param state: customer state
     * :param event: whatsapp not unblocked event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_WHATSAPP_NOT_UNBLOCKED]:
        (state: ICustomerState,
         event: Events.ICustomerWhatsappNotUnblocked): ICustomerState =>
        {
            return {
                ...state,
                hasUnblockWhatsappError: true,
                isUnblockingWhatsapp: false,
                unblockWhatsappError: event.error,
            };
        },


    /**
     * I clean whatsapp unblocking flags on whatsapp-unblocked event.
     *
     * :param state: customer state
     * :param event: whatsapp unblocked event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_WHATSAPP_UNBLOCKED]:
        (state: ICustomerState,
         event: Events.ICustomerWhatsappUnblocked): ICustomerState =>
        {
            return {
                ...state,
                hasUnblockWhatsappError: false,
                isUnblockingWhatsapp: false,
                unblockWhatsappError: '',
            };
        },


    /**
     * I flag that state is unblocking on whatsapp-unblocking event.
     *
     * :param state: customer state
     * :param event: whatsapp unblocking event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_WHATSAPP_UNBLOCKING]:
        (state: ICustomerState,
         event: Events.ICustomerWhatsappUnblocking): ICustomerState =>
        {
            return {
                ...state,
                hasUnblockWhatsappError: false,
                isUnblockingWhatsapp: true,
                unblockWhatsappError: '',
            };
        },


    /**
     * I update customer state on contact already exists event.
     *
     * :param state: customer state
     * :param event: contact already exists event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_CONTACT_ALREADY_EXISTS]:
        (state: ICustomerState,
         event: Events.ICustomerContactAlreadyExists): ICustomerState =>
        {
            return {
                ...state,
                contactAlreadyExists: true,
                isCreating: false,
                isEditing: false,
            };
        },


    /**
     * I update customer state on contact type added event.
     *
     * :param state: customer state
     * :param event: contact type added event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_CONTACT_TYPE_ADDED]:
        (state: ICustomerState,
         event: Events.ICustomerContactTypeAdded): ICustomerState =>
        {
            // get state contact type byId
            const byId = {...state.contactTypes.byId};

            // add event contact type to byId
            byId[event.id] = {
                channel: event.channel,
                id: event.id,
                name: event.name,
                prefix: event.prefix,
            };

            // return updated state
            return {
                ...state,
                contactTypes: {
                    ...state.contactTypes,
                    byId,
                },
            };
        },


    /**
     * I update customer state on contact types fetched event.
     *
     * :param state: customer state
     * :param event: contact types fetched event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_CONTACT_TYPES_FETCHED]:
        (state: ICustomerState,
         event: Events.ICustomerContactTypesFetched): ICustomerState => ({
            ...state,
            contactTypes: {
                ...state.contactTypes,
                byId: {},
                hasError: false,
                isFetching: false,
            },
        }),


    /**
     * I update customer state on contact types fetching event.
     *
     * :param state: customer state
     * :param event: contact types fetching event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_CONTACT_TYPES_FETCHING]:
        (state: ICustomerState,
         event: Events.ICustomerContactTypesFetching): ICustomerState => ({
            ...state,
            contactTypes: {
                ...state.contactTypes,
                hasError: false,
                isFetching: true,
            },
        }),


    /**
     * I update customer state on contact types not fetched event.
     *
     * :param state: customer state
     * :param event: contact types not fetched event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_CONTACT_TYPES_NOT_FETCHED]:
        (state: ICustomerState,
         event: Events.ICustomerContactTypesNotFetched): ICustomerState => ({
            ...state,
            contactTypes: {
                ...state.contactTypes,
                hasError: true,
                isFetching: false,
            },
        }),


    /**
     * I update customer state on customer created event.
     *
     * :param state: customer state
     * :param event: customer created event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_CREATED]:
        (state: ICustomerState,
         event: Events.ICustomerCreated): ICustomerState => ({
            ...state,
            currentCustomer: event._target,
            hasError: false,
            isCreating: false,
        }),


    /**
     * I update customer state on customer creating event.
     *
     * :param state: customer state
     * :param event: customer creating event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_CREATING]:
        (state: ICustomerState,
         event: Events.ICustomerCreating): ICustomerState => ({
            ...state,
            hasError: false,
            isCreating: true,
        }),


    /**
     * I update customer state on customer focused event.
     *
     * :param state: customer state
     * :param event: customer focused event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_FOCUSED]:
        (state: ICustomerState,
         event: Events.ICustomerFocused): ICustomerState => ({
            ...state,
            currentCustomer: event.customer,
        }),


    /**
     * I update customer state on customer loading event.
     *
     * :param state: customer state
     * :param event: customer loading event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_LOADING]:
        (state: ICustomerState,
         event: Events.ICustomerLoading): ICustomerState =>
        {
            // return customer state
            return {
                ...state,
                isLoading: true,
            };
        },


    /**
     * I update customer state on customer not created event.
     *
     * :param state: customer state
     * :param event: customer not created event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_NOT_CREATED]:
        (state: ICustomerState,
         event: Events.ICustomerNotCreated): ICustomerState => ({
            ...state,
            hasError: true,
            isCreating: false,
        }),


    /**
     * I update customer state on customer not loaded event.
     *
     * :param state: customer state
     * :param event: customer not loaded event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_NOT_LOADED]:
        (state: ICustomerState,
         event: Events.ICustomerLoaded): ICustomerState =>
        {
            // return customer state
            return {
                ...state,
                hasLoadingError: true,
                isLoading: false,
            };
        },


    /**
     * I update customer state on customer not removed event.
     *
     * :param state: customer state
     * :param event: customer not removed event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_NOT_REMOVED]:
        (state: ICustomerState,
         event: Events.ICustomerRemoved): ICustomerState =>
        {
            return {
                ...state,
                hasRemovalError: true,
                isRemoving: false,
            };
        },


    /**
     * I update customer state on customer not tagged event.
     *
     * :param state: customer state
     * :param event: customer not tagged event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_NOT_TAGGED]:
        (state: ICustomerState,
         event: Events.ICustomerNotTagged): ICustomerState =>
        {
            return {
                ...state,
                hasTaggingError: true,
                isTagging: false,
            };
        },


    /**
     * I update customer state on customer not tagged event.
     *
     * :param state: customer state
     * :param event: customer not tagged event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_NOT_UNTAGGED]:
        (state: ICustomerState,
         event: Events.ICustomerNotUntagged): ICustomerState =>
        {
            return {
                ...state,
                hasTaggingError: true,
                isTagging: false,
            };
        },


    /**
     * I update customer state on customer removal.
     *
     * :param state: customer state
     * :param event: customer removed event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_REMOVED]:
        (state: ICustomerState,
         event: Events.ICustomerRemoved): ICustomerState =>
        {
            // get customer
            const customer = state.byId.get(event._target);

            // remove customer contacts index
            const byContact = customer.contacts.reduce(
                (state, {type_id, value}) =>
                {
                    // create index id
                    const index = `${type_id}_${value}`;

                    // get customer id from index
                    const customerId = state.get(index);

                    // index with removed customer: remove it and return
                    // new state
                    if (customerId === customer.id)
                    {
                        return state.delete(`${type_id}_${value}`);
                    }

                    // index updated to other customer: return state
                    return state;
                },
                state.byContact,
            );

            // return customer state without removed customer
            return {
                ...state,
                byContact,
                byId: state.byId.delete(event._target),
                hasRemovalError: false,
                isRemoving: false,
            };
        },


    /**
     * I update customer state on customer removing event.
     *
     * :param state: customer state
     * :param event: customer removing event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_REMOVING]:
        (state: ICustomerState,
         event: Events.ICustomerRemoving): ICustomerState =>
        {
            return {
                ...state,
                hasRemovalError: false,
                isRemoving: true,
            };
        },


    /**
     * I update customer state on customer search term added event.
     *
     * :param state: customer state
     * :param event: customer search term added event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_SEARCH_TERM_ADDED]:
        (state: ICustomerState, event: Events.ICustomerSearchTermAdded):
        ICustomerState => ({
            ...state,
            searchTerm: event.term,
        }),


    /**
     * I update customer state on customer tagged event.
     *
     * :param state: customer state
     * :param event: customer tagged event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_TAGGED]:
        (state: ICustomerState, event: Events.ICustomerTagged):
        ICustomerState =>
        {
            // get customer before untag
            const beforeTag = state.byId.get(event._target);

            // get tags
            const tags = [...beforeTag.tags];

            // add tagged tag
            tags.push(event.customerTag);

            // set untagged customer
            const customer: ICustomer = {
                ...beforeTag,
                tags,
            };

            // return updated customer state
            return {
                ...state,
                byId: state.byId.set(event._target, customer),
                hasTaggingError: false,
                isTagging: false,
            };
        },


    /**
     * I update customer state on customer tagging event.
     *
     * :param state: customer state
     * :param event: customer tagging event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_TAGGING]:
        (state: ICustomerState, event: Events.ICustomerTagging):
        ICustomerState => ({
            ...state,
            hasTaggingError: false,
            isTagging: true,
        }),


    /**
     * I update customer state on customer update failed event.
     *
     * :param state: customer state
     * :param event: customers update failed event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_NOT_UPDATED]:
        (state: ICustomerState, event: Events.ICustomerNotUpdated):
        ICustomerState => ({
            ...state,
            hasEditionError: true,
            isEditing: false,
        }),


    /**
     * I update customer state on customer page loaded event.
     *
     * :param state: customer state
     * :param event: customers page loaded event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_PAGE_LOADED]: (
        state: ICustomerState,
        event: Events.ICustomerPageLoaded,
    ): ICustomerState =>
    {
        // add customers to map
        const customers = event.customers.reduce(
            (byId, customer) => byId.set(customer.id, customer),
            state.byId,
        );

        // update by contact with all customer contacts
        const byContact = event.customers.reduce((byContact, customer) =>
        {
            // create by contact index
            return customer.contacts.reduce(
                (state, {type_id, value}) =>
                    state.set(`${type_id}_${value}`, customer.id),
                byContact);
        }, state.byContact);

        // returns updated state
        return {
            ...state,
            byContact,
            byId: customers,
            hasLoadingError: false,
            isLoading: false,
            page: event.page,
        };
    },


    /**
     * I update customer state on customer unassociated contacts not loaded
     * event.
     *
     * :param state: customer state
     * :param event: customer unassociated contacts not loaded event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_UNTAGGED]:
        (state: ICustomerState, event: Events.ICustomerUntagged):
        ICustomerState =>
        {
            // get customer before untag
            const beforeUntag = state.byId.get(event._target);

            // get tags
            const tags = [...beforeUntag.tags];

            // set untagged customer
            const customer: ICustomer = {
                ...beforeUntag,
                tags: tags.filter((tag) => tag.id !== event.customerTag),
            };

            // return updated customer state
            return {
                ...state,
                byId: state.byId.set(event._target, customer),
                hasTaggingError: false,
                isTagging: false,
            };
        },


    /**
     * I update customer state on customer update succeeded event.
     *
     * :param state: customer state
     * :param event: customers update succeeded event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_UPDATED]:
        (state: ICustomerState, event: Events.ICustomerUpdated):
        ICustomerState =>
        {
            // get customer before update
            const beforeUpdate = state.byId.get(event._target);

            const customer: ICustomer = {
                address1: event.address1,
                address2: event.address2,
                agents: event.agents,
                calls: {},
                city: event.city,
                code: event.code,
                company: event.company,
                contacts: event.contacts,
                description: event.description,
                document: event.document,
                email: event.email,
                id: event._target,
                isEditing: false,
                name: event.name,
                organization: event.organization,
                state: event.state,
                tags: beforeUpdate.tags,
                unassociated: false,
                zipcode: event.zipcode,
            };

            // create by contact index to new contacts
            const byContact = customer.contacts.reduce(
                (state, {type_id, value}) =>
                    state.set(`${type_id}_${value}`, customer.id),
                state.byContact,
            );

            // return customer state updating customer
            return {
                ...state,
                byContact,
                byId: state.byId.set(event._target, customer),
                hasEditionError: false,
                isEditing: false,
            };
        },


    /**
     * I update customer state on customer updating event.
     *
     * :param state: customer state
     * :param event: customers updating event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_UPDATING]:
        (state: ICustomerState, event: Events.ICustomerUpdating):
        ICustomerState => ({
            ...state,
            hasEditionError: false,
            isEditing: true,
        }),


    /**
     * I update customer state on error reset event.
     *
     * :param state: customer state
     * :param event: reset event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_ERROR_RESET]:
        (state: ICustomerState, event: Events.ICustomerErrorReset):
        ICustomerState => ({
            ...state,
            blockWhatsappError: '',
            contactAlreadyExists: false,
            hasBlockWhatsappError: false,
            hasEditionError: false,
            hasError: false,
            hasRemovalError: false,
            hasTaggingError: false,
            hasUnblockWhatsappError: false,
            isBlockingWhatsapp: false,
            isCreating: false,
            isEditing: false,
            isRemoving: false,
            isTagging: false,
            isUnblockingWhatsapp: false,
            unblockWhatsappError: '',
        }),


    /**
     * I update customer state on customer fetched event.
     *
     * :param state: customer state
     * :param event: customer fetched event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_FETCHED]:
        (state: ICustomerState, event: Events.ICustomerFetched):
        ICustomerState =>
        {
            // get customer before fetch
            const beforeFetch = state.byId.get(event._target);

            // remove old index by contacts
            const byContact = beforeFetch.contacts.reduce(
                (state, {type_id, value}) =>
                    state.delete(`${type_id}_${value}`),
                state.byContact,
            );

            // set new customer
            const customer = {
                ...beforeFetch,
                address1: event.address1,
                address2: event.address2,
                agents: event.agents,
                city: event.city,
                code: event.code,
                company: event.company,
                contacts: event.contacts,
                description: event.description,
                document: event.document,
                email: event.email,
                id: event._target,
                name: event.name,
                organization: event.organization,
                state: event.state,
                tags: event.tags,
                zipcode: event.zipcode,
            };

            // add customer's contact index
            const updatedByContact = customer.contacts.reduce(
                (state, {type_id, value}) =>
                    state.set(`${type_id}_${value}`, customer.id),
                byContact,
            );

            // return customer state
            return {
                ...state,
                byContact: updatedByContact,
                byId: state.byId.set(event._target, customer),
            };
        },


    /**
     * I update customer state on tags filter updated event.
     *
     * :param state: customer state
     * :param event: tags filter updated event
     *
     * :returns: customer state
     */
    [eventTypes.CUSTOMER_TAGS_FILTER_UPDATED]:
        (state: ICustomerState, event: Events.ICustomerTagsFilterUpdated):
        ICustomerState => ({
            ...state,
            tagsFilter: [...event.tags],
        }),
};


/**
 * User reducer.
 */
const reducer = Store.createReducer(initialState, actionsMap);


/**
 * EXPORTS
 */
export default reducer;
