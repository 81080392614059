/**
 * TYPES
 */
import {ObservableSubscription} from '@apollo/client';
import {ISubscription} from 'src/services/graphis/index.d';


/**
 * CODE
 */

/**
 * I manage the subscriptions references by topic
 */
class Subscriptions
{
    // define class properties
    private byTopic: Map<string, ObservableSubscription> = new Map();


    /**
     * I delete the subscription observable
     *
     * :param subscription: subscription reference
     * :param variables: variables to get the topic
     *
     * :returns: nothing
     */
    public delete<TVariables>(
        subscription: ISubscription<TVariables>,
        variables?: TVariables,
    ): void
    {
        // get topic from subscription with variables
        const topic = subscription.getTopic(variables);

        // delete the subscription observable
        this.byTopic.delete(topic);
    }


    /**
     * I get the subscription observable
     *
     * :param subscription: subscription reference
     * :param variables: variables to get the topic
     *
     * :returns: subscription observable
     */
    public get<TVariables>(
        subscription: ISubscription<TVariables>,
        variables?: TVariables,
    ): ObservableSubscription
    {
        // get topic from subscription with variables
        const topic = subscription.getTopic(variables);

        // get the subscription observable and return
        return this.byTopic.get(topic);
    }


    /**
     * I store a subscription observables by topic
     *
     * :param observable: observable reference
     * :param subscription: subscription reference
     * :param variables: variables to get the topic
     *
     * :returns: nothing
     */
    public set<TVariables>(
        observable: ObservableSubscription,
        subscription: ISubscription<TVariables>,
        variables?: TVariables,
    ): void
    {
        // get topic from subscription with variables
        const topic = subscription.getTopic(variables);

        // store the subscription observables
        this.byTopic.set(topic, observable);
    }
}


export {
    Subscriptions,
};
