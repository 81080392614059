/**
 * TYPES
 */
import {IPreferences} from 'src/aggregates/preferences/state.d';
import {IPreferencesState} from 'src/aggregates/preferences/state.d';


/**
 * CODE
 */
// initial user preferences
const initialPreferences: IPreferences = {
    autoMessages: {
        byId: {},
        current: null,
    },
    emailPageSize: 10,
    feedback: null,
    muteNotifications: false,
    paused: false,
    tagColors: {},
    tour: true,
    walletReminder: false,
};

/**
 * Preferences initial state.
 */
const initialState: IPreferencesState = {
    byId: {},
};


/**
 * EXPORTS
 */
export {
    initialPreferences,
    initialState,
};
