/**
 * IMPORTS
 */
import createReducer from 'src/contexts/createReducer';


/**
 * TYPES
 */
import {IActionsMap} from 'src/contexts/index.d';
import {IScreenActions} from 'src/contexts/screen/actions.d';
import {types} from 'src/contexts/screen/actions.d';
import {IScreenState} from 'src/contexts/screen/index.d';


/**
 * CODE
 */

/**
 * Screen events map
 */
const actionsMap: IActionsMap<IScreenState, IScreenActions> = {

    /**
     * I update screen state on hide contacts sidebar action.
     *
     * :param state: screen state
     * :param event: screen action
     *
     * :returns: screen state
     */
    [types.HIDE_CONTACTS_SIDEBAR]: (state, action) =>
    {
        return {...state, isContactsSidebarOpen: false};
    },


    /**
     * I update screen state on show contacts sidebar action.
     *
     * :param state: screen state
     * :param event: screen action
     *
     * :returns: screen state
     */
    [types.SHOW_CONTACTS_SIDEBAR]: (state, action) =>
    {
        return {...state, isContactsSidebarOpen: true};
    },


    /**
     * I update screen state on hide info sidebar action.
     *
     * :param state: screen state
     * :param event: screen action
     *
     * :returns: screen state
     */
    [types.HIDE_INFO_SIDEBAR]: (state, action) =>
    {
        return {...state, isInfoSidebarOpen: false};
    },


    /**
     * I update screen state on show info sidebar action.
     *
     * :param state: screen state
     * :param event: screen action
     *
     * :returns: screen state
     */
    [types.SHOW_INFO_SIDEBAR]: (state, action) =>
    {
        return {...state, isInfoSidebarOpen: true};
    },


    /**
     * I update screen state on hide sidebar action.
     *
     * :param state: screen state
     * :param event: screen action
     *
     * :returns: screen state
     */
    [types.HIDE_PROFILE]: (state, action) =>
    {
        return {...state, isProfileOpen: false};
    },


    /**
     * I update screen state on show sidebar action.
     *
     * :param state: screen state
     * :param event: screen action
     *
     * :returns: screen state
     */
    [types.SHOW_PROFILE]: (state, action) =>
    {
        return {...state, isProfileOpen: true};
    },


    /**
     * I update screen state on hide sidebar action.
     *
     * :param state: screen state
     * :param event: screen action
     *
     * :returns: screen state
     */
    [types.HIDE_SIDEBAR]: (state, action) =>
    {
        return {...state, isSidebarOpen: false};
    },


    /**
     * I update screen state on show sidebar action.
     *
     * :param state: screen state
     * :param event: screen action
     *
     * :returns: screen state
     */
    [types.SHOW_SIDEBAR]: (state, action) =>
    {
        return {...state, isSidebarOpen: true};
    },
};

/**
 * Create reducer
 */
const reducer = createReducer(actionsMap);


/**
 * EXPORTS
 */
export default reducer;

