/**
 * CODE
 */

/**
 * Base xmpp error.
 *
 * :param message: error message
 *
 * :returns: nothing
 */
class XmppError extends Error
{
    constructor (message: string)
    {
        // call parent constructor
        super(message);

        // set exception name
        this.name = this.constructor.name;
    }
}


/**
 * EXPORTS
 */
export default XmppError;
