/**
 * IMPORTS
 */
import React from 'react';
import {Container} from 'src/components/Shared/IconWrapper/styles';


/**
 * TYPES
 */
import {IIconWrapperProps} from 'src/components/Shared/IconWrapper/index.d';


/**
 * CODE
 */

/**
 * I render the Icon Wrapper component.
 *
 * :param props: necessary component props
 *
 * :returns: Icon Wrapper component
 */
function IconWrapper({
    children,
    className,
    spin,
    style,
}: IIconWrapperProps): JSX.Element
{
    return (
        <Container
            className={className}
            spin={spin}
            style={style}
        >
            {children}
        </Container>
    );
}


/**
 * EXPORTS
 */
export default IconWrapper;
