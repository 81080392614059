/**
 * IMPORTS
 */
import {ApplicationError} from 'src/aggregates/err/applicationerror';


/**
 * CODE
 */

/**
 * I am a pre-authorization error code error.
 */
class PreAuthorizationError extends ApplicationError
{
}


/**
 * EXPORTS
 */
export {
    PreAuthorizationError,
};
