/**
 * TYPES
 */
import {IAppState} from 'src/aggregates/index.d';
import {IMapStateProps} from 'src/config/routes.d';


/**
 * CODE
 */

/**
 * I get the application state and return only the necessary.
 *
 * :param state: application state
 *
 * :returns: necessary component props
 */
function mapStateToProps(state: IAppState): IMapStateProps
{
    return {
        isAuthorized: state.user.isAuthorized,
    };
}


/**
 * EXPORTS
 */
export default mapStateToProps;
