/**
 * IMPORTS
 */
import account from 'src/aggregates/accounts/reducer';
import agent from 'src/aggregates/agents/reducer';
import conversation from 'src/aggregates/conversations/reducer';
import customer from 'src/aggregates/customers/reducer';
import preferences from 'src/aggregates/preferences/reducer';
import surveys from 'src/aggregates/surveys/reducer';
import tags from 'src/aggregates/tags/reducer';
import timeline from 'src/aggregates/timelines/reducer';
import user from 'src/aggregates/user/reducer';


/**
 * EXPORTS
 */
export default
{
    account,
    agent,
    conversation,
    customer,
    preferences,
    surveys,
    tags,
    timeline,
    user,
};
