/**
 * IMPORTS
 */
import {execute} from 'src/aggregates/execute';
import * as commands from 'src/aggregates/user/commands';
import * as handlers from 'src/aggregates/user/handlers';
import {IConfig} from 'src/aggregates/user/state.d';
import {Statuses} from 'src/aggregates/user/state.d';


/**
 * CODE
 */

/**
 * I am an API to clear message popup shown to user.
 *
 * :returns: promise with nothing
 */
async function clearPopup (): Promise<void>
{
    await execute(commands.clearPopup(), handlers.clearPopup);
}


/**
 * I am an API to load user config.
 *
 * :returns: promise with nothing
 */
async function loadConfig (): Promise<void>
{
    await execute(commands.loadConfig(), handlers.loadConfig);
}


/**
 * I am an API to login user.
 *
 * :param username: username
 * :param password: user password
 * :param isAdmin: whether the user is an administrator
 *
 * :returns: promise with nothing
 */
async function login (username: string,
                      password: string,
                      isAdmin: boolean): Promise<void>
{
    await execute(commands.login(username, password, isAdmin), handlers.login);
}


/**
 * I am an API to login user with token.
 *
 * :param role: user role
 * :param token: token uid string
 *
 * :returns: promise with nothing
 */
async function loginToken (role: string,
                           token: string): Promise<void>
{
    await execute(commands.loginToken(role, token), handlers.loginToken);
}


/**
* I am an API to logout the user.
*
* :returns: promise with nothing
*/
async function logout (): Promise<void>
{
    await execute(commands.logout(), handlers.logout);
}


/**
 * I am an API to notify user.
 *
 * :param message: notification message
 * :param reference: notification reference
 * :param requireInteraction: wheter notification should remain active until the
                              user clicks or dismisses it
 * :param title: notification title
 *
 * :returns: promise with nothing
 */
async function notify (message: string,
                       reference: number,
                       requireInteraction: boolean,
                       title: string): Promise<void>
{
    await execute(commands.notify(message,
                                  reference,
                                  requireInteraction,
                                  title),
                  handlers.notify);
}


/**
 * I am an API to set server url.
 *
 * :param serverUrl: server url to set
 *
 * :returns: promise with nothing
 */
async function setServerUrl (serverUrl: string): Promise<void>
{
    await execute(commands.setServerUrl(serverUrl), handlers.setServerUrl);
}


/**
 * I am an API to show a message popup to user.
 *
 * :returns: promise with nothing
 */
async function showPopup (level: string,
                          message: string,
                          title: string): Promise<void>
{
    await execute(commands.showPopup(level, message, title),
                  handlers.showPopup);
}


/**
 * I am an API to start user.
 *
 * :returns: promise with nothing
 */
async function start (): Promise<void>
{
    await execute(commands.start(), handlers.start);
}


/**
 * I am an API to update the user config.
 *
 * :param config: user config
 *
 * :returns: promise with nothing
 */
async function updateConfig (config: IConfig): Promise<void>
{
    await execute(commands.updateConfig(config), handlers.updateConfig);
}


/**
 * I am an API to update the user status.
 *
 * :param autoAnswer: selected autoAnswer id
 * :param status: current user status
 *
 * :returns: promise with nothing
 */
function updateStatus (status: Statuses): void
{
    execute(commands.updateStatus(status), handlers.updateStatus);
}


/**
 * EXPORTS
 */
export {
    clearPopup,
    loadConfig,
    login,
    loginToken,
    logout,
    notify,
    setServerUrl,
    showPopup,
    start,
    updateConfig,
    updateStatus,
};
