/**
 * IMPORTS
 */
import React from 'react';
import {createContext} from 'react';
import {useReducer} from 'react';
import reducer from 'src/contexts/screen/reducer';


/**
 * TYPES
 */
import {IScreenContext} from 'src/contexts/screen/index.d';
import {IScreenContextProviderProps} from 'src/contexts/screen/index.d';
import {IScreenState} from 'src/contexts/screen/index.d';


/**
 * CONSTANTS AND DEFINITIONS
 */
const INITIAL_STATE: IScreenState = {
    isContactsSidebarOpen: false,
    isCustomerSidebarOpen: false,
    isInfoSidebarOpen: false,
    isProfileOpen: false,
    isSidebarOpen: true,
};


/**
 * CODE
 */

// create react context
const screenContext = createContext<IScreenContext>({
    state: INITIAL_STATE,
});


/**
 * I wrap components to share React Context.
 *
 * :returns: screen context
 */
function ScreenContextProvider(
    {children}: IScreenContextProviderProps,
): JSX.Element
{
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    return (
        <screenContext.Provider value={{dispatch, state}}>
            {children}
        </screenContext.Provider>
    );
}


/**
 * EXPORTS
 */
export {
    screenContext,
    ScreenContextProvider,
};
