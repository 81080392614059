/**
 * IMPORTS
 */
import {logger} from 'src/logger';


/**
 * TYPES
 */
import {IConfig} from 'src/serviceWorker/registration.d';


/**
 * GLOBALS
 */
let REGISTRATION: ServiceWorkerRegistration = null;


/**
 * CODE
 */


/**
 * I handle when service worker change installation state
 *
 * :param event: on state change event
 * :param registration: service worker registration
 * :param config: service worker config
 *
 * :returns: nothing
 */
function onStateChange (
    event: Event,
    registration: ServiceWorkerRegistration,
    config?: IConfig,
): void
{
    // get service worker that is installing
    const serviceWorker = event.currentTarget as ServiceWorker;

    // service worker not installed: return
    if (serviceWorker.state !== 'installed')
    {
        return;
    }

    // already has previous installation: execute on update callback and return
    if (navigator.serviceWorker.controller)
    {
        config?.onUpdate?.(registration);
        return;
    }

    // execute on success callback
    config?.onSuccess?.(registration);
}


/**
 * I handle when has new service worker version
 *
 * :param registration: service worker registration
 * :param config: service worker config
 *
 * :returns: nothing
 */
function onUpdateFound (
    registration: ServiceWorkerRegistration,
    config?: IConfig,
): void
{
    // get service worker that is installing
    const serviceWorker = registration.installing;

    // has not new service worker: return
    if (serviceWorker === null)
    {
        return;
    }

    // register on state change event handler
    serviceWorker.onstatechange = (event) => onStateChange(
        event,
        registration,
        config,
    );
}


/**
 * I register the service worker and check for updates.
 *
 * :param config: service worker config
 *
 * :returns: nothing
 */
function register (config?: IConfig): void
{
    // browser has not service worker: log error and return
    if (('serviceWorker' in navigator) !== true)
    {
        logger.error('CVJPZ0030E', {});
        return;
    }

    // register service worker after load
    window.addEventListener('load', () =>
    {
        // get service worker path
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        // Register service worker
        registerServiceWorker(swUrl, config);
    });
}


/**
 * I register service worker file.
 *
 * :param url: Service worker url
 * :param config: service worker config
 *
 * :returns: Promise with nothing
 */
async function registerServiceWorker (
    url: string,
    config?: IConfig,
): Promise<void>
{
    // try register service worker
    try
    {
        // register service worker file
        REGISTRATION = await navigator.serviceWorker.register(
            url,
            {scope: '/'},
        );

        // register on update found callback
        REGISTRATION.onupdatefound = () => onUpdateFound(REGISTRATION, config);
    }

    // can not register service worker: log it
    catch (error)
    {
        logger.error('CVJPZ0028E', {reason: error});
    }
}


/**
 * I unregister the service worker.
 *
 * :returns: Promise with nothing
 */
async function unregister (): Promise<void>
{
    // browser has not service worker: log error and return
    if (('serviceWorker' in navigator) !== true)
    {
        logger.error('CVJPZ0031E', {});
        return;
    }

    // try unregister service worker
    try
    {
        // get registration
        const registration = await navigator.serviceWorker.ready;

        // unregister service worker
        registration.unregister();

    }

    // cannot unregister service worker: log it
    catch (error)
    {
        logger.error('CVJPZ0029E', {reason: error});
    }
}


/**
 * EXPORTS
 */
export {
    register,
    REGISTRATION,
    unregister,
};
