/**
 * IMPORTS
 */
import initialState from 'src/aggregates/user/initialstate';
import Store from 'src/store';


/**
 * TYPES
 */
import {IUserConfigLoaded} from 'src/aggregates/user/events.d';
import {IUserConfigLoading} from 'src/aggregates/user/events.d';
import {IUserConfigNotLoaded} from 'src/aggregates/user/events.d';
import {IUserConfigNotUpdated} from 'src/aggregates/user/events.d';
import {IUserConfigUpdated} from 'src/aggregates/user/events.d';
import {IUserConfigUpdating} from 'src/aggregates/user/events.d';
import {IUserLoggedIn} from 'src/aggregates/user/events.d';
import {IUserLoggedOut} from 'src/aggregates/user/events.d';
import {IUserLoggingIn} from 'src/aggregates/user/events.d';
import {IUserNotified} from 'src/aggregates/user/events.d';
import {IUserNotLoggedIn} from 'src/aggregates/user/events.d';
import {IUserNotStarted} from 'src/aggregates/user/events.d';
import {IUserPopupCleared} from 'src/aggregates/user/events.d';
import {IUserPopupShown} from 'src/aggregates/user/events.d';
import {IUserServerUrlSet} from 'src/aggregates/user/events.d';
import {IUserStarted} from 'src/aggregates/user/events.d';
import {IUserStarting} from 'src/aggregates/user/events.d';
import {IUserStatusUpdated} from 'src/aggregates/user/events.d';
import {types as eventTypes} from 'src/aggregates/user/events.d';
import {IUserReducer} from 'src/aggregates/user/reducer.d';
import {IUserState} from 'src/aggregates/user/state.d';


/**
 * CODE
 */
const actions: IUserReducer = {

    /**
     * I update user config state on user config loaded event.
     *
     * :param state: user state
     * :param event: user config loaded event
     *
     * :returns: user config state
     */
    [eventTypes.USER_CONFIG_LOADED]:
        (state: IUserState, event: IUserConfigLoaded): IUserState => ({
            ...state,
            config: event.config,
            hasLoadConfigError: false,
            isLoadingConfig: false,
        }),


    /**
     * I update user config state on user config loading event.
     *
     * :param state: user state
     * :param event: user config loading event
     *
     * :returns: user config state
     */
    [eventTypes.USER_CONFIG_LOADING]:
    (state: IUserState, event: IUserConfigLoading): IUserState => ({
        ...state,
        hasLoadConfigError: false,
        isLoadingConfig: true,
    }),


    /**
     * I update user config state on user config not loaded event.
     *
     * :param state: user state
     * :param event: user config not loaded event
     *
     * :returns: user config state
     */
    [eventTypes.USER_CONFIG_NOT_LOADED]:
        (state: IUserState, event: IUserConfigNotLoaded): IUserState => ({
            ...state,
            hasLoadConfigError: true,
            isLoadingConfig: false,
        }),


    /**
     * I update user state on user logged in event.
     *
     * :param state: user state
     * :param event: user logged in event
     *
     * :returns: user state
     */
    [eventTypes.USER_LOGGED_IN]:
        (state: IUserState, event: IUserLoggedIn): IUserState => ({
            ...state,
            company: event.company,
            companyConfig: event.companyConfig,
            id: event._target.toString(),
            isAdmin: event.isAdmin,
            isAuthorized: true,
            isAuthorizing: false,
            loginError: null,
            name: event.name,
            rooms: event.rooms,
            username: event.username,
        }),


    /**
    * I update user state on user logged out event.
    *
    * :param state: user state
    * :param event: user logged out event
    *
    * :returns: user state
    */
    [eventTypes.USER_LOGGED_OUT]:
        (state: IUserState, event: IUserLoggedOut): IUserState => initialState,


    /**
     * I update user state when logging in an user.
     *
     * :param state: user state
     * :param event: loggin in user event
     *
     * :returns: user state
     */
    [eventTypes.USER_LOGGING_IN]:
        (state: IUserState, event: IUserLoggingIn): IUserState => ({
            ...state,
            isAuthorizing: true,
            loginError: null,
        }),


    /**
     * I update user state on user notified event.
     *
     * :param state: user state
     * :param event: user notified event
     *
     * :returns: user state
     */
    [eventTypes.USER_NOTIFIED]:
        (state: IUserState, event: IUserNotified): IUserState => ({
            ...state,
            notification: {
                data: {
                    message: event.message,
                    reference: event.reference,
                },
                requireInteraction: event.requireInteraction,
                title: event.title,
            },
        }),


    /**
     * I update user state on user not logged event.
     *
     * :param state: user state
     * :param event: user not logged event
     *
     * :returns: user state
     */
    [eventTypes.USER_NOT_LOGGED_IN]:
        (state: IUserState, event: IUserNotLoggedIn): IUserState => ({
            ...state,
            isAuthorized: false,
            isAuthorizing: false,
            loginError: event.reason,
        }),


    /**
     * I update user state on user started event.
     *
     * :param state: user state
     * :param event: user started event
     *
     * :returns: user state
     */
    [eventTypes.USER_NOT_STARTED]:
        (state: IUserState, event: IUserNotStarted): IUserState => ({
            ...state,
            hasStartError: true,
            isStarting: false,
            started: false,
        }),

    /**
     * I update user state on user popup cleared event.
     *
     * :param state: user state
     * :param event: user popup cleared event
     *
     * :returns: user state
     */
    [eventTypes.USER_POPUP_CLEARED]:
        (state: IUserState, event: IUserPopupCleared): IUserState => ({
            ...state,
            popup: null,
        }),


    /**
     * I update user state on user popup shown event.
     *
     * :param state: user state
     * :param event: user popup shown event
     *
     * :returns: user state
     */
    [eventTypes.USER_POPUP_SHOWN]:
        (state: IUserState, event: IUserPopupShown): IUserState => ({
            ...state,
            popup: {
                level: event.level,
                message: event.message,
                title: event.title,
            },
        }),


    /**
     * I update user state on user server url set event.
     *
     * :param state: user state
     * :param event: user server url set event
     *
     * :returns: user state
     */
    [eventTypes.USER_SERVER_URL_SET]:
        (state: IUserState, event: IUserServerUrlSet): IUserState => ({
            ...state,
            serverUrl: event.serverUrl,
        }),


    /**
     * I update user state on user started event.
     *
     * :param state: user state
     * :param event: user started event
     *
     * :returns: user state
     */
    [eventTypes.USER_STARTED]:
        (state: IUserState, event: IUserStarted): IUserState => ({
            ...state,
            hasStartError: false,
            isStarting: false,
            started: true,
        }),


    /**
     * I update user state on user starting event.
     *
     * :param state: user state
     * :param event: user starting event
     *
     * :returns: user state
     */
    [eventTypes.USER_STARTING]:
        (state: IUserState, event: IUserStarting): IUserState => ({
            ...state,
            hasStartError: false,
            isStarting: true,
            started: false,
        }),


    /**
     * I update user state on user status updated event.
     *
     * :param state: user state
     * :param event: user status updated event
     *
     * :returns: user state
     */
    [eventTypes.USER_STATUS_UPDATED]:
        (state: IUserState, event: IUserStatusUpdated): IUserState => ({
            ...state,
            status: event.status,
        }),

    /**
     * I update user state on user config not updated event.
     *
     * :param state: user state
     * :param event: user config updated event
     *
     * :returns: user state
     */
    [eventTypes.USER_CONFIG_NOT_UPDATED]:
    (state: IUserState, event: IUserConfigNotUpdated): IUserState => ({
        ...state,
        hasUpdateConfigError: true,
        isUpdatingConfig: false,
    }),


    /**
     * I update user state on user config updated event.
     *
     * :param state: user state
     * :param event: user config updated event
     *
     * :returns: user state
     */
    [eventTypes.USER_CONFIG_UPDATED]:
    (state: IUserState, event: IUserConfigUpdated): IUserState => ({
        ...state,
        config: event.config,
        hasUpdateConfigError: false,
        isUpdatingConfig: false,
    }),


    /**
     * I update user state on user config updating event.
     *
     * :param state: user state
     * :param event: user config updated event
     *
     * :returns: user state
     */
    [eventTypes.USER_CONFIG_UPDATING]:
    (state: IUserState, event: IUserConfigUpdating): IUserState => ({
        ...state,
        hasUpdateConfigError: false,
        isUpdatingConfig: true,
    }),
};


/**
 * User reducer.
 */
const reducer = Store.createReducer(initialState, actions);


/**
 * EXPORTS
 */
export default reducer;
