/**
 * IMPORTS
 */
import {ApolloClient} from '@apollo/client';
import {HttpLink} from '@apollo/client';
import {InMemoryCache} from '@apollo/client';
import {split} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {Subscriptions} from 'src/services/graphis/subscriptions';


/**
 * TYPES
 */
import {ApolloQueryResult} from '@apollo/client';
import {DefaultOptions} from '@apollo/client';
import {DocumentNode} from '@apollo/client';
import {FetchResult} from '@apollo/client';
import {NormalizedCacheObject} from '@apollo/client';
import {ISubscription} from 'src/services/graphis/index.d';


/**
 * CONSTANTS AND DEFINITIONS
 */
const DEFAULT_OPTIONS: DefaultOptions = {
    mutate: {
        fetchPolicy: 'network-only',
    },
    query: {
        fetchPolicy: 'network-only',
    },
};
const OPERATIONS_PATH = '/api/graphis/';
const SUBSCRIPTION_PATH = '/subscriptions/graphis/';


/**
 * CODE
 */

/**
 * I am a Apollo client to Graphis service
 */
class GraphisClient
{
    // define class properties
    public client!: ApolloClient<NormalizedCacheObject>;
    private subscriptions!: Subscriptions;


    /**
     * I create a new apollo client with provided url
     *
     * :param baseUrl: base url to connect
     * :param company: logged in user's company
     * :param secret: user session token
     *
     * :returns: nothing
     */
    public create (baseUrl: string, company: number, secret: string): void
    {
        // create http link
        const httpLink = new HttpLink({
            uri: `https://${baseUrl}${OPERATIONS_PATH}`,
        });

        // create WebSocket link
        const wsLink = new WebSocketLink({
            uri: `wss://${baseUrl}${SUBSCRIPTION_PATH}`,
        });

        // define the link to be used by the operation type
        const splitLink = split(
            ({query}) =>
            {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            httpLink,
        );

        // create auth link
        const authLink = setContext((operation, {headers}) =>
        {
            return {
                headers: {
                    ...headers,
                    company,
                    secret,
                },
            };
        });

        // initialize apollo client
        this.client = new ApolloClient({
            cache: new InMemoryCache(),
            defaultOptions: DEFAULT_OPTIONS,
            link: authLink.concat(splitLink),
        });

        // initialize subscriptions
        this.subscriptions = new Subscriptions();
    }


    /**
     * I request Graphis to execute the given mutation
     *
     * :param mutation: mutation definition
     * :param variables: mutation variables
     *
     * :returns: mutation result
     */
    public mutate<TResult, TVariables = Record<string, unknown>> (
        mutation: DocumentNode,
        variables?: TVariables,
    ): Promise<FetchResult<TResult>>
    {
        return this.client.mutate<TResult>({mutation, variables});
    }


    /**
     * I request Graphis to execute the given query
     *
     * :param query: query definition
     * :param variables: query variables
     *
     * :returns: query result
     */
    public query<TResult, TVariables = Record<string, unknown>> (
        query: DocumentNode,
        variables?: TVariables,
    ): Promise<ApolloQueryResult<TResult>>
    {
        return this.client.query<TResult>({query, variables});
    }


    /**
     * I subscribe on the provided subscription
     *
     * :param handler: function to handle new messages
     * :param subscription: subscription to subscribe
     * :param variables: variable to use on subscription
     *
     * :returns: nothing
     */
    public subscribe<TResult, TVariables = Record<string, unknown>> (
        handler: (params: TResult) => void,
        subscription: ISubscription<TVariables>,
        variables?: TVariables,
    ): void
    {
        // subscribe to the given subscription
        const observable = this.client
            .subscribe({query: subscription.query, variables})
            .subscribe(handler);

        // save subscription observable reference
        this.subscriptions.set(observable, subscription, variables);
    }


    /**
     * I unsubscribe on the provided subscription
     *
     * :param subscription: subscription to unsubscribe
     * :param variables: variable to use on subscription
     *
     * :returns: nothing
     */
    public unsubscribe<TVariables = Record<string, unknown>> (
        subscription: ISubscription<TVariables>,
        variables?: TVariables,
    ): void
    {
        // get subscription observable
        const observable = this.subscriptions.get(subscription, variables);

        // observable not found: return
        if (observable === undefined)
        {
            return;
        }

        // unsubscribe
        observable.unsubscribe();

        // remove subscription reference
        this.subscriptions.delete(subscription, variables);
    }
}


/**
 * EXPORTS
 */
export default new GraphisClient();
