/**
 * IMPORTS
 */
import {Progress} from 'antd';
import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {connect} from 'react-redux';
import {loginToken} from 'src/aggregates/user/api';
import ChannelsImg from 'src/assets/images/channels.png';
import LogoImg from 'src/assets/images/logo.png';
import Form from 'src/components/Login/Form';
import {mapStateToProps} from 'src/components/Login/mapstate';
import {Loading} from 'src/config/loadable/Pages';
import history from 'src/history';

import 'src/components/Login/style.scss';


/**
 * TYPES
 */
import {ILoginProps} from 'src/components/Login/index.d';


/**
 * CODE
 */
function Login ({isAuthorized ,isAuthorizing, loginError}: ILoginProps): JSX.Element
{
    // set inner states
    const [progress, setProgress] = useState<number>(0);
    const [usingToken, setUsingToken] = useState<boolean>(true);

    /**
     * I preload the loading page and try to login with token
     */
    useEffect(() =>
    {
        // preload loading page
        Loading.preload();

        // it's not authorizing and it's using token: login with token
        if (isAuthorizing === false
            && usingToken === true
            && isAuthorized == false)
        {
            loginWithToken();
        }
    }, []);

    /**
     * I try to login using the auth token.
     *
     * :returns: promise with nothing
     */
    async function loginWithToken (): Promise<void>
    {
        // get role and token from local storage
        const role = localStorage.getItem('@pecazap:role') || null,
              token = localStorage.getItem('@pecazap:token') || null;

        // token or role not found: return
        if (token === null || role === null)
        {
            setUsingToken(false);
            return;
        }

        // attempt to login using the auth token
        await loginToken(role, token);
    }

    /**
     * I stop using the token if there is an error logging in with it
     */
    useEffect(() =>
    {
        // authorization ended and there is an error: stop using token
        if (isAuthorizing === false && loginError !== null)
        {
            setUsingToken(false);
        }
    }, [isAuthorizing]);

    /**
     * I finish the progress if there is no error logging in with token
     */
    useEffect(() =>
    {
        // authorization ended sucessfully: finish progress
        if (isAuthorizing === false &&
            loginError === null &&
            usingToken === true)
        {
            setProgress(100);
        }
    }, [isAuthorizing]);

    /**
     * I update the progress if login is being authorized
     */
    useEffect(() =>
    {
        // is authorizing and using token: update progress
        if (isAuthorizing === true && usingToken === true)
        {
            setProgress(50);
        }
    }, [isAuthorizing]);

    /**
     * I push the user to the loading route, if authorized
     */
    useEffect(() =>
    {
        // login successful: push loading route
        if (progress === 100 && usingToken == true)
        {
            setUsingToken(false);
            history.push('/loading');
        }
    }, [progress]);

    // return login form background
    return (

        // Login div
        <div className="login" >

            {/* Login form container */}
            <div className="login_form-container" >

                {/* peçaZap logo */}
                <img
                    src={LogoImg}
                    alt="Logo peçaZap"
                    className="logo"
                />

                {/* Social network logos */}
                <img
                    src={ChannelsImg}
                    alt="Whatsapp, Skype, E-mail e Viber"
                    className="channels"
                />

                {usingToken === true && isAuthorizing === true ?
                    <>
                        <strong className="progress-label">
                            Carregando...
                        </strong>
                        <Progress
                            percent={progress}
                            showInfo={false}
                            status="active"
                            strokeColor={{from: '#00A7CF', to: '#CCDC29'}}
                            trailColor="#DDE7EE"
                        />
                    </>
                    :
                    <Form />
                }
            </div>
        </div>
    );
}

const ConnectedLogin = connect(mapStateToProps)(Login);


/**
 * EXPORTS
 */
export default ConnectedLogin;
