import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {persistReducer} from 'redux-persist';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import App from './components/App';
import reducers from 'src/aggregates/index';
import {ScreenContextProvider} from 'src/contexts/screen';
import {register} from 'src/serviceWorker/registration';
import Store from 'src/store';

import 'src/styles.scss';

// configuration to persist redux stores
const persistConfig = {
  key: 'preferences',
  storage,
  whitelist: ['preferences']
}

// combine reducers
const rootReducer = Store.combineReducers(reducers);

// persist reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// create global redux store
const store = Store.create(persistedReducer);

// persist store
const persistor = persistStore(store);

// render application
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ScreenContextProvider>
        <App />
      </ScreenContextProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// initialize foreground state
window.foreground = true;

// update foreground state
window.onblur = () => window.foreground = false;
window.onfocus = () => window.foreground = true;

// register service worker
register();
