/**
 * IMPORTS
 */
import {IEvent} from 'src/aggregates/index.d';
import {_aggregates} from 'src/aggregates/index.d';
import {Statuses} from 'src/aggregates/user/state.d';
import {ICompanyConfig} from 'src/aggregates/user/state.d';
import {loginErrors} from 'src/aggregates/user/state.d';
import {IConfig} from 'src/aggregates/user/state.d';


/**
 * EXPORTS
 */
export enum types
{
    USER_CONFIG_LOADED = 'USER_CONFIG_LOADED',
    USER_CONFIG_LOADING = 'USER_CONFIG_LOADING',
    USER_CONFIG_NOT_LOADED = 'USER_CONFIG_NOT_LOADED',
    USER_CONFIG_NOT_UPDATED = 'USER_CONFIG_NOT_UPDATED',
    USER_CONFIG_UPDATED = 'USER_CONFIG_UPDATED',
    USER_CONFIG_UPDATING = 'USER_CONFIG_UPDATING',
    USER_LOGGED_IN = 'USER_LOGGED_IN',
    USER_LOGGED_OUT = 'USER_LOGGED_OUT',
    USER_LOGGING_IN = 'USER_LOGGING_IN',
    USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN',
    USER_NOT_STARTED = 'USER_NOT_STARTED',
    USER_NOTIFIED = 'USER_NOTIFIED',
    USER_POPUP_CLEARED = 'USER_POPUP_CLEARED',
    USER_POPUP_SHOWN = 'USER_POPUP_SHOWN',
    USER_SERVER_URL_SET = 'USER_SERVER_URL_SET',
    USER_STARTED = 'USER_STARTED',
    USER_STARTING = 'USER_STARTING',
    USER_STATUS_UPDATED = 'USER_STATUS_UPDATED',
}

export interface IUserConfigLoaded extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_CONFIG_LOADED;
    config: IConfig;
    type: types.USER_CONFIG_LOADED;
}

export interface IUserConfigLoading extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_CONFIG_LOADING;
    type: types.USER_CONFIG_LOADING;
}

export interface IUserConfigNotLoaded extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_CONFIG_NOT_LOADED;
    type: types.USER_CONFIG_NOT_LOADED;
}

export interface IUserConfigNotUpdated extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_CONFIG_NOT_UPDATED;
    type: types.USER_CONFIG_NOT_UPDATED;
}

export interface IUserConfigUpdated extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_CONFIG_UPDATED;
    config: IConfig;
    type: types.USER_CONFIG_UPDATED;
}

export interface IUserConfigUpdating extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_CONFIG_UPDATING;
    type: types.USER_CONFIG_UPDATING;
}

export interface IUserLoggedIn extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_LOGGED_IN;
    company: number;
    companyConfig: ICompanyConfig;
    name: string;
    rooms: string[];
    type: types.USER_LOGGED_IN;
    isAdmin: isAdmin;
    username: string;
}

export interface IUserLoggedOut extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_LOGGED_OUT;
    type: types.USER_LOGGED_OUT;
}

export interface IUserLoggingIn extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_LOGGING_IN;
    type: types.USER_LOGGING_IN;
}

export interface IUserNotLoggedIn extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_NOT_LOGGED_IN;
    reason: loginErrors;
    type: types.USER_NOT_LOGGED_IN;
}

export interface IUserNotStarted extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_NOT_STARTED;
    type: types.USER_NOT_STARTED;
}

export interface IUserNotified extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_NOTIFIED;
    message: string;
    reference: number;
    requireInteraction: boolean;
    title: string;
    type: types.USER_NOTIFIED;
}

export interface IUserPopupCleared extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_POPUP_CLEARED;
    type: types.USER_POPUP_CLEARED;
}

export interface IUserPopupShown extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_POPUP_SHOWN;
    level: string;
    message: string;
    title: string;
    type: types.USER_POPUP_SHOWN;
}

export interface IUserServerUrlSet extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_SERVER_URL_SET;
    serverUrl: string;
    type: types.USER_SERVER_URL_SET;
}

export interface IUserStarted extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_STARTED;
    type: types.USER_STARTED;
}

export interface IUserStarting extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_STARTING;
    type: types.USER_STARTING;
}

export interface IUserStatusUpdated extends IEvent
{
    _aggregate: _aggregates.USER;
    _name: types.USER_STATUS_UPDATED;
    status: Statuses;
    type: types.USER_STATUS_UPDATED;
}
