/**
 * IMPORTS
 */
import {ITagsState} from 'src/aggregates/tags/state.d';


/**
 * Tags initial state.
 */
const initialState: ITagsState = {
    byId: {},
    hasCreationError: false,
    hasLoadingError: false,
    hasUpdateError: false,
    isCreating: false,
    isLoading: false,
    isUpdating: false,
};


/**
 * EXPORTS
 */
export default initialState;
