/**
 * IMPORTS
 */
import {notify} from 'grudis';
import InvalidPacket from 'src/aggregates/user/xmpp/err/invalidpacket';


/**
 * TYPES
 */
import {Eventis} from 'eventis';


/**
 * CODE
 */

/**
 * I am a delegate for event packets which adds received events to an
 * Eventis instance.
 *
 * :param eventis: instance to add received events to
 *
 * :returns: nothing
 * :rtype:   undefined
 */
function Delegate (eventis: Eventis): void
{
    this.eventis = eventis;
}


/**
 * I decode an event packet received from a sender and add it to an Eventis
 * instance.
 *
 * :param packet: packet to be handled
 * :param sender: object with a send(message) method
 *
 * :returns: nothing
 *
 * :throws InvalidPacket: if packet does not have required fields
 */
Delegate.prototype.handle = function (packet: any, sender: object): void
{
    // initialize variables
    let name: string;
    let params: any;

    // decode packet
    try
    {
        name = packet.get('name');
        params = packet.get('params');
    }

    // invalid packet: report error
    catch (error)
    {
        throw new InvalidPacket(packet);
    }

    // create event
    const event = {event: name, ...params};

    // relay event to eventis
    this.eventis.add(event);

    // notify observers on grudis
    notify(name, sender, params);
};


/**
 * EXPORTS
 */
export default Delegate;
