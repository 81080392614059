/**
 * TYPES
 */
import {IAppState} from 'src/aggregates/index.d';
import {IMapStateProps} from 'src/components/Login/index.d';


/**
 * CODE
 */

/**
 * I map store to component properties.
 *
 * :param state: store state
 *
 * :returns: map state properties
 */
function mapStateToProps (state: IAppState): IMapStateProps
{
    return {
        isAuthorized: state.user.isAuthorized,
        isAuthorizing: state.user.isAuthorizing,
        loginError: state.user.loginError,
    };
}


/**
 * EXPORTS
 */
export {
    mapStateToProps,
};
