/**
 * IMPORTS
 */
import initialState from 'src/aggregates/tags/initialstate';
import Store from 'src/store';


/**
 * TYPES
 */
import * as Events from 'src/aggregates/tags/events.d';
import {types as eventTypes} from 'src/aggregates/tags/events.d';
import {ITagsReducer} from 'src/aggregates/tags/reducer.d';
import {ITagById} from 'src/aggregates/tags/state.d';
import {ITagsState} from 'src/aggregates/tags/state.d';


/**
 * CODE
 */
const actions: ITagsReducer =
{

    /**
     * I update tags state on tag created event.
     *
     * :param state: tags state
     * :param event: tag created event
     *
     * :returns: tags state
     */
    [eventTypes.TAG_CREATED]:
        (state: ITagsState, event: Events.ITagCreated) => ({
            ...state,
            byId: {
                ...state.byId,
                [event._target]: {
                    color: event.color,
                    id: event._target,
                    name: event.name,
                },
            },
            hasCreationError: false,
            isCreating: false,
        }),


    /**
     * I update tags state on tag creating event.
     *
     * :param state: tags state
     * :param event: tag creating event
     *
     * :returns: tags state
     */
    [eventTypes.TAG_CREATING]:
        (state: ITagsState, event: Events.ITagCreating) => ({
            ...state,
            hasCreationError: false,
            isCreating: true,
        }),


    /**
     * I update tags state on tag loading event.
     *
     * :param state: tags state
     * :param event: tag loading event
     *
     * :returns: tags state
     */
    [eventTypes.TAG_LOADING]:
        (state: ITagsState, event: Events.ITagLoading) => ({
            ...state,
            hasLoadingError: false,
            isLoading: true,
        }),


    /**
     * I update tags state on tag loaded event.
     *
     * :param state: tags state
     * :param event: tag loaded event
     *
     * :returns: tags state
     */
    [eventTypes.TAG_LOADED]:
        (state: ITagsState, event: Events.ITagLoaded) =>
        {

            // set tags by id
            const byId: ITagById = {};

            // set each tag on its id
            for (const tag of event.tags)
            {
                byId[tag.id] = {
                    color: tag.color,
                    id: tag.id,
                    name: tag.name,
                };
            }

            // return tags state
            return {
                ...state,
                byId,
                hasLoadingError: false,
                isLoading: false,
            };
        },


    /**
     * I update tags state on tag not created event.
     *
     * :param state: tags state
     * :param event: tags not created event
     *
     * :returns: tags state
     */
    [eventTypes.TAG_NOT_CREATED]:
        (state: ITagsState, event: Events.ITagCreating) => ({
            ...state,
            hasCreationError: true,
            isCreating: false,
        }),


    /**
     * I update tags state on tag not loaded event.
     *
     * :param state: tags state
     * :param event: tag not loaded event
     *
     * :returns: tags state
     */
    [eventTypes.TAG_NOT_LOADED]:
        (state: ITagsState, event: Events.ITagNotLoaded) => ({
            ...state,
            hasLoadingError: true,
            isLoading: false,
        }),


    /**
     * I update tags state on tag not updated event.
     *
     * :param state: tags state
     * :param event: tag not updated event
     *
     * :returns: tags state
     */
    [eventTypes.TAG_NOT_UPDATED]:
        (state: ITagsState, event: Events.ITagNotUpdated) => ({
            ...state,
            hasUpdateError: true,
            isUpdating: false,
        }),


    /**
     * I update tags state on tag updated event.
     *
     * :param state: tags state
     * :param event: tag updated event
     *
     * :returns: tags state
     */
    [eventTypes.TAG_UPDATED]:
        (state: ITagsState, event: Events.ITagUpdated) => ({
            ...state,
            byId: {
                ...state.byId,
                [event._target]: {
                    ...event.tag,
                },
            },
            hasUpdateError: false,
            isUpdating: false,
        }),


    /**
     * I update tags state on tag updating event.
     *
     * :param state: tags state
     * :param event: tag updating event
     *
     * :returns: tags state
     */
    [eventTypes.TAG_UPDATING]:
        (state: ITagsState, event: Events.ITagUpdating) => ({
            ...state,
            hasUpdateError: false,
            isUpdating: true,
        }),


    /**
     * I update tags state on tags reset error event.
     *
     * :param state: tags state
     * :param event: tags reset error event
     *
     * :returns: tags state
     */
    [eventTypes.TAGS_ERROR_RESET]:
        (state: ITagsState, event: Events.ITagsErrorReset) => ({
            ...state,
            hasCreationError: false,
            hasLoadingError: false,
            hasUpdateError: false,
        }),
};


/**
 * Tags reducer.
 */
const reducer = Store.createReducer(initialState, actions);


/**
 * EXPORTS
 */
export default reducer;
