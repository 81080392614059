/**
 * IMPORTS
 */
import styled from 'styled-components';


/**
 * CODE
 */
const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ant-progress-text {
        color: #636466 !important;
        padding-top: 0px;

        span {
            color: #5BC37F !important;
        }
    }
`;

const Info = styled.span`
    color: #636466;
    padding-top: 15px;
    font-size: 20px;
`;


/**
 * EXPORTS
 */
export {
    Container,
    Info,
};
