
/**
 * IMPORTS
 */
import Eventis from 'eventis';
import Messagis from 'messagis';
import {Endpoints} from 'packetis';
import {Packetis} from 'packetis';
import {delegates} from 'packetis';
import {Patterns} from 'requestis';
import {Promiser} from 'requestis';
import {Responder} from 'requestis';
import {Response as Responsifier} from 'requestis';
import Delegate from 'src/aggregates/user/xmpp/delegate';
import Mediator from 'src/aggregates/user/xmpp/mediator';
import timeout from 'src/aggregates/utils/timeout';
import Metadata from 'src/config/Metadata';
import {logger} from 'src/logger';


/**
 * CONSTANTS AND DEFINITIONS
 */

// set nick name to use in XMPP rooms
const NICK = 'agent';

// set XMPP rooms to be joined on authentication
const ROOMS = [
    'catraquis@conference.platform.converja.com',
    'conversis@conference.platform.converja.com',
];

// set user to send authorization requests to
const AUTHORIZER = 'catraquis@conference.platform.converja.com/catraquis';

// set domain name for XMPP and SIP authentication
const DOMAIN = 'platform.converja.com';

// create XMPP factory and messenger
const factory = new Messagis.factory.Factory(Messagis.Protocol);
const messenger = new Messagis.messenger.Messenger();

// create manager of promises (results of asynchronous operations)
const promiser = new Promiser();

// create packets sender and receiver linked to endpoints manager
const packetis = new Packetis();
const endpoints = new Endpoints(packetis, promiser);

// create responder
const responder = new Responder(new Patterns());

// configure delegate for response packets
const responsifier = (a, b, c, d): any => new Responsifier(a, b, c, d);
const response = new delegates.Response(responsifier);
packetis.delegate('response', response.handle.bind(response));

// configure delegate for request packets
const request = new delegates.Request(responder.respond.bind(responder));
packetis.delegate('request', request.handle.bind(request));

// create events registry
const eventis = new Eventis.Eventis();

// configure delegate for events packets
const delegate = new Delegate(eventis);
packetis.delegate('event', delegate.handle.bind(delegate));

// initialize xmpp
let xmpp = null;

// mediator
const mediator = new Mediator(NICK,
                              ROOMS,
                              endpoints,
                              factory,
                              messenger,
                              promiser);

// xmpp config
let CONFIG = null;


/**
 * CODE
 */

/**
 * I authenticate an user.
 *
 * :param baseUrl: base url to connect
 * :param username: username
 * :param password: user password
 *
 * :returns: promise with authentication response
 */
function authenticate (
    baseUrl: string,
    username: string,
    password: string,
): Promise<void>
{
    // generate jid to login
    const jid = encodeURIComponent(username) + '@' + DOMAIN;

    // format bosh URL
    const boshURL = `${baseUrl}/http-bind/`;

    // create XMPP client config
    const config = {
        boshURL,
        jid,
        password,
        transport: 'bosh',
    };

    // first authentication: store its config
    if (CONFIG === null)
    {
        CONFIG = config;
    }

    // is not the first authentication: update only bosh URL
    else
    {
        CONFIG.boshURL = boshURL;
    }

    // informed new username or password: update it in configuration
    if (username !== '' || password !== '')
    {
        CONFIG.jid = jid;
        CONFIG.password = password;
    }

    // previous session exists: disconnect it
    if (xmpp !== null)
    {
        xmpp.disconnect();
    }

    // log user authenticating
    logger.info('CVJPZ0046I', {
        boshURL: CONFIG.boshURL,
        jid: CONFIG.jid,
        user: username,
    });

    // create connector
    xmpp = Messagis.connectXMPP(CONFIG, factory);

    // return promise
    return timeout(
        promiser.make('application', 'authentication'),
        Metadata.config.auth.timeout,
    );
}


/**
 * I authorize an user.
 *
 * :param username: user to be authorized
 * :param isAdmin: whether the user is an administrator
 *
 * :returns: promise of authorization result
 */
function authorize (username: string, isAdmin: boolean): Promise<any>
{
    // get endpoint for authorizer
    const endpoint = endpoints.get(AUTHORIZER);

    // request authorization
    const role = isAdmin === true ? 'supervisors' : 'agents';
    return timeout(
        endpoint.request('POST', `/${role}/`, {user: username}),
        Metadata.config.auth.timeout,
    );
}


/**
 * I join rooms.
 *
 * :param rooms: rooms to join
 *
 * :returns: promise that fulfills when all rooms are joined
 */
function join (rooms: string[]): Promise<any>
{
    // initialize list of rooms joined
    const promises = [];

    // join each room
    for (let room of rooms)
    {
        // build room jid
        room = `${room}@conference.platform.converja.com`;

        // join it
        const promise = messenger.rooms.join(room);

        // store promise
        promises.push(promise);
    }

    // return promise
    return Promise.all(promises);
}


/**
 * I start application after authentication and authorization have been granted.
 *
 * :param id: user id
 * :param rooms: xmpp roons
 *
 * :returns: start promise
 */
function start (id: string, rooms: string[]): Promise<any>
{
    // join rooms and start application components
    const promise = join(rooms)
        .then((): any => messenger.setNick(id));

    // return start promise
    return promise;
}


/**
 * EXPORTS
 */
export {
    authenticate,
    authorize,
    endpoints,
    factory,
    join,
    mediator,
    messenger,
    packetis,
    promiser,
    responder,
    start,
    xmpp,
};
