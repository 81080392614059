/**
 * TYPES
 */
import {_aggregates} from 'src/aggregates/index.d';
import {IUserClearPopup} from 'src/aggregates/user/commands.d';
import {IUserLoadConfig} from 'src/aggregates/user/commands.d';
import {IUserLogin} from 'src/aggregates/user/commands.d';
import {IUserLoginToken} from 'src/aggregates/user/commands.d';
import {IUserLogout} from 'src/aggregates/user/commands.d';
import {IUserNotify} from 'src/aggregates/user/commands.d';
import {IUserSetServerUrl} from 'src/aggregates/user/commands.d';
import {IUserShowPopup} from 'src/aggregates/user/commands.d';
import {IUserStart} from 'src/aggregates/user/commands.d';
import {IUserUpdateConfig} from 'src/aggregates/user/commands.d';
import {IUserUpdateStatus} from 'src/aggregates/user/commands.d';
import {_names} from 'src/aggregates/user/commands.d';
import {IConfig} from 'src/aggregates/user/state.d';
import {Statuses} from 'src/aggregates/user/state.d';


/**
 * CODE
 */

/**
 * I am a command creator for clear popup.
 *
 * :returns: user clear popup command
 */
function clearPopup (): IUserClearPopup
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_CLEAR_POPUP,
        _target: null,
    };
}


/**
 * I am a command creator for user config.
 *
 * :returns: user config command
 */
function loadConfig (): IUserLoadConfig
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_LOAD_CONFIG,
        _target: null,
    };
}


/**
 * I am a command creator for user login.
 *
 * :param username: username
 * :param password: user password
 * :param isAdmin: whether the user is an administrator
 *
 * :returns: user login command
 */
function login (username: string,
                password: string,
                isAdmin: boolean): IUserLogin
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_LOGIN,
        _target: null,
        isAdmin,
        password,
        username,
    };
}

/**
 * I am a command creator for user login token.
 *
 * :param role: user role
 * :param token: previous session token
 *
 * :returns: user login token command
 */
function loginToken(role: string, token: string): IUserLoginToken
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_LOGIN_TOKEN,
        _target: null,
        role: role,
        token: token,
    };
}

/**
* I am a command creator for user logout.
*
* :returns: user logout command
*/
function logout (): IUserLogout
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_LOGOUT,
        _target: null,
    };
}


/**
 * I am a command creator for user show popup.
 *
 * :param level: notification level
 * :param message: notification message
 * :param title: notification title
 *
 * :returns: user show popup command
 */
function showPopup (level: string,
                    message: string,
                    title: string): IUserShowPopup
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_SHOW_POPUP,
        _target: null,
        level,
        message,
        title,
    };
}


/**
 * I am a command creator for user notification.
 *
 * :param message: notification message
 * :param reference: notification reference
 * :param requireInteraction: wheter notification should remain active until the
                              user clicks or dismisses it
 * :param title: notification title
 *
 * :returns: user notification command
 */
function notify (message: string,
                 reference: number,
                 requireInteraction: boolean,
                 title: string): IUserNotify
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_NOTIFY,
        _target: null,
        message,
        reference,
        requireInteraction,
        title,
    };
}


/**
 * I am a command creator for user set server url.
 *
 * :param serverUrl: server url to set
 *
 * :returns: user set server url command
 */
function setServerUrl (serverUrl: string): IUserSetServerUrl
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_SET_SERVER_URL,
        _target: null,
        serverUrl,
    };
}


/**
 * I am a command creator for user start.
 *
 * :returns: user start command
 */
function start (): IUserStart
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_START,
        _target: null,
    };
}


/**
 * I am a command creator for user update config.
 *
 * :param config: user config
 *
 * :returns: user update config command
 */
function updateConfig (config: IConfig): IUserUpdateConfig
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_UPDATE_CONFIG,
        _target: null,
        config,
    };
}


/**
 * I am a command creator for user update status.
 *
 * :param autoAnswer: selected autoAnswer id
 * :param status: current user status
 *
 * :returns: user update status command
 */
function updateStatus (status: Statuses): IUserUpdateStatus
{
    return {
        _aggregate: _aggregates.USER,
        _name: _names.USER_START,
        _target: null,
        status,
    };
}

/**
 * EXPORTS
 */
export {
    clearPopup,
    loadConfig,
    login,
    loginToken,
    logout,
    notify,
    setServerUrl,
    showPopup,
    start,
    updateConfig,
    updateStatus,
};
