/**
 * IMPORTS
 */
import Store from 'src/store';


/**
 * TYPES
 */
import {IUserState} from 'src/aggregates/user/state.d';
import {ICompanyConfig} from 'src/aggregates/user/state.d';
import {IConfig} from 'src/aggregates/user/state.d';


/**
 * CODE
 */

/**
 * I fetch logged user
 *
 * :returns: user state
 */
const fetch = (): IUserState =>
{
    return Store.getState().user;
};


/**
 * I get the current logged user company config
 *
 * :returns: company config
 */
function getCompanyConfig(): ICompanyConfig
{
    return Store.getState().user.companyConfig;
}


/**
 * I get an user's config
 *
 * :returns: user config
 */
const getConfig = (): IConfig =>
{
    return Store.getState().user.config;
};


/**
 * I get the server url
 *
 * :returns: server url or null
 */
function getServerUrl (): string | null
{
    return Store.getState().user.serverUrl || null;
}


/**
 * EXPORTS
 */
export {
    fetch,
    getCompanyConfig,
    getConfig,
    getServerUrl,
};
