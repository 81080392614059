/**
 * IMPORTS
 */
import getUnixTime from 'date-fns/getUnixTime';
import getRoundedDate from 'src/utils/getRoundedDate';


/**
 * TYPES
 */
import {ISurveysState} from 'src/aggregates/surveys/state.d';


/**
 * CONSTANTS AND DEFINITIONS
 */
const DAY_IN_MS = 24 * 60 * 60 * 1000;
const LAST_WEEK = getRoundedDate(Date.now() - (7 * DAY_IN_MS));
const NOW = getRoundedDate(Date.now());


/**
 * Surveys initial state.
 */
const initialState: ISurveysState = {
    byId: {},
    endDate: getUnixTime(NOW),
    hasLoadError: false,
    isLoading: false,
    startDate: getUnixTime(LAST_WEEK),
};


/**
 * EXPORTS
 */
export default initialState;
