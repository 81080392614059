/**
 * IMPORTS
 */
import initialState from 'src/aggregates/surveys/initialstate';
import Store from 'src/store';


/**
 * TYPES
*/
import * as Events from 'src/aggregates/surveys/events.d';
import {types as eventTypes} from 'src/aggregates/surveys/events.d';
import {ISurveysReducer} from 'src/aggregates/surveys/reducer.d';
import {ISurveyById} from 'src/aggregates/surveys/state.d';
import {ISurveysState} from 'src/aggregates/surveys/state.d';


/**
 * CODE
 */


// surveys reducer
const actions: ISurveysReducer =
{

    /**
     * I update surveys answers state on surveys answers loaded event.
     *
     * :param state: surveys answers state
     * :param event: surveys answers loaded event
     *
     * :returns: surveys answers state
     */
    [eventTypes.SURVEYS_ANSWERS_LOADED]:
        (state: ISurveysState, event: Events.ISurveysAnswersLoaded) =>
        {
            // get surveyId
            const surveyId = event._target;

            // get survey
            const survey = state.byId[surveyId];

            // survey does not exist: return state
            if (survey === undefined)
            {
                return state;
            }

            // update survey answers
            survey['answers'] = event.answers;

            // update survey nps
            survey['nps'] = event.nps;

            // update survey total
            survey['total'] = event.total;

            // update survey detractor
            survey['detractor'] = event.detractor;

            // update survey neutral
            survey['neutral'] = event.neutral;

            // update survey promoter
            survey['promoter'] = event.promoter;

            // update survey hasLoadAnswersError
            survey['hasLoadAnswersError'] = false;

            // update survey isLoadingAnswers
            survey['isLoadingAnswers'] = false;

            // update survey on state
            const byId: ISurveyById = {
                ...state.byId,
                [surveyId]: survey,
            };

            // return surveys answers state
            return {
                ...state,
                byId,
            };
        },

    /**
     * I update surveys answers state on surveys answers loading event.
     *
     * :param state: surveys answers state
     * :param event: surveys answers loading event
     *
     * :returns: surveys answers state
     */
    [eventTypes.SURVEYS_ANSWERS_LOADING]:
        (state: ISurveysState, event: Events.ISurveysAnswersLoading) =>
        {
            // get surveyId
            const surveyId = event._target;

            // get survey
            const survey = state.byId[surveyId];

            // survey does not exist: return state
            if (survey === undefined)
            {
                return state;
            }

            // update survey hasLoadAnswersError
            survey['hasLoadAnswersError'] = false;

            // update survey isLoadingAnswers
            survey['isLoadingAnswers'] = true;

            // update survey on state
            const byId: ISurveyById = {
                ...state.byId,
                [surveyId]: survey,
            };

            // return surveys answers state
            return {
                ...state,
                byId,
            };
        },

    /**
     * I update surveys answers state on surveys answers not loaded event.
     *
     * :param state: surveys answers state
     * :param event: surveys answers not loaded event
     *
     * :returns: surveys answers state
     */
    [eventTypes.SURVEYS_ANSWERS_NOT_LOADED]:
        (state: ISurveysState, event: Events.ISurveysAnswersNotLoaded) =>
        {
            // get surveyId
            const surveyId = event._target;

            // get suvery
            const survey = state.byId[surveyId];

            // survey does not exist: return state
            if (survey === undefined)
            {
                return state;
            }

            // update survey answers
            survey['answers'] = null;

            // update survey nps
            survey['nps'] = null;

            // update survey total
            survey['total'] = null;

            // update survey detractor
            survey['detractor'] = null;

            // update survey neutral
            survey['neutral'] = null;

            // update survey promoter
            survey['promoter'] = null;

            // update survey hasLoadAnswersError
            survey['hasLoadAnswersError'] = true;

            // update survey isLoadingAnswers
            survey['isLoadingAnswers'] = false;

            // update survey on state
            const byId: ISurveyById = {
                ...state.byId,
                [surveyId]: survey,
            };

            // return surveys answers state
            return {
                ...state,
                byId,
            };
        },

    /**
     * I update surveys state on surveys loaded event.
     *
     * :param state: surveys state
     * :param event: surveys loaded event
     *
     * :returns: surveys state
     */
    [eventTypes.SURVEYS_LOADED]:
        (state: ISurveysState, event: Events.ISurveysLoaded) =>
        {
            // set surveys by id
            const byId: ISurveyById = {};

            // set each survey on its id
            for (const survey of event.surveys)
            {
                byId[survey.id] = {
                    answers: null,
                    detractor: null,
                    hasLoadAnswersError: false,
                    id: survey.id,
                    isLoadingAnswers: false,
                    neutral:  null,
                    nps: null,
                    promoter:  null,
                    title: survey.title,
                    total: null,
                };
            }

            // return surveys state
            return {
                ...state,
                byId,
                hasLoadError: false,
                isLoading: false,
            };
        },

    /**
     * I update surveys state on surveys loading event.
     *
     * :param state: surveys state
     * :param event: surveys loading event
     *
     * :returns: surveys state
     */
    [eventTypes.SURVEYS_LOADING]:
        (state: ISurveysState, event: Events.ISurveysLoading) => ({
            ...state,
            hasLoadError: false,
            isLoading: true,
        }),

    /**
     * I update surveys state on surveys not loaded event.
     *
     * :param state: surveys state
     * :param event: surveys not loaded event
     *
     * :returns: surveys state
     */
    [eventTypes.SURVEYS_NOT_LOADED]:
        (state: ISurveysState, event: Events.ISurveysNotLoaded) => ({
            ...state,
            hasLoadError: true,
            isLoading: false,
        }),

    /**
     * I update surveys state on surveys period set event.
     *
     * :param state: surveys state
     * :param event: surveys period set event
     *
     * :returns: surveys state
     */
    [eventTypes.SURVEYS_PERIOD_SET]:
        (state: ISurveysState, event: Events.ISurveysPeriodSet) => ({
            ...state,
            endDate: event.end,
            startDate: event.start,
        }),
};


/**
 * Surveys reducer.
 */
const reducer = Store.createReducer(initialState, actions);


/**
 * EXPORTS
 */
export default reducer;
