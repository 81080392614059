/**
 * TYPES
 */
import {ILoginMetrics} from 'src/aggregates/agents/state.d';
import {IRoles} from 'src/aggregates/agents/state.d';
import {_aggregates} from 'src/aggregates/index.d';
import {IEvent} from 'src/aggregates/index.d';


/**
 * EXPORTS
 */

/**
 * Events types.
 */
export enum types
{
    AGENT_ADDED = 'AGENT_ADDED',
    AGENT_LOADED = 'AGENT_LOADED',
    AGENT_LOADING = 'AGENT_LOADING',
    AGENT_LOGIN_METRICS_FETCHED = 'AGENT_LOGIN_METRICS_FETCHED',
    AGENT_LOGIN_METRICS_FETCHING = 'AGENT_LOGIN_METRICS_FETCHING',
    AGENT_LOGIN_METRICS_NOT_FETCHED = 'AGENT_LOGIN_METRICS_NOT_FETCHED',
    AGENT_METRICS_PERIOD_SET = 'AGENT_METRICS_PERIOD_SET',
    AGENT_NOT_LOADED = 'AGENT_NOT_LOADED',
    AGENT_STARTING = 'AGENT_STARTING'
}


/**
 * Events interfaces.
 */
export interface IAgentAdded extends IEvent
{
    _aggregate: _aggregates.AGENTS;
    _name: types.AGENT_ADDED;
    fullname: string;
    roles: IRoles;
    type: types.AGENT_ADDED;
    uid: string;
}

export interface IAgentLoaded extends IEvent
{
    _aggregate: _aggregates.AGENTS;
    _name: types.AGENT_LOADED;
    type: types.AGENT_LOADED;
}

export interface IAgentLoading extends IEvent
{
    _aggregate: _aggregates.AGENTS;
    _name: types.AGENT_LOADING;
    type: types.AGENT_LOADING;
}

export interface IAgentLoginMetricsFetched extends IEvent
{
    _aggregate: _aggregates.AGENTS;
    _name: types.AGENT_LOGIN_METRICS_FETCHED;
    metrics: ILoginMetrics;
    type: types.AGENT_LOGIN_METRICS_FETCHED;
}

export interface IAgentLoginMetricsFetching extends IEvent
{
    _aggregate: _aggregates.AGENTS;
    _name: types.AGENT_LOGIN_METRICS_FETCHING;
    type: types.AGENT_LOGIN_METRICS_FETCHING;
}

export interface IAgentLoginMetricsNotFetched extends IEvent
{
    _aggregate: _aggregates.AGENTS;
    _name: types.AGENT_LOGIN_METRICS_NOT_FETCHED;
    type: types.AGENT_LOGIN_METRICS_NOT_FETCHED;
}

export interface IAgentMetricsPeriodSet extends IEvent
{
    _aggregate: _aggregates.AGENTS;
    _name: types.AGENT_METRICS_PERIOD_SET;
    end: number;
    start: number;
    type: types.AGENT_METRICS_PERIOD_SET;
}

export interface IAgentNotLoaded extends IEvent
{
    _aggregate: _aggregates.AGENTS;
    _name: types.AGENT_NOT_LOADED;
    type: types.AGENT_NOT_LOADED;
}

export interface IAgentStarting extends IEvent
{
    _aggregate: _aggregates.AGENTS;
    _name: types.AGENT_STARTING;
    type: types.AGENT_STARTING;
}
