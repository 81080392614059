/**
 * IMPORTS
 */
import {css} from 'styled-components';
import {keyframes} from 'styled-components';
import styled from 'styled-components';


/**
 * TYPES
 */
import {IContainerProps} from 'src/components/Shared/IconWrapper/styles.d';


/**
 * CODE
 */

// rotate animation
const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
        
    to {
        transform: rotate(360deg);
    }
`;

// container component style
const Container = styled.span<IContainerProps>`
    display: flex;

    ${props => props.spin === true && css`
        svg {
            animation: ${rotate} 1s linear infinite;
        }
    `}
`;


/**
 * EXPORTS
 */
export {
    Container,
};
