/**
 * IMPORTS
 */
import {Map} from 'immutable';


/**
 * TYPES
 */
import {ITimelineState} from 'src/aggregates/timelines/state.d';


/**
 * CONSTANTS AND DEFINITIONS
 */
const initialState: ITimelineState = {
    byConversation: Map(),
    current: null,
    hasLoadingError: false,
    hasMore: true,
    hasTemplateSendError: false,
    isLoading: false,
    timeline: [],
};


/**
 * EXPORTS
 */
export default initialState;
