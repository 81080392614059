/**
 * IMPORTS
 */
import {observe} from 'grudis';


/**
 * TYPES
 */
import {Messagis} from 'messagis';
import {Messenger} from 'messagis';
import {Endpoints} from 'packetis';


/**
 * CODE
 */

/**
 * I am the application frontback mediator.
 *
 * :param nick: user nickname
 * :param rooms: rooms to be joined
 * :param endpoints: object that manage broadcasting of events
 * :param messagis: object that manage communication between components
 * :param messenger: manager of messagis rooms and roaster
 * :param promiser: manager of promises
 *
 * :returns: nothing
 */
function Mediator (nick: string,
                   rooms: string[],
                   endpoints: Endpoints,
                   messagis: Messagis,
                   messenger: Messenger,
                   promiser: any,
): void
{
    // store application configuration
    this.nick = nick;
    this.rooms = rooms;

    // store components
    this.endpoints = endpoints;
    this.messenger = messenger;
    this.promiser = promiser;

    // observe messagis
    observe(messagis, this);

    // track added rooms
    this.messenger.rooms.on('add', (room) => this._EH_RoomAdded(room));
}


/**
 * I handle the event of a member joining a room.
 *
 * :param room: room that member joined
 * :type  room: messagis.messenger.room.Room
 *
 * :param member: joined member
 * :type  member: messagis.messenger.member.Member
 *
 * :returns: nothing
 */

Mediator.prototype._EH_MemberAdded = function (room: any, member: any): void
{
    // register member endpoint
    this.endpoints.register(member.getJid(), member);
};


/**
 * I handle the event of a member leaving a room.
 *
 * :param room: room that member left
 * :type  room: messagis.messenger.room.Room
 *
 * :param member: left member
 * :type  member: messagis.messenger.member.Member
 *
 * :returns: nothing
 */

Mediator.prototype._EH_MemberRemoved = function (room: any, member: any): void
{
    // unregister member endpoint
    this.endpoints.unregister(member.getJid());
};


/**
 * I handle the add of a new room to messenger.
 *
 * :param room: added room
 * :type  room: messagis.messenger.Room
 *
 * :returns: nothing
 */
Mediator.prototype._EH_RoomAdded = function (room: any): void
{
    // register its existent members
    for (const member of room.list())
    {
        this._EH_MemberAdded(room, member);
    }

    // track members
    room.on('add', (member) => this._EH_MemberAdded(room, member));
    room.on('remove', (member) => this._EH_MemberRemoved(room, member));
};


/**
 * I get called when user authentication fails.
 *
 * :param sender: object which sent the event
 * :type  sender: Factory
 *
 * :param reason: failure reason
 *
 * :returns: nothing
 */

Mediator.prototype._NH_AuthenticationDidFail = function (sender: any,
                                                         reason: Error): void
{
    // reject authentication promise
    this.promiser.settle('application', 'authentication').reject(reason);
};


/**
 * I get called when user authentication succeeds.
 *
 * :param sender: object which sent the event
 * :type  sender: Factory
 *
 * :param rooms: rooms joined after authentication
 *
 * :returns: nothing
 */

Mediator.prototype._NH_AuthenticationDidSucceed = function (
    sender: any,
    rooms: string[],
): void
{
    // fullfil authentication promise
    this.promiser.settle('application', 'authentication').fulfill(rooms);
};


/**
 * I get called when connection is made.
 *
 * :param sender: object which sent the MessagisDidStart event
 * :type  sender: Factory
 *
 * :param connector: connector
 * :type  protocol: Connector
 *
 * :param reason: failure reason
 *
 * :returns: nothing
 * :rtype: undefined
 */

Mediator.prototype._NH_MessagisDidFailToStart = function (sender: any,
                                                          connector: any,
                                                          reason: Error): void
{
    // reject authentication promise
    this.promiser.settle('application', 'authentication').reject(reason);
};


/**
 * I get called when some message is broadcast at room.
 *
 * :param sender: object which sent the event of broadcast
 * :type  sender: Factory
 *
 * :param room: room JID
 * :type  room: JID
 *
 * :param user: the user which sent message
 * :type  user: User
 *
 * :param message: message sent by the user
 * :type  message: String
 *
 * :returns: nothing
 * :rtype: undefined
 */

Mediator.prototype._NH_MessagisDidReceiveBroadcast = function (
    sender: any,
    room: any,
    user: any,
    message: string,
): void
{
    // endpoint exists: handle message
    if (this.endpoints.has(user.jid) === true)
    {
        this.endpoints.get(user.jid).dispatch(message);
    }
};


/**
 * I get called when a private message has been received.
 *
 * :param sender: object which sent the event
 * :type  sender: Factory
 *
 * :param from: user message was received from
 * :type  from: String
 *
 * :param to: user message was sent to
 * :type  to: String
 *
 * :param message: received message
 *
 * :returns: nothing
 * :rtype: undefined
 */

Mediator.prototype._NH_MessagisDidReceiveMessage = function (
    sender: any,
    from: any,
    to: any,
    message: string,
): void
{
    // endpoint exists: dispatch message to user
    if (this.endpoints.has(from) === true)
    {
        this.endpoints.get(from).dispatch(message);
    }
};


/**
 * I get called when connection is made.
 *
 * :param sender: object which sent the MessagisDidStart event
 * :type  sender: Factory
 *
 * :param protocol: protocol of communication
 * :type  protocol: Protocol
 *
 * :returns: nothing
 */

Mediator.prototype._NH_MessagisDidStart = function (sender: any,
                                                    protocol: any): void
{
    // start messenger
    this.messenger.start(protocol.transport.client.config.jid, protocol);

    // build nick name for user
    const uid = protocol.transport.client.config.jid.local;

    // nick does not contains user uid: add it and set messenger nick
    if (this.nick.includes(uid) === false)
    {
        // add user uid to nick name
        const nick = `${this.nick}.${uid}`;

        // make it persisent
        this.nick = nick;
        this.messenger.setNick(nick);
    }

    // initialize list of room join result promises
    const promises = [];

    // join rooms
    for (const room of this.rooms)
    {
        // request to join room
        const promise = this.messenger.rooms.join(room);

        // append its promise to authentication result
        promises.push(promise);
    }

    // resolve application start promise when room join promises resolve
    Promise.all(promises)
        .then((value) => this._NH_AuthenticationDidSucceed(this, value))
        .catch((reason) => this._NH_AuthenticationDidFail(this, reason));
};


/**
 * I get called when connection is lost.
 *
 * :param sender: object which sent the event
 * :type  sender: Factory
 *
 * :param reason: connection loss reason
 *
 * :returns: nothing
 */

Mediator.prototype._NH_MessagisDidStop = function (sender: any,
                                                   reason: Error): void
{
    // stop messenger
    this.messenger.stop(reason);
};


/**
 * EXPORTS
 */
export default Mediator;
