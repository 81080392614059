/**
 * EXPORTS
 */

export enum AckTypes
{
    NOT_TRANSMITTED = 'nottransmitted',
    SEEN = 'seen',
    RECEIVED = 'received',
    TRANSMITTED = 'transmitted',
    TRANSMITTING = 'transmitting',
    UNSUPPORTED = 'unsupported'
}


export enum MessageTypes
{
    AUDIO = 'audio',
    CALL = 'call',
    CONTACT = 'vcard',
    EMAIL = 'email',
    FILE = 'file',
    IMAGE = 'image',
    INTERACTIVE_LIST = 'interactiveList',
    INTERACTIVE_OPTION = 'interactiveOption',
    LOCATION = 'location',
    TEMPLATE = 'template',
    TEXT = 'text',
    UNSUPPORTED = 'unsupported',
    VIDEO = 'video',
}


export interface IMessage
{
    ack: AckTypes;
    body: string;
    deleted: number | null;
    originalId: string;
    outgoing: boolean;
    timestamp: number;
    type: MessageTypes;
}
