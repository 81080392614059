/**
 * IMPORTS
 */
import initialState from 'src/aggregates/accounts/initialstate';
import Store from 'src/store';


/**
 * TYPES
 */
import {IAccountAdded} from 'src/aggregates/accounts/events.d';
import {IAccountChannelsLoaded} from 'src/aggregates/accounts/events.d';
import {IAccountChannelsLoading} from 'src/aggregates/accounts/events.d';
import {IAccountChannelsNotLoaded} from 'src/aggregates/accounts/events.d';
import {IAccountLoaded} from 'src/aggregates/accounts/events.d';
import {IAccountLoading} from 'src/aggregates/accounts/events.d';
import {IAccountNotLoaded} from 'src/aggregates/accounts/events.d';
import {IAccountTemplatesLoaded} from 'src/aggregates/accounts/events.d';
import {types} from 'src/aggregates/accounts/events.d';
import {IAccountReducer} from 'src/aggregates/accounts/reducer.d';
import {IAccountState} from 'src/aggregates/accounts/state.d';
import {ITemplateById} from 'src/aggregates/accounts/state.d';


/**
 * CODE
 */

/**
 * Accounts events map
 */
const eventsMap: IAccountReducer = {

    /**
     * I update account state on account added event.
     *
     * :param state: account state
     * :param event: account added event
     *
     * :returns: account state
     */
    [types.ACCOUNT_ADDED]:
        (state: IAccountState, event: IAccountAdded): IAccountState =>
        {
            // get accounts byId
            const byId = {...state.byId};

            // add account
            byId[event._target] = {
                channel: event.channel,
                configuredTemplates: event.configuredTemplates,
                defaultTemplate: event.defaultTemplate,
                id: event._target,
                name: event.name,
                templates: [],
                uid: event.uid,
            };

            // return account state
            return {
                ...state,
                byId,
            };
        },


    /**
     * I update account state on account channels loaded event.
     *
     * :param state: account state
     * :param event: account channels loaded event
     *
     * :returns: account state
     */
    [types.ACCOUNT_CHANNELS_LOADED]: (
        state: IAccountState,
        event: IAccountChannelsLoaded,
    ): IAccountState => ({
        ...state,
        channels: {
            byType: event.channels,
            hasLoadingError: false,
            isLoading: false,
        },
    }),


    /**
     * I update account state on account channels loading event.
     *
     * :param state: account state
     * :param event: account channels loading event
     *
     * :returns: account state
     */
    [types.ACCOUNT_CHANNELS_LOADING]: (
        state: IAccountState,
        event: IAccountChannelsLoading,
    ): IAccountState => ({
        ...state,
        channels: {
            ...state.channels,
            hasLoadingError: false,
            isLoading: true,
        },
    }),


    /**
     * I update account state on account channels not loaded event.
     *
     * :param state: account state
     * :param event: account channels not loaded event
     *
     * :returns: account state
     */
    [types.ACCOUNT_CHANNELS_NOT_LOADED]: (
        state: IAccountState,
        event: IAccountChannelsNotLoaded,
    ): IAccountState => ({
        ...state,
        channels: {
            ...state.channels,
            hasLoadingError: true,
            isLoading: false,
        },
    }),


    /**
     * I update account state on account loaded event.
     *
     * :param state: account state
     * :param event: account loaded event
     *
     * :returns: account state
     */
    [types.ACCOUNT_LOADED]:
        (state: IAccountState, event: IAccountLoaded): IAccountState => ({
            ...state,
            byId: {},
            hasError: false,
            isLoading: false,
        }),


    /**
     * I update account state on account loading event.
     *
     * :param state: account state
     * :param event: account loading event
     *
     * :returns: account state
     */
    [types.ACCOUNT_LOADING]:
        (state: IAccountState, event: IAccountLoading): IAccountState => ({
            ...state,
            hasError: false,
            isLoading: true,
        }),


    /**
     * I update account state on account not loaded event.
     *
     * :param state: account state
     * :param event: account not loaded event
     *
     * :returns: account state
     */
    [types.ACCOUNT_NOT_LOADED]:
        (state: IAccountState, event: IAccountNotLoaded): IAccountState => ({
            ...state,
            hasError: true,
            isLoading: false,
        }),


    /**
     * I update account state on templates loaded event.
     *
     * :param state: account state
     * :param event: templates loaded event
     *
     * :returns: account state
     */
    [types.ACCOUNT_TEMPLATES_LOADED]:
        (state: IAccountState, event: IAccountTemplatesLoaded): IAccountState =>
        {
            // get accounts by id state object
            const byId = {...state.byId};

            // get event props
            const {_target: id, filteredTemplates, templates} = event;

            // add templates to the account
            byId[id] = {...byId[id], templates: filteredTemplates};

            // declare map of loaded template indexed by id
            const templatesMap: ITemplateById = {};

            // add all templates to created map
            templates?.forEach(template =>
                templatesMap[template.id] = template);

            // return updated accounts state
            return {
                ...state,
                byId,
                templates: {...state.templates, ...templatesMap},
            };
        },
};


/**
 * Create reducer
 */
const reducer = Store.createReducer(initialState, eventsMap);


/**
 * EXPORTS
 */
export default reducer;
