/**
 * IMPORTS
 */
import {IAccountState} from 'src/aggregates/accounts/state.d';
import {IAgentState} from 'src/aggregates/agents/state.d';
import {ICustomerState} from 'src/aggregates/customers/state.d';
import {IPreferencesState} from 'src/aggregates/preferences/state.d';
import {ISurveysState} from 'src/aggregates/surveys/state.d';
import {ITagsState} from 'src/aggregates/tags/state.d';
import {ITimelineState} from 'src/aggregates/timelines/state.d';
import {IUserState} from 'src/aggregates/user/state.d';


/**
 * EXPORTS
 */

/**
 * Aggregates names.
 */
export enum _aggregates
{
    ACCOUNTS = 'account',
    AGENTS = 'agents',
    CONVERSATIONS = 'conversations',
    CUSTOMERS = 'customers',
    PREFERENCES = 'preferences',
    SURVEYS = 'surveys',
    TAGS = 'tags',
    TIMELINES = 'timelines',
    USER = 'user'
}


/**
 * Application state interface.
 */
export interface IAppState
{
    account: IAccountState;
    agent: IAgentState;
    conversation: any;
    customer: ICustomerState;
    preferences: IPreferencesState;
    surveys: ISurveysState;
    tags: ITagsState;
    timeline: ITimelineState;
    user: IUserState;
}


/**
 * Command interface.
 */
export interface ICommand
{
    _aggregate: string;
    _name: string;
    _target: number;
}


/**
 * Command creator interface.
 */
export interface ICommandCreator<C>
{
    (...args: any[]): C;
}


/**
 * Event interface.
 */
export interface IEvent
{
    _aggregate: string;
    _name: string;
    _target: number;
    type: string;
}


/**
 * Event creator interface.
 */
export interface IEventCreator<E>
{
    (...args: any[]): E;
}

/**
 * Graphis error.
 */
export interface IGraphisError
{
    __typename: string,
    code: string,
    info: string | null,
}

/**
 * Command handler interface.
 */
export interface IHandler<C>
{
    (command: C, state: IAppState): AsyncIterable<IEvent>;
}
