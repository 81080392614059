/**
 * EXPORTS
 */
export enum UploadStatuses
{
    CANCELED = 1,
    COMPLETED = 3,
    ERROR = 0,
    NONE = -1,
    PENDING = 2
}
