/**
 * IMPORTS
 */
import {LoginTokenError}
    from 'src/aggregates/user/err/logintokenerror';
import {PreAuthenticationError}
    from 'src/aggregates/user/err/preauthenticationerror';
import {PreAuthorizationError}
    from 'src/aggregates/user/err/preauthorizationerror';
import {CREATE_NPS_FEEDBACK} from 'src/aggregates/user/mutation';
import {UPDATE_USER_CONFIG} from 'src/aggregates/user/mutation';
import {FETCH_COMPANY_CONFIG} from 'src/aggregates/user/queries';
import {FETCH_USER_CONFIG} from 'src/aggregates/user/queries';
import {fetch as fetchUser} from 'src/aggregates/user/selectors';
import {authenticate as authenticateUser} from 'src/aggregates/user/xmpp/main';
import {authorize as authorizeUser} from 'src/aggregates/user/xmpp/main';
import {start as startUser} from 'src/aggregates/user/xmpp/main';
import timeout from 'src/aggregates/utils/timeout';
import Metadata from 'src/config/Metadata';
import {logger} from 'src/logger';
import graphis from 'src/services/graphis';


/**
 * TYPES
 */
import {IGraphisError} from 'src/aggregates/index.d';
import {ICreateNPSFeedbackResponse} from 'src/aggregates/user/mutation.d';
import {IUpdateUserConfigResponse} from 'src/aggregates/user/mutation.d';
import {IFetchCompanyConfigResponse} from 'src/aggregates/user/queries.d';
import {IFetchUserConfigResponse} from 'src/aggregates/user/queries.d';
import {IGraphisCompanyConfig} from 'src/aggregates/user/queries.d';
import {IGraphisUserConfig} from 'src/aggregates/user/queries.d';
import {IConfig} from 'src/aggregates/user/state.d';
import {IQuickMessage} from 'src/aggregates/user/state.d';
import {loginErrors} from 'src/aggregates/user/state.d';
import {ILoginTokenResponse} from 'src/aggregates/user/utils.d';
import {IPreAuthenticationData} from 'src/aggregates/user/utils.d';
import {IResponse} from 'src/aggregates/user/utils.d';
import {IAuthorizationData} from 'src/aggregates/user/xmpp/main.d';
import {IAuthorizationResponse} from 'src/aggregates/user/xmpp/main.d';


/**
 * CONSTANTS AND DEFINITIONS
 */
const AUTH_ERRORS: Record<string, loginErrors> = {
    'Error: Authentication Error': loginErrors.AUTHENTICATION,
    'Error: Could not connect to server': loginErrors.UNAVAILABLE,
};

const LOCAL_STORAGE_SERVER_KEY = '@pecazap:server';

const TOKEN_AUTH_TIMEOUT = 10000;


/**
 * CODE
 */

/**
 * I authenticate an user.
 *
 * :param password: user password
 * :param url: server url to connect
 * :param username: username
 *
 * :returns: promise with login error or null
 */
async function authenticate (
    password: string,
    url: string,
    username: string,
): Promise<loginErrors | null>
{
    // try authenticate with base url
    try
    {
        // authenticate user
        await authenticateUser(
            url,
            username,
            password,
        );

        // return response
        return null;
    }

    // authentication failed: try to log error and return error
    catch (error)
    {
        if (logger !== null && logger !== undefined)
        {
            // logger already initialized: log error
            logger.error('CVJPZ0025E', {
                reason: error.stack,
                server: url,
                user: username,
            });
        }

        // return error
        return AUTH_ERRORS[error] ?? loginErrors.UNKNOWN;
    }
}


/**
 * I authorize an user.
 *
 * :param isAdmin: whether the user is an administrator
 * :param url: server url to connect
 * :param username: username
 *
 * :returns: promise with authorization response
 */
async function authorize (
    isAdmin: boolean,
    url: string,
    username: string,
): Promise<IAuthorizationData | null>
{
    // initialize response
    let response: IAuthorizationResponse;

    // request authorization
    try
    {
        response = await authorizeUser(username, isAdmin);
    }

    // authorization failed: log and return null
    catch (error)
    {
        logger.error('CVJPZ0026E', {
            reason: error,
            role: isAdmin ? 'admin' : 'agent',
            user: username,
        });
        return null;
    }

    // error code: return null
    if (response.status >= 300)
    {
        logger.error('CVJPZ0026E', {
            reason: `${response.status}: ${response.params}`,
            role: isAdmin ? 'admin' : 'agent',
            user: username,
        });
        return null;
    }

    // get allowed companies by server
    const companies = Metadata.config.servers[url];

    // get authorization data from response
    const authorization = response?.params?.data;

    // user not allowed to login in server: log and return null
    if (companies?.includes(authorization?.user?.company) !== true)
    {
        logger.error('CVJPZ0032E', {
            company: authorization?.user?.company,
            server: url,
            user: username,
        });
        return null;
    }

    // success code: return authorization response data
    return authorization;
}


/**
 * I create a NPS feedback
 *
 * :param company: user company
 * :param date: feedback date
 * :param name: user name
 * :param response: feedback response
 * :param role: user role
 * :param score: feedback score
 * :param server: user logged server
 * :param user: user id
 *
 * :returns: Promise with nothing
 */
async function createNPSFeedback (
    company: number,
    date: string,
    name: string,
    response: string,
    role: string,
    score: number,
    server: string,
    user: string,
): Promise<void>
{
    // initialize result
    let result: ICreateNPSFeedbackResponse = null;

    // try create nps feedback
    try
    {
        result = await graphis.mutate(CREATE_NPS_FEEDBACK, {
            company,
            date,
            name,
            response,
            role,
            score,
            server,
            user,
        });
    }

    // cannot create nps feedback: log error and return
    catch (error)
    {
        logger.error('CVJPZ0034E', {reason: error, user});
        return;
    }

    // failed to create feedback: log error
    if (result.data.createNPSFeedback === null)
    {
        logger.error('CVJPZ0034E', {
            reason: 'Graphis returns null',
            user,
        });
    }
}


/**
 * I get the first server to fulfill a promise.
 *
 * :param isAdmin: whether user is supervisor or not
 * :param password: user password
 * :param user: username
 *
 * :returns: promise with server name
 */
async function getServer (
    isAdmin: boolean,
    password: string,
    user: string,
): Promise<string | null>
{
    // get last logged server
    let lastServer = localStorage.getItem(LOCAL_STORAGE_SERVER_KEY);

    // last server is defined: try to pre-authenticate it on catraquis
    if (lastServer !== null)
    {
        // pre-authenticate last server on catraquis
        try
        {
            lastServer = await preAuthenticate(
                isAdmin,
                password,
                lastServer,
                user,
            );
        }

        // pre-authentication failed: check error
        catch (error)
        {
            // authorization error: throw error
            if (error instanceof PreAuthorizationError)
            {
                throw error;
            }

            // other error: empty last server
            lastServer = null;
        }
    }

    // last server is not defined: return the first one to respond successfully
    if (lastServer === null)
    {
        // get server from metadata config
        const servers = Object.keys(Metadata.config.servers);

        // set servers promises list
        const promises = servers.map(server => preAuthenticate(
            isAdmin,
            password,
            server,
            user,
        ));

        // save first server to respond successfully or throw error
        await Promise.any(promises)
            .then(server =>
            {
                lastServer = server;
            })
            .catch(({errors}) =>
            {
                for (const error of errors)
                {
                    // authorization error: throw it separately
                    if (error instanceof PreAuthorizationError)
                    {
                        throw error;
                    }
                }

                // throw authentication error
                throw new PreAuthenticationError(errors[errors.length - 1]);
            });
    }

    // return last server
    return lastServer;
}


/**
 * I authenticate an user on catraquis.
 *
 * :param isAdmin: whether user is supervisor or not
 * :param password: user password
 * :param server: server domain
 * :param user: username
 *
 * :returns: promise with login error or null
 */
async function preAuthenticate (
    isAdmin: boolean,
    password: string,
    server: string,
    user: string,
): Promise<string>
{
    // initialize response
    let response: Response;

    // set user role
    const role = isAdmin ? 'supervisor' : 'agent';

    // response data
    let data: IPreAuthenticationData;

    // try to authenticate and authorize
    try
    {
        // request server
        response = await timeout(
            fetch(`https://${server}/sessions`, {
                body: JSON.stringify({password, role, user}),
                method: 'POST',
            }),
            Metadata.config.auth.timeout,
        );

        // load response data
        data = await response.json();
    }

    // request failed: log and throw error
    catch (error)
    {
        logger.error('CVJPZ0043E', {reason: error, server, user});
        throw new PreAuthenticationError(server);
    }

    // unauthorized: log and throw error
    if (response.status === 401 && data?.error === 'authorization')
    {
        logger.error('CVJPZ0043E', {reason: data?.error, server, user});
        throw new PreAuthorizationError(server);
    }

    // request was not successful: log and throw error
    if (response.status !== 201)
    {
        logger.error('CVJPZ0043E', {reason: data?.error, server, user});
        throw new PreAuthenticationError(server);
    }

    // request was successful: return server
    return server;
}


/**
 * I start user.
 *
 * :param id: user id
 * :param rooms: xmpp rooms
 *
 * :returns: promise with nothing
 */
async function start (id: string, rooms: string[]): Promise<void>
{
    // initialize response
    let response: void;

    // request authentication
    try
    {
        response = await startUser(id, rooms);
    }

    // authentication failed: log and return null
    catch (error)
    {
        logger.error('CVJPZ0027E', {reason: error, user: id});
        return null;
    }

    // return response
    return response;
}

/**
 * I authenticate an user on catraquis using its token.
 *
 * :param role: user role
 * :param server: server domain
 * :param token: user session token
 *
 * :returns: promise with response or null
 */
async function authenticateToken (
    role: string,
    server: string,
    token: string
): Promise<ILoginTokenResponse>
{
    // initialize response
    let response: Response;

    // initialize response content
    let content: IResponse;

    // try to authenticate
    try
    {
        response = await timeout(
            fetch(`https://${server}/sessions/${token}/roles/${role}`, {
                method: 'POST',
            }), TOKEN_AUTH_TIMEOUT,
        );
    }

    // authentication failed: raise LoginTokenError
    catch (error)
    {
        throw new LoginTokenError(error);
    }

    // request failed: raise LoginTokenError
    if (response === null || response === undefined)
    {
        const error = 'Token login failed: empty response';
        throw new LoginTokenError(error);
    }

    // request failed with 401
    if (response.status < 200 || response.status > 299)
    {
        const error = `Token login failed: Error ${response.status}`;
        throw new LoginTokenError(error);
    }

    // extract response fields
    try
    {
        content = await response.json();
    }

    // failed extrecting response fields: raise LoginTokenError
    catch (error)
    {
        throw new LoginTokenError(error);
    }

    // request was successful: return params
    return content.data;
}


/**
 * I get company config
 *
 * :returns: company config
 */
async function getCompanyConfig(): Promise<IGraphisCompanyConfig | null>
{
    // initialize response
    let response: IFetchCompanyConfigResponse;

    // get id, name and company from user
    const {id, name, company} = fetchUser();

    // fetch configurations
    try
    {
        response = await graphis.query(FETCH_COMPANY_CONFIG);
    }

    // load company configurations failed: log error and return null
    catch (error)
    {
        logger.error('CVJPZ0063E', {
            company,
            name: name,
            reason: error,
            user: id,
        });
        return null;
    }

    // company config failed with other reason: log error and return null
    if (response.data.companyConfig.__typename === 'Error')
    {
        // get company config error
        const error = response.data.companyConfig as IGraphisError;

        // log error
        logger.error('CVJPZ0063E', {
            company,
            name: name,
            reason: error.info ?? error.code,
            user: id,
        });
        return null;
    }

    // return company config
    return response.data.companyConfig as IGraphisCompanyConfig;
}


/**
 * I get user config
 *
 * :returns: user config
 */
async function getUserConfig(): Promise<IConfig | null>
{
    // initialize response
    let response: IFetchUserConfigResponse;

    // fetch user config
    try
    {
        response = await graphis.query(FETCH_USER_CONFIG);
    }

    // load user configurations failed: log error and return null
    catch (error)
    {
        // get id, name and company from user
        const {id, name, company} = fetchUser();

        // log error
        logger.error('CVJPZ0065E', {
            company,
            name: name,
            reason: error.info ?? error.code,
            user: id,
        });
        return null;
    }

    // get response data
    const data = response.data.userConfig as IGraphisUserConfig;

    // reduce messages
    const messages = data?.messages?.map(({body, key}) => ({body, key}));

    // return user config
    return {
        answerAlert: data?.answerAlert,
        messages,
    };
}


/**
 * I reduce set up quick messages
 *
 * :param shortcuts: list of quick messages
 * :param admin: wheter user is admin
 *
 * :returns: list of messages with proper format
 */
function reduceQuickMessages (
    shortcuts: IQuickMessage[],
    admin: boolean
): IQuickMessage[]
{
    // user is admin: return unreduced quick messages
    if (admin === true)
    {
        return shortcuts;
    }

    // initiate reduce quick messages list
    const reducedShortcuts: IQuickMessage[] = [];

    // add forward slash to each shortcut key
    shortcuts.forEach((shortcut) => reducedShortcuts.push(
        {
            body: shortcut.body,
            key: `/${shortcut.key}`,
        },
    ));

    // return reduced quick messages
    return reducedShortcuts;
}


/**
 * I update user config
 *
 * :param config: user config
 *
 * :returns: user config
 */
async function updateUserConfig (
    config: IConfig,
): Promise<IConfig | null>
{
    // initialize response
    let response: IUpdateUserConfigResponse;

    // try update user config
    try
    {
        response = await graphis.mutate(UPDATE_USER_CONFIG, {input: config});
    }

    // cannot update user config: log error and return null
    catch (error)
    {
        // get id, name and company from user
        const {id, name, company} = fetchUser();

        // log error
        logger.error('CVJPZ0066E', {
            company,
            name: name,
            reason: error.info ?? error.code,
            user: id,
        });
        return null;
    }

    // get response data
    const data = response.data.updateUserConfig as IGraphisUserConfig;

    // typename is error: log error and return null
    if (data.__typename === 'Error')
    {
        // get response as error
        const error = response.data.updateUserConfig as IGraphisError;

        // get id, name and company from user
        const {id, name, company} = fetchUser();

        // log error
        logger.error('CVJPZ0066E', {
            company,
            name: name,
            reason: error.info ?? error.code,
            user: id,
        });
        return null;
    }

    // reduce messages
    const messages = data?.messages?.map(({body, key}) => ({body, key}));

    // return updated user configuration
    return {
        answerAlert: data?.answerAlert,
        messages,
    };
}


/**
 * EXPORTS
 */
export {
    authenticate,
    authenticateToken,
    authorize,
    createNPSFeedback,
    getCompanyConfig,
    getServer,
    getUserConfig,
    preAuthenticate,
    reduceQuickMessages,
    start,
    updateUserConfig,
};
