
/**
 * IMPORTS
 */
import XmppError from 'src/aggregates/user/xmpp/err/xmpperror';


/**
 * CODE
 */
class InvalidPacket extends XmppError
{
}


/**
 * EXPORTS
 */
export default InvalidPacket;
