/**
 * IMPORTS
 */
import {Map} from 'immutable';
import initialState from 'src/aggregates/timelines/initialstate';
import Store from 'src/store';


/**
 * TYPES
 */
import {AckTypes} from 'src/aggregates/conversations/message/messages.d';
import {ITimelineAdded} from 'src/aggregates/timelines/events.d';
import {ITimelineAllContactMessagesLoaded}
    from 'src/aggregates/timelines/events.d';
import {ITimelineByConversationCleared}
    from 'src/aggregates/timelines/events.d';
import {ITimelineByConversationLoaded} from 'src/aggregates/timelines/events.d';
import {ITimelineByConversationMessagesLoaded}
    from 'src/aggregates/timelines/events.d';
import {ITimelineByConversationMessagesLoading}
    from 'src/aggregates/timelines/events.d';
import {ITimelineByConversationMessagesNotLoaded}
    from 'src/aggregates/timelines/events.d';
import {ITimelineByConversationRemoved}
    from 'src/aggregates/timelines/events.d';
import {ITimelineErrorReset} from 'src/aggregates/timelines/events.d';
import {ITimelineFocused} from 'src/aggregates/timelines/events.d';
import {ITimelineLoaded} from 'src/aggregates/timelines/events.d';
import {ITimelineLoading} from 'src/aggregates/timelines/events.d';
import {ITimelineMessageAckReceived} from 'src/aggregates/timelines/events.d';
import {ITimelineMessageByOriginalIdFetched}
    from 'src/aggregates/timelines/events.d';
import {ITimelineMessageByOriginalIdFetching}
    from 'src/aggregates/timelines/events.d';
import {ITimelineMessageByOriginalIdNotFetched}
    from 'src/aggregates/timelines/events.d';
import {ITimelineMessageOriginalIdReceived}
    from 'src/aggregates/timelines/events.d';
import {ITimelineMessagesLoaded} from 'src/aggregates/timelines/events.d';
import {ITimelineMessagesLoading} from 'src/aggregates/timelines/events.d';
import {ITimelineMessagesNotLoaded} from 'src/aggregates/timelines/events.d';
import {ITimelineNotLoaded} from 'src/aggregates/timelines/events.d';
import {ITimelineRemoved} from 'src/aggregates/timelines/events.d';
import {ITimelineTemplateSending} from 'src/aggregates/timelines/events.d';
import {ITimelineTemplateSent} from 'src/aggregates/timelines/events.d';
import {ITimelineTemplateNotSent} from 'src/aggregates/timelines/events.d';
import {types} from 'src/aggregates/timelines/events.d';
import {ITimeline} from 'src/aggregates/timelines/handlers.d';
import {ITimelineReducer} from 'src/aggregates/timelines/reducer.d';
import {ITimelineConversation} from 'src/aggregates/timelines/state.d';
import {ITimelineState} from 'src/aggregates/timelines/state.d';
import {getIndex} from 'src/aggregates/timelines/utils';


/**
 * CODE
 */

/**
 * Timeline events map.
 */
const eventsMap: ITimelineReducer = {

    /**
     * I update timeline state on timeline added event.
     *
     * :param state: timeline state
     * :param event: timeline added event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_ADDED]:
        (state: ITimelineState, event: ITimelineAdded): ITimelineState =>
        {
            // get store timeline events
            const timeline = [...state.timeline];

            // create timeline event
            const item: ITimelineConversation = {
                account: event.account,
                application: event.application,
                end: event.end,
                hasLoadMessagesError: false,
                id: event.id,
                isLoadingMessages: false,
                isSendingTemplate: false,
                lastInteraction: event.lastInteraction,
                remote: event.remote,
                start: event.start,
                status: event.status,
                subject: event.subject,
                templateRequired: false,
                templateSent: null,
            };

            // add event timeline event to store timeline events
            timeline.unshift(item);

            // return timeline state
            return {
                ...state,
                timeline,
            };
        },


    /**
     * I update timeline state on timeline all contact messages loaded event.
     *
     * :param state: timeline state
     * :param event: timeline all contact messages loaded event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_ALL_CONTACT_MESSAGES_LOADED]: (
        state: ITimelineState,
        event: ITimelineAllContactMessagesLoaded,
    ): ITimelineState =>
    {
        // return timeline state
        return {
            ...state,
            hasMore: false,
        };
    },


    /**
     * I update timeline state on timeline by conversation cleared event.
     *
     * :param state: timeline state
     * :param event: timeline by conversation cleared event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_BY_CONVERSATION_CLEARED]: (
        state: ITimelineState,
        event: ITimelineByConversationCleared,
    ): ITimelineState =>
    {
        return {
            ...state,
            byConversation: Map(),
        };
    },


    /**
     * I update timeline state on timeline by conversation loaded event.
     *
     * :param state: timeline state
     * :param event: timeline by conversation loaded event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_BY_CONVERSATION_LOADED]: (
        state: ITimelineState,
        event: ITimelineByConversationLoaded,
    ): ITimelineState =>
    {
        // add messages prop to timeline
        const timeline: ITimelineConversation[] = event.timeline.map(
            (timelineEvent): ITimelineConversation => ({
                ...timelineEvent,
                hasLoadMessagesError: false,
                isLoadingMessages: false,
                templateSent: timelineEvent.templateSent,
            }),
        );

        // update by conversation timeline
        const byConversation = state.byConversation.set(
            event.conversation,
            timeline,
        );

        // returns timeline state
        return {
            ...state,
            byConversation,
            hasLoadingError: false,
            hasMore: true,
            isLoading: false,
        };
    },


    /**
     * I update timeline state on timeline by conversation messages
     * loaded event.
     *
     * :param state: timeline state
     * :param event: timeline by conversation messages loaded event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_BY_CONVERSATION_MESSAGES_LOADED]: (
        state: ITimelineState,
        event: ITimelineByConversationMessagesLoaded,
    ): ITimelineState =>
    {
        // get timeline conversation
        const timeline = state.byConversation.get(event.conversation);

        // has no timeline loaded: return current state
        if (timeline === undefined)
        {
            return state;
        }

        // get index of conversation on timeline array
        const index = getIndex(event.timeline, timeline);

        // set conversation message state
        timeline[index] = {
            ...timeline[index],
            hasLoadMessagesError: false,
            isLoadingMessages: false,
            messages: event.messages,
        };

        // update by conversation timeline
        const byConversation = state.byConversation.set(
            event.conversation,
            [...timeline],
        );

        // return timeline state
        return {
            ...state,
            byConversation,
        };
    },


    /**
     * I update timeline state on timeline by conversation messages
     * loading event.
     *
     * :param state: timeline state
     * :param event: timeline by conversation messages loading event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_BY_CONVERSATION_MESSAGES_LOADING]: (
        state: ITimelineState,
        event: ITimelineByConversationMessagesLoading,
    ): ITimelineState =>
    {
        // get timeline conversation
        const timeline = state.byConversation.get(event.conversation);

        // has no timeline loaded: return current state
        if (timeline === undefined)
        {
            return state;
        }

        // get index of conversation on timeline array
        const index = getIndex(event.timeline, timeline);

        // set conversation message state
        timeline[index] = {
            ...timeline[index],
            hasLoadMessagesError: false,
            isLoadingMessages: true,
        };

        // update by conversation timeline
        const byConversation = state.byConversation.set(
            event.conversation,
            [...timeline],
        );

        // return timeline state
        return {
            ...state,
            byConversation,
        };
    },


    /**
     * I update timeline state on timeline by conversation messages
     * not loaded event.
     *
     * :param state: timeline state
     * :param event: timeline by conversation messages not loaded event
     *
     * :returns: timeline state
     */
    ['types.TIMELINE_BY_CONVERSATION_MESSAGES_NOT_LOADED']: (
        state: ITimelineState,
        event: ITimelineByConversationMessagesNotLoaded,
    ): ITimelineState =>
    {
        // get timeline conversation
        const timeline = state.byConversation.get(event.conversation);

        // has no timeline loaded: return current state
        if (timeline === undefined)
        {
            return state;
        }

        // get index of conversation on timeline array
        const index = getIndex(event.timeline, timeline);

        // set conversation message state
        timeline[index] = {
            ...timeline[index],
            hasLoadMessagesError: true,
            isLoadingMessages: false,
        };

        // update by conversation timeline
        const byConversation = state.byConversation.set(
            event.conversation,
            [...timeline],
        );

        // return timeline state
        return {
            ...state,
            byConversation,
        };
    },


    /**
     * I update timeline state on timeline by conversation removed event.
     *
     * :param state: timeline state
     * :param event: timeline by conversation removed event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_BY_CONVERSATION_REMOVED]: (
        state: ITimelineState,
        event: ITimelineByConversationRemoved,
    ): ITimelineState =>
    {
        // return timeline state
        return {
            ...state,
            byConversation: state.byConversation.remove(event.conversation),
        };
    },


    /**
     * I update timeline state on error reset.
     *
     * :param state: chat state
     * :param event: error reset event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_ERROR_RESET]: (
        state: ITimelineState,
        event: ITimelineErrorReset,
    ): ITimelineState => ({
        ...state,
        hasLoadingError: false,
        hasTemplateSendError: false,
    }),


    /**
     * I update timeline state on timeline focuesed event.
     *
     * :param state: timeline state
     * :param event: timeline focused event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_FOCUSED]: (
        state: ITimelineState,
        event: ITimelineFocused,
    ): ITimelineState =>
    {
        // return timeline state
        return {
            ...state,
            current: event._target,
            hasMore: true,
        };
    },


    /**
     * I update timeline state on timeline loaded event.
     *
     * :param state: timeline state
     * :param event: timeline loaded event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_LOADED]:
        (state: ITimelineState, event: ITimelineLoaded): ITimelineState =>
        {
            // add messages prop to timeline
            const timeline: ITimelineConversation[] = event.timeline.map(
                (timelineEvent: ITimeline): ITimelineConversation => ({
                    ...timelineEvent,
                    hasLoadMessagesError: false,
                    isLoadingMessages: false,
                    templateSent: timelineEvent.templateSent,
                }),
            );

            // return timeline state
            return {
                ...state,
                hasLoadingError: false,
                hasMore: true,
                isLoading: false,
                timeline,
            };
        },


    /**
     * I update timeline state on timeline loading event.
     *
     * :param state: timeline state
     * :param event: timeline loading event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_LOADING]:
        (state: ITimelineState, event: ITimelineLoading): ITimelineState =>
        {
            // return timeline state
            return {
                ...state,
                hasLoadingError: false,
                hasMore: true,
                isLoading: true,
                timeline: [],
            };
        },


    /**
     * I update timeline chat state on message ack received.
     *
     * :param state: chat state
     * :param event: message ack received event
     *
     * :returns: timeline chat state
     */
    [types.TIMELINE_MESSAGE_ACK_RECEIVED]:
    (
        state: ITimelineState,
        event: ITimelineMessageAckReceived,
    ): ITimelineState =>
    {
        // get store timeline events
        const timeline = [...state.timeline];

        // get index of conversation on timeline array
        const index = timeline.findIndex(
            (conversation: ITimelineConversation): boolean =>
                conversation.id === event._target,
        );

        // timeline conversation not found: return state
        if (index === -1)
        {
            return state;
        }

        // conversation exists: get conversation messages
        const messages = timeline[index].messages;

        // get message index
        const messageIndex = messages.findIndex(
            timelineMessage => timelineMessage.id === event.id,
        );

        // message doesn't exist: return unaltered state
        if (messageIndex === -1)
        {
            return state;
        }

        // update message ack
        const newMessage = {
            ...messages[messageIndex],
            ack: event.ack,
        };

        // update conversation messages
        messages[messageIndex] = newMessage;

        // update conversation
        timeline[index]= {
            ...timeline[index],
            messages,
        };

        // return conversation state
        return {
            ...state,
            timeline,
        };
    },


    /**
     * I update chat state on message by original id fetched.
     *
     * :param state: chat state
     * :param event: message by original id fetched event
     *
     * :returns: chat state
     */
    [types.TIMELINE_MESSAGE_BY_ORIGINAL_ID_FETCHED]:
        (state: ITimelineState,
         event: ITimelineMessageByOriginalIdFetched): ITimelineState =>
        {
            // get props from event
            const {
                _target: conversationId,
                current,
                data,
                message: messageId,
            } = event;

            // get conversation data
            const conversation = state.byConversation.get(current as number);

            // conversation exist in byConversation: find reply message
            if (conversation !== undefined)
            {
                // get conversation index
                const conversationIndex = conversation.findIndex(({id}) => (
                    id === conversationId
                ));

                // conversation exist: get message
                if (conversationIndex !== -1)
                {
                    // get conversation message
                    const messageIndex = conversation[conversationIndex]
                        .messages?.findIndex(({id}) => id === messageId);
                    const message = conversation[conversationIndex]
                        ?.messages[messageIndex];

                    // message exist: set replied message content and return
                    if (message !== undefined)
                    {
                        conversation[conversationIndex]
                            .messages[messageIndex] = {
                                ...message,
                                isFetchingReply: false,
                                repliedMessage: data,
                                replyFetched: true,
                            };

                        return {
                            ...state,
                            byConversation: state.byConversation.set(
                                current as number,
                                conversation,
                            ),
                        };
                    }
                }
            }

            // get timeline
            const timeline = [...state.timeline];

            // get index of conversation on timeline array
            const index = timeline.findIndex(({id}) => id === conversationId);

            // timeline conversation not found: return state
            if (index === -1)
            {
                return {...state};
            }

            // get messages
            const messages = timeline[index].messages;

            // get message index
            const messageIndex = messages.findIndex(({id}) => id === messageId);

            // message doesn't exist: return unaltered state
            if (messageIndex === -1)
            {
                return {...state};
            }

            // update message
            const updatedMessage = {
                ...messages[messageIndex],
                isFetchingReply: false,
                repliedMessage: data,
                replyFetched: true,
            };

            // update conversation messages
            messages[messageIndex] = updatedMessage;

            // update conversation
            timeline[index]= {
                ...timeline[index],
                messages,
            };

            // return conversation state
            return {
                ...state,
                timeline,
            };
        },


    /**
     * I update chat state on message by original id fetching.
     *
     * :param state: chat state
     * :param event: message by original id fetching event
     *
     * :returns: chat state
     */
    [types.TIMELINE_MESSAGE_BY_ORIGINAL_ID_FETCHING]:
        (state: ITimelineState,
         event: ITimelineMessageByOriginalIdFetching): ITimelineState =>
        {
            // get props from event
            const {
                _target: conversationId,
                current,
                message: messageId,
            } = event;

            // get conversation data
            const conversation = state.byConversation.get(current as number);

            // conversation exist in byConversation: find reply message
            if (conversation !== undefined)
            {
                // get conversation index
                const conversationIndex = conversation.findIndex(({id}) => (
                    id === conversationId
                ));

                // conversation exist: get message
                if (conversationIndex !== -1)
                {
                    // get conversation message
                    const messageIndex = conversation[conversationIndex]
                        .messages?.findIndex(({id}) => id === messageId);
                    const message = conversation[conversationIndex]
                        ?.messages[messageIndex];

                    // message exist: set replied message content and return
                    if (message !== undefined)
                    {
                        conversation[conversationIndex]
                            .messages[messageIndex] = {
                                ...message,
                                isFetchingReply: true,
                            };

                        return {
                            ...state,
                            byConversation: state.byConversation.set(
                                current as number,
                                conversation,
                            ),
                        };
                    }
                }
            }

            // get timeline
            const timeline = [...state.timeline];

            // get index of conversation on timeline array
            const index = timeline.findIndex(({id}) => id === conversationId);

            // timeline conversation not found: return state
            if (index === -1)
            {
                return state;
            }

            // get messages
            const messages = timeline[index].messages;

            // get message index
            const messageIndex = messages.findIndex(({id}) => id === messageId);

            // message doesn't exist: return unaltered state
            if (messageIndex === -1)
            {
                return state;
            }

            // update message ack
            const updatedMessage = {
                ...messages[messageIndex],
                isFetchingReply: true,
            };

            // update conversation messages
            messages[messageIndex] = updatedMessage;

            // update conversation
            timeline[index]= {
                ...timeline[index],
                messages,
            };

            // return conversation state
            return {
                ...state,
                timeline,
            };
        },


    /**
     * I update chat state on message by original id not fetched.
     *
     * :param state: chat state
     * :param event: message by original id not fetched event
     *
     * :returns: chat state
     */
    [types.TIMELINE_MESSAGE_BY_ORIGINAL_ID_NOT_FETCHED]:
        (state: ITimelineState,
         event: ITimelineMessageByOriginalIdNotFetched,
        ): ITimelineState =>
        {
            // get props from event
            const {
                _target: conversationId,
                current,
                message: messageId,
            } = event;

            // get conversation data
            const conversation = state.byConversation.get(current as number);

            // conversation exist in byConversation: find reply message
            if (conversation !== undefined)
            {
                // get conversation index
                const conversationIndex = conversation.findIndex(({id}) => (
                    id === conversationId
                ));

                // conversation exist: get message
                if (conversationIndex !== -1)
                {
                    // get conversation message
                    const messageIndex = conversation[conversationIndex]
                        .messages?.findIndex(({id}) => id === messageId);
                    const message = conversation[conversationIndex]
                        ?.messages[messageIndex];

                    // message exist: set replied message content and return
                    if (message !== undefined)
                    {
                        conversation[conversationIndex]
                            .messages[messageIndex] = {
                                ...message,
                                isFetchingReply: false,
                                replyFetched: true,
                            };

                        return {
                            ...state,
                            byConversation: state.byConversation.set(
                                current as number,
                                conversation,
                            ),
                        };
                    }
                }
            }

            // get timeline
            const timeline = [...state.timeline];

            // get index of conversation on timeline array
            const index = timeline.findIndex(({id}) => id === conversationId);

            // timeline conversation not found: return state
            if (index === -1)
            {
                return state;
            }

            // get messages
            const messages = timeline[index].messages;

            // get message index
            const messageIndex = messages.findIndex(({id}) => id === messageId);

            // message doesn't exist: return unaltered state
            if (messageIndex === -1)
            {
                return state;
            }

            // update message ack
            const updatedMessage = {
                ...messages[messageIndex],
                isFetchingReply: false,
                replyFetched: true,
            };

            // update conversation messages
            messages[messageIndex] = updatedMessage;

            // update conversation
            timeline[index]= {
                ...timeline[index],
                messages,
            };

            // return conversation state
            return {
                ...state,
                timeline,
            };
        },


    /**
    * I update timeline chat state on message original id received event.
    *
    * :param state: chat state
    * :param event: message original id received event
    *
    * :returns: timeline chat state
    */
    [types.TIMELINE_MESSAGE_ORIGINAL_ID_RECEIVED]:
    (
        state: ITimelineState,
        event: ITimelineMessageOriginalIdReceived,
    ): ITimelineState =>
    {
        // get store timeline events
        const timeline = [...state.timeline];

        // get index of conversation on timeline array
        const index = timeline.findIndex(
            (conversation: ITimelineConversation): boolean =>
                conversation.id === event._target,
        );

        // timeline conversation not found: return state
        if (index === -1)
        {
            return state;
        }

        // conversation exists: get conversation messages
        const messages = timeline[index].messages;

        // get message index
        const messageIndex = messages.findIndex(
            timelineMessage => timelineMessage.id === event.id,
        );

        // message doesn't exist: return unaltered state
        if (messageIndex === -1)
        {
            return state;
        }

        // message exists: update message
        const newMessage = {
            ...messages[messageIndex],
            originalId: event.originalId,
        };

        // update conversation messages
        messages[messageIndex] = newMessage;

        // update conversation
        timeline[index] = {
            ...timeline[index],
            messages,
        };

        // return conversation state
        return {
            ...state,
            timeline,
        };
    },

    /**
     * I update timeline state on timeline messages loaded event.
     *
     * :param state: timeline state
     * :param event: timeline messages loaded event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_MESSAGES_LOADED]: (
        state: ITimelineState,
        event: ITimelineMessagesLoaded,
    ): ITimelineState =>
    {
        // get timeline array
        const {timeline: conversations} = state;

        // get index of conversation on timeline array
        const index = getIndex(event._target, conversations);

        // copy timeline array
        const timeline = [...state.timeline];

        // set conversation message state
        timeline[index] = {
            ...timeline[index],
            hasLoadMessagesError: false,
            isLoadingMessages: false,
            messages: event.messages,
        };

        // return timeline state
        return {
            ...state,
            timeline,
        };
    },


    /**
     * I update timeline state on timeline messages loading event.
     *
     * :param state: timeline state
     * :param event: timeline messages loading event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_MESSAGES_LOADING]: (
        state: ITimelineState,
        event: ITimelineMessagesLoading,
    ): ITimelineState =>
    {
        // get timeline array
        const {timeline: conversations} = state;

        // get index of conversation on timeline array
        const index = getIndex(event._target, conversations);

        // copy timeline array
        const timeline = [...state.timeline];

        // set conversation message state
        timeline[index] = {
            ...timeline[index],
            hasLoadMessagesError: false,
            isLoadingMessages: true,
        };

        // return timeline state
        return {
            ...state,
            timeline,
        };
    },


    /**
     * I update timeline state on timeline messages not loaded event.
     *
     * :param state: timeline state
     * :param event: timeline messages not loaded event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_MESSAGES_NOT_LOADED]: (
        state: ITimelineState,
        event: ITimelineMessagesNotLoaded,
    ): ITimelineState =>
    {
        // get timeline array
        const {timeline: conversations} = state;

        // get index of conversation on timeline array
        const index = getIndex(event._target, conversations);

        // copy timeline array
        const timeline = [...state.timeline];

        // set conversation message state
        timeline[index] = {
            ...timeline[index],
            hasLoadMessagesError: true,
            isLoadingMessages: false,
        };

        // return timeline state
        return {
            ...state,
            timeline,
        };
    },


    /**
     * I update timeline state on timeline not loaded event.
     *
     * :param state: timeline state
     * :param event: timeline loading event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_NOT_LOADED]:
        (state: ITimelineState, event: ITimelineNotLoaded): ITimelineState =>
        {
            // return timeline state
            return {
                ...state,
                hasLoadingError: true,
                isLoading: false,
            };
        },


    /**
     * I update timeline state on timeline removed event.
     *
     * :param state: timeline state
     * :param event: timeline removed event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_REMOVED]:
        (state: ITimelineState, event: ITimelineRemoved): ITimelineState =>
        {
            // get store timeline events
            const timeline = [...state.timeline];

            // get index of conversation on timeline array
            const index = timeline.findIndex(
                (conversation: ITimelineConversation): boolean =>
                    conversation.id === event._target,
            );

            // remove conversation
            timeline.splice(index, 1);

            // return timeline state
            return {
                ...state,
                timeline,
            };
        },


    /**
     * I update timeline state on timeline template sending event.
     *
     * :param state: timeline state
     * :param event: timeline template sending event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_TEMPLATE_SENDING]:
        (state: ITimelineState, event: ITimelineTemplateSending):
        ITimelineState =>
        {
            // get store timeline events
            const timeline = [...state.timeline];

            // get template message payload
            const {payload} = event;

            // get index of conversation on timeline array
            const index = timeline.findIndex(
                (conversation: ITimelineConversation): boolean =>
                    conversation.id === event._target,
            );

            // timeline not found: return with error
            if (timeline[index] === null || timeline[index] === undefined)
            {
                return {...state, hasTemplateSendError: true};
            }

            // chat exists: get messages
            const messages = timeline[index].messages;

            // include template message in timeline
            messages.push(payload);

            // set conversation
            timeline[index] = {
                ...timeline[index],
                isSendingTemplate: true,
                lastInteraction: Date.now() / 1000,
                messages,
            };

            // return chat state
            return {
                ...state,
                hasTemplateSendError: false,
                timeline,
            };
        },


    /**
     * I update timeline state on timeline template sent event.
     *
     * :param state: timeline state
     * :param event: timeline template sent event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_TEMPLATE_SENT]:
        (state: ITimelineState, event: ITimelineTemplateSent):
        ITimelineState =>
        {
            // get conversation id
            const {_target: id} = event;

            // get old originalId
            const {oldOriginalId} = event;

            // get message payload returned from conversis
            const {payload} = event;

            // get store timeline events
            const timeline = [...state.timeline];

            // get index of conversation on timeline array
            const index = timeline.findIndex(
                (conversation: ITimelineConversation): boolean =>
                    conversation.id === event._target,
            );

            // timeline conversation not found: return state
            if (index === -1)
            {
                return state;
            }

            // conversation exists: get conversation messages
            const messages = timeline[index].messages;

            // get message index
            const messageIndex = messages.findIndex(
                timelineMessage => timelineMessage.originalId === oldOriginalId,
            );

            // message exists: update message
            if (messageIndex !== -1)
            {
                // update timeline message with response
                messages[messageIndex] = payload;

                // update conversation
                timeline[index] = {
                    ...timeline[index],
                    isSendingTemplate: false,
                    messages,
                    templateSent: payload.timestamp,
                };
            }

            // return chat state
            return {
                ...state,
                hasTemplateSendError: false,
                timeline,
            };
        },


    /**
     * I update timeline state on timeline template not sent event.
     *
     * :param state: timeline state
     * :param event: timeline template not sent event
     *
     * :returns: timeline state
     */
    [types.TIMELINE_TEMPLATE_NOT_SENT]:
        (state: ITimelineState, event: ITimelineTemplateNotSent):
        ITimelineState =>
        {
            // get store timeline events
            const timeline = [...state.timeline];

            // get index of conversation on timeline array
            const index = timeline.findIndex(
                (conversation: ITimelineConversation): boolean =>
                    conversation.id === event._target,
            );

            // get message props
            const {originalId} = event;

            // get conversation
            const conversation = timeline[index];

            // conversation does not exist: return unaltered state
            if (conversation === undefined)
            {
                return state;
            }

            // conversation exists: get conversation messages
            const messages = conversation.messages;

            // get message
            const messageIndex = messages.findIndex(
                timelineMessage =>
                    timelineMessage.originalId === originalId,
            );

            // message exists: update message
            if (messageIndex !== -1)
            {
                // update message ack to not transmitted
                messages[messageIndex] = {
                    ...messages[messageIndex],
                    ack: AckTypes.NOT_TRANSMITTED,
                };

                // update conversation messages
                timeline[index] = {
                    ...conversation,
                    isSendingTemplate: false,
                    messages,
                };
            }

            // return updated conversation state
            return {
                ...state,
                hasTemplateSendError: true,
                timeline,
            };
        },
};


/**
 * User reducer.
 */
const reducer = Store.createReducer(initialState, eventsMap);


/**
 * EXPORTS
 */
export default reducer;
