/**
 * TYPES
 */
import {AckTypes} from 'src/aggregates/conversations/message/messages.d';
import {IAgentSession} from 'src/aggregates/conversations/queries.d';
import {IConversationLoaded as IGraphisConversationLoaded}
    from 'src/aggregates/conversations/queries.d';
import {IOngoingConversation} from 'src/aggregates/conversations/queries.d';
import {IConversation} from 'src/aggregates/conversations/state.d';
import {IConversationHistory} from 'src/aggregates/conversations/state.d';
import {IMessage} from 'src/aggregates/conversations/state.d';
import {IRealTimeMetrics} from 'src/aggregates/conversations/state.d';
import {IConversationsMetrics} from 'src/aggregates/conversations/utils.d';
import {IMessagesMetricsByAgent} from 'src/aggregates/conversations/utils.d';
import {_aggregates} from 'src/aggregates/index.d';
import {IEvent} from 'src/aggregates/index.d';
import {IAttachment} from 'src/aggregates/timelines/state.d';
import {IReferral} from 'src/aggregates/timelines/state.d';
import {IMessage as ITimelineMessage} from 'src/aggregates/timelines/state.d';
import {ToastTypes} from 'src/aggregates/utils/feedback';
import {IMessage as IComponentMessage}
    from 'src/components/Shared/Message/index.d';


/**
 * EXPORTS
 */

/**
 * Events types.
 */
export enum types
{
    CONVERSATION_ADDED = 'CONVERSATION_ADDED',
    CONVERSATION_AGENTS_MESSAGES_METRICS_FETCHED =
        'CONVERSATION_AGENTS_MESSAGES_METRICS_FETCHED',
    CONVERSATION_AGENTS_MESSAGES_METRICS_FETCHING =
        'CONVERSATION_AGENTS_MESSAGES_METRICS_FETCHING',
    CONVERSATION_AGENTS_MESSAGES_METRICS_NOT_FETCHED =
        'CONVERSATION_AGENTS_MESSAGES_METRICS_NOT_FETCHED',
    CONVERSATION_ALL_PAGES_LOADED = 'CONVERSATION_ALL_PAGES_LOADED',
    CONVERSATION_ALREADY_EXISTS = 'CONVERSATION_ALREADY_EXISTS',
    CONVERSATION_AUTOANSWERED = 'CONVERSATION_AUTOANSWERED',
    CONVERSATION_CREATED = 'CONVERSATION_CREATED',
    CONVERSATION_CREATING = 'CONVERSATION_CREATING',
    CONVERSATION_ERROR_RESET = 'CONVERSATION_ERROR_RESET',
    CONVERSATION_EXITED = 'CONVERSATION_EXITED',
    CONVERSATION_FEEDBACK_ADDED = 'CONVERSATION_FEEDBACK_ADDED',
    CONVERSATION_FEEDBACK_REMOVED = 'CONVERSATION_FEEDBACK_REMOVED',
    CONVERSATION_FETCHED = 'CONVERSATION_FETCHED',
    CONVERSATION_FETCHING = 'CONVERSATION_FETCHING',
    CONVERSATION_FILE_NOT_UPLOADED = 'CONVERSATION_FILE_NOT_UPLOADED',
    CONVERSATION_FILE_UPLOADED = 'CONVERSATION_FILE_UPLOADED',
    CONVERSATION_FILE_UPLOADING = 'CONVERSATION_FILE_UPLOADING',
    CONVERSATION_FINISHED = 'CONVERSATION_FINISHED',
    CONVERSATION_FINISHING = 'CONVERSATION_FINISHING',
    CONVERSATION_FOCUSED = 'CONVERSATION_FOCUSED',
    CONVERSATION_FORWARD_STARTED = 'CONVERSATION_FORWARD_STARTED',
    CONVERSATION_FORWARD_STOPPED = 'CONVERSATION_FORWARD_STOPPED',
    CONVERSATION_FORWARDING_MESSAGE_ADDED =
        'CONVERSATION_FORWARDING_MESSAGE_ADDED',
    CONVERSATION_FORWARDING_MESSAGE_REMOVED =
        'CONVERSATION_FORWARDING_MESSAGE_REMOVED',
    CONVERSATION_HISTORY_ADDED = 'CONVERSATION_HISTORY_ADDED',
    CONVERSATION_LOADED = 'CONVERSATION_LOADED',
    CONVERSATION_LOADED_ALL_BY_PERIOD = 'CONVERSATION_LOADED_ALL_BY_PERIOD',
    CONVERSATION_LOADED_BY_CUSTOMER = 'CONVERSATION_LOADED_BY_CUSTOMER',
    CONVERSATION_LOADED_BY_PERIOD = 'CONVERSATION_LOADED_BY_PERIOD',
    CONVERSATION_LOADED_ONGOING_BY_CUSTOMER =
    'CONVERSATION_LOADED_ONGOING_BY_CUSTOMER',
    CONVERSATION_LOADING = 'CONVERSATION_LOADING',
    CONVERSATION_LOADING_BY_CUSTOMER = 'CONVERSATION_LOADING_BY_CUSTOMER',
    CONVERSATION_LOADING_BY_PERIOD = 'CONVERSATION_LOADING_BY_PERIOD',
    CONVERSATION_LOADING_ONGOING_BY_CUSTOMER =
        'CONVERSATION_LOADING_ONGOING_BY_CUSTOMER',
    CONVERSATION_MESSAGE_ACK_RECEIVED = 'CONVERSATION_MESSAGE_ACK_RECEIVED',
    CONVERSATION_MESSAGE_BY_ORIGINAL_ID_FETCHED =
        'CONVERSATION_MESSAGE_BY_ORIGINAL_ID_FETCHED',
    CONVERSATION_MESSAGE_BY_ORIGINAL_ID_FETCHING =
        'CONVERSATION_MESSAGE_BY_ORIGINAL_ID_FETCHING',
    CONVERSATION_MESSAGE_BY_ORIGINAL_ID_NOT_FETCHED =
        'CONVERSATION_MESSAGE_BY_ORIGINAL_ID_NOT_FETCHED',
    CONVERSATION_MESSAGE_NOT_SENT = 'CONVERSATION_MESSAGE_NOT_SENT',
    CONVERSATION_MESSAGE_ORIGINAL_ID_RECEIVED = 'CONVERSATION_MESSAGE_ORIGINAL_ID_RECEIVED',
    CONVERSATION_MESSAGE_RECEIVED = 'CONVERSATION_MESSAGE_RECEIVED',
    CONVERSATION_MESSAGE_RETRYING = 'CONVERSATION_MESSAGE_RETRYING',
    CONVERSATION_MESSAGE_SENDING = 'CONVERSATION_MESSAGE_SENDING',
    CONVERSATION_MESSAGE_SENT = 'CONVERSATION_MESSAGE_SENT',
    CONVERSATION_METRICS_FETCHED = 'CONVERSATION_METRICS_FETCHED',
    CONVERSATION_METRICS_FETCHING = 'CONVERSATION_METRICS_FETCHING',
    CONVERSATION_METRICS_NOT_FETCHED = 'CONVERSATION_METRICS_NOT_FETCHED',
    CONVERSATION_METRICS_PERIOD_SET = 'CONVERSATION_METRICS_PERIOD_SET',
    CONVERSATION_NOT_CREATED = 'CONVERSATION_NOT_CREATED',
    CONVERSATION_NOT_FETCHED = 'CONVERSATION_NOT_FETCHED',
    CONVERSATION_NOT_FINISHED = 'CONVERSATION_NOT_FINISHED',
    CONVERSATION_NOT_LOADED = 'CONVERSATION_NOT_LOADED',
    CONVERSATION_NOT_LOADED_BY_CUSTOMER = 'CONVERSATION_NOT_LOADED_BY_CUSTOMER',
    CONVERSATION_NOT_LOADED_BY_PERIOD = 'CONVERSATION_NOT_LOADED_BY_PERIOD',
    CONVERSATION_NOT_LOADED_ONGOING_BY_CUSTOMER =
        'CONVERSATION_NOT_LOADED_ONGOING_BY_CUSTOMER',
    CONVERSATION_NOT_TRANSFERRED = 'CONVERSATION_NOT_TRANSFERRED',
    CONVERSATION_HEARTBEAT_SUCCEEDED = 'CONVERSATION_HEARTBEAT_SUCCEEDED',
    CONVERSATION_ONLINE_AGENT_ADDED = 'CONVERSATION_ONLINE_AGENT_ADDED',
    CONVERSATION_ONLINE_AGENTS_NOT_LOADED = 'CONVERSATION_ONLINE_AGENTS_NOT_LOADED',
    CONVERSATION_ONLINE_AGENTS_LOADED = 'CONVERSATION_ONLINE_AGENTS_LOADED',
    CONVERSATION_ONLINE_AGENTS_LOADING = 'CONVERSATION_ONLINE_AGENTS_LOADING',
    CONVERSATION_ORDER_PRIORITIZED = 'CONVERSATION_ORDER_PRIORITIZED',
    CONVERSATION_PAGE_LOADED = 'CONVERSATION_PAGE_LOADED',
    CONVERSATION_PAGE_LOADING = 'CONVERSATION_PAGE_LOADING',
    CONVERSATION_PAGE_NOT_LOADED = 'CONVERSATION_PAGE_NOT_LOADED',
    CONVERSATION_REALTIME_METRICS_FETCHED
        = 'CONVERSATION_REALTIME_METRICS_FETCHED',
    CONVERSATION_REALTIME_METRICS_FETCHING
        = 'CONVERSATION_REALTIME_METRICS_FETCHING',
    CONVERSATION_REALTIME_METRICS_NOT_FETCHED
        = 'CONVERSATION_REALTIME_METRICS_NOT_FETCHED',
    CONVERSATION_RECEIVED = 'CONVERSATION_RECEIVED',
    CONVERSATION_REMOVED = 'CONVERSATION_REMOVED',
    CONVERSATION_SESSION_CLOSED = 'CONVERSATION_SESSION_CLOSED',
    CONVERSATION_SESSION_FORCIBLY_ENDED = 'CONVERSATION_SESSION_FORCIBLY_ENDED',
    CONVERSATION_SESSION_NOT_OPENED = 'CONVERSATION_SESSION_NOT_OPENED',
    CONVERSATION_SESSION_OPENED = 'CONVERSATION_SESSION_OPENED',
    CONVERSATION_SHOULD_EXIT = 'CONVERSATION_SHOULD_EXIT',
    CONVERSATION_STARTED = 'CONVERSATION_STARTED',
    CONVERSATION_STOPPED = 'CONVERSATION_STOPPED',
    CONVERSATION_TEMPLATE_NOT_SENT = 'CONVERSATION_TEMPLATE_NOT_SENT',
    CONVERSATION_TEMPLATE_SENDING = 'CONVERSATION_TEMPLATE_SENDING',
    CONVERSATION_TEMPLATE_SENT = 'CONVERSATION_TEMPLATE_SENT',
    CONVERSATION_TRANSFER_RESET = 'CONVERSATION_TRANSFER_RESET',
    CONVERSATION_TRANSFERRED = 'CONVERSATION_TRANSFERRED',
    CONVERSATION_TRANSFERRING = 'CONVERSATION_TRANSFERRING',
    CONVERSATION_TYPED_TEXT = 'CONVERSATION_TYPED_TEXT',
    CONVERSATION_UNSUPPORTED_MESSAGE_SENT = 'CONVERSATION_UNSUPPORTED_MESSAGE_SENT',
    CONVERSATION_WITH_ANOTHER_AGENT = 'CONVERSATION_WITH_ANOTHER_AGENT',
    CONVERSATION_WITH_ANOTHER_APP = 'CONVERSATION_WITH_ANOTHER_APP'
}


/**
 * Events interfaces.
 */
export interface IConversationAdded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_ADDED;
    account: number;
    channel: string;
    customer?: string | number;
    end: number | null;
    hasAgentAnswer: boolean | null;
    history: (number | string)[];
    isSendingTemplate: boolean;
    lastInteraction: number;
    messages: object;
    ongoing: (number | string)[];
    remote: string;
    start: number;
    subject: string | null;
    templateRequired: boolean;
    templateSent: number | null;
    type: types.CONVERSATION_ADDED;
    unansweredMessages: number;
}

export interface IConversationAgentsMessagesMetricsFetched extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_AGENTS_MESSAGES_METRICS_FETCHED;
    metrics: IMessagesMetricsByAgent;
    type: types.CONVERSATION_AGENTS_MESSAGES_METRICS_FETCHED;
}

export interface IConversationAgentsMessagesMetricsFetching extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_AGENTS_MESSAGES_METRICS_FETCHING;
    type: types.CONVERSATION_AGENTS_MESSAGES_METRICS_FETCHING;
}

export interface IConversationAgentsMessagesMetricsNotFetched extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_AGENTS_MESSAGES_METRICS_NOT_FETCHED;
    type: types.CONVERSATION_AGENTS_MESSAGES_METRICS_NOT_FETCHED;
}

export interface IConversationAllPagesLoaded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_ALL_PAGES_LOADED;
    type: types.CONVERSATION_ALL_PAGES_LOADED;
}

export interface IConversationAlreadyExists extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_ALREADY_EXISTS;
    type: types.CONVERSATION_ALREADY_EXISTS;
}

export interface IConversationAutoAnswered extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_AUTOANSWERED;
    type: types.CONVERSATION_AUTOANSWERED;
}

export interface IConversationCreated extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_CREATED;
    type: types.CONVERSATION_CREATED;
}

export interface IConversationCreating extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_CREATING;
    type: types.CONVERSATION_CREATING;
}

export interface IConversationErrorReset extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_ERROR_RESET;
    type: types.CONVERSATION_ERROR_RESET;
}

export interface IConversationExited extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_EXITED;
    status: number;
    type: types.CONVERSATION_EXITED;
}

export interface IConversationFeedbackAdded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FEEDBACK_ADDED;
    closable: boolean;
    duration: number;
    message: string;
    title: string;
    toastType: ToastTypes;
    type: types.CONVERSATION_FEEDBACK_ADDED;
}

export interface IConversationFeedbackRemoved extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FEEDBACK_REMOVED;
    index: number;
    type: types.CONVERSATION_FEEDBACK_REMOVED;
}

export interface IConversationFetched extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FETCHED;
    hasAgentAnswer: boolean | null;
    messages: ITimelineMessage[];
    type: types.CONVERSATION_FETCHED;
}

export interface IConversationFetching extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FETCHING;
    type: types.CONVERSATION_FETCHING;
}

export interface IConversationFileNotUploaded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FILE_NOT_UPLOADED;
    message: string;
    type: types.CONVERSATION_FILE_NOT_UPLOADED;
}

export interface IConversationFileUploaded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FILE_UPLOADED;
    message: string;
    type: types.CONVERSATION_FILE_UPLOADED;
}

export interface IConversationFileUploading extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FILE_UPLOADING;
    message: string;
    progress: number;
    type: types.CONVERSATION_FILE_UPLOADING;
}

export interface IConversationFinished extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FINISHED;
    conversations: number[];
    type: types.CONVERSATION_FINISHED;
}

export interface IConversationFinishing extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FINISHING;
    conversations: number[];
    type: types.CONVERSATION_FINISHING;
}

export interface IConversationFocused extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FOCUSED;
    currentAccount: number | null;
    currentChannel: string;
    currentContact: number | string | null;
    current: number | string | null;
    type: types.CONVERSATION_FOCUSED;
}

export interface IConversationForwardStarted extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FORWARD_STARTED;
    type: types.CONVERSATION_FORWARD_STARTED;
}

export interface IConversationForwardStopped extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FORWARD_STOPPED;
    type: types.CONVERSATION_FORWARD_STOPPED;
}

export interface IConversationForwardingMessageAdded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FORWARDING_MESSAGE_ADDED;
    message: IComponentMessage;
    type: types.CONVERSATION_FORWARDING_MESSAGE_ADDED;
}

export interface IConversationForwardingMessageRemoved extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_FORWARDING_MESSAGE_REMOVED;
    message: number;
    type: types.CONVERSATION_FORWARDING_MESSAGE_REMOVED;
}

export interface IConversationHeartbeatSucceeded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_HEARTBEAT_SUCCEEDED;
    conversationsOnQueue: number;
    type: types.CONVERSATION_HEARTBEAT_SUCCEEDED;
}

export interface IConversationHistoryAdded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_HISTORY_ADDED;
    conversations: IConversationHistory[];
    type: types.CONVERSATION_HISTORY_ADDED;
}

export interface IConversationLoaded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_LOADED;
    conversations: IOngoingConversation[];
    ongoing: (number | string)[];
    type: types.CONVERSATION_LOADED;
}

export interface IConversationLoadedAllByPeriod extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_LOADED_ALL_BY_PERIOD;
    agent: string;
    since: number;
    type: types.CONVERSATION_LOADED_ALL_BY_PERIOD;
}

export interface IConversationLoadedByCustomer extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_LOADED_BY_CUSTOMER;
    customer: number | string;
    newIds: IConversation['id'][];
    type: types.CONVERSATION_LOADED__BY_CUSTOMER;
}

export interface IConversationLoadedOngoingByCustomer extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_LOADED_ONGOING_BY_CUSTOMER;
    customer: number | string;
    ids: number[];
    type: types.CONVERSATION_LOADED_ONGOING_BY_CUSTOMER;
}

export interface IConversationLoadingOngoingByCustomer extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_LOADING_ONGOING_BY_CUSTOMER;
    type: types.CONVERSATION_LOADING_ONGOING_BY_CUSTOMER;
}

export interface IConversationsByPeriod extends IGraphisConversationLoaded
{
    customerId: string | number;
}

export interface IConversationLoadedByPeriod extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_LOADED_BY_PERIOD;
    agent: string;
    newIds: number[];
    since: number;
    type: types.CONVERSATION_LOADED_BY_PERIOD;
}

export interface IConversationLoading extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_LOADING;
    type: types.CONVERSATION_LOADING;
}

export interface IConversationLoadingByCustomer extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_LOADING_BY_CUSTOMER;
    customer: number | string;
    type: types.CONVERSATION_LOADING_BY_CUSTOMER;
}

export interface IConversationLoadingByPeriod extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_LOADING_BY_PERIOD;
    agent: string;
    type: types.CONVERSATION_LOADING_BY_PERIOD;
}

export interface IConversationMessageAckReceived extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_MESSAGE_ACK_RECEIVED;
    id: number;
    ack: AckTypes;
    type: types.CONVERSATION_MESSAGE_ACK_RECEIVED;
}

export interface IConversationMessageByOriginalIdFetched extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_MESSAGE_BY_ORIGINAL_ID_FETCHED;
    data: ITimelineMessage;
    message: number;
    type: types.CONVERSATION_MESSAGE_BY_ORIGINAL_ID_FETCHED;
}

export interface IConversationMessageByOriginalIdFetching extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_MESSAGE_BY_ORIGINAL_ID_FETCHING;
    message: number;
    type: types.CONVERSATION_MESSAGE_BY_ORIGINAL_ID_FETCHING;
}

export interface IConversationMessageByOriginalIdNotFetched extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_MESSAGE_BY_ORIGINAL_ID_NOT_FETCHED;
    message: number;
    type: types.CONVERSATION_MESSAGE_BY_ORIGINAL_ID_NOT_FETCHED;
}

export interface IConversationMessageNotSent extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_MESSAGE_NOT_SENT;
    message: IMessage;
    type: types.CONVERSATION_MESSAGE_NOT_SENT;
}


export interface IConversationMessageOriginalIdReceived extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_MESSAGE_ORIGINAL_ID_RECEIVED;
    id: number;
    originalId: string;
    type: types.CONVERSATION_MESSAGE_ORIGINAL_ID_RECEIVED;
}


export interface IConversationMessageReceived extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_MESSAGE_RECEIVED;
    ack: string;
    attachments: IAttachment[],
    body: string;
    caption: string;
    deleted: number | null;
    id: number;
    messageClass: string;
    messageType: string;
    name: string;
    ongoing: (number | string)[];
    originalId: string;
    reducedBody: any;
    referral: IReferral | null;
    reply: string | null;
    timestamp: number;
    uid: string;
    unansweredMessages: number | null;
    type: types.CONVERSATION_MESSAGE_RECEIVED;
}

export interface IConversationMessageRetrying extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_MESSAGE_RETRYING;
    message: IMessage;
    type: types.CONVERSATION_MESSAGE_RETRYING;
}

export interface IConversationMessageSending extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_MESSAGE_SENDING;
    message: IMessage;
    ongoing: (number | string)[];
    type: types.CONVERSATION_MESSAGE_SENDING;
}

export interface IConversationMessageSent extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_MESSAGE_SENT;
    message: IMessage;
    oldOriginalId: string;
    type: types.CONVERSATION_MESSAGE_SENT;
}

export interface IConversationMetricsFetched extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_METRICS_FETCHED;
    metrics: IConversationsMetrics;
    type: types.CONVERSATION_METRICS_FETCHED;
}

export interface IConversationMetricsFetching extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_METRICS_FETCHING;
    type: types.CONVERSATION_METRICS_FETCHING;
}

export interface IConversationMetricsNotFetched extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_METRICS_NOT_FETCHED;
    type: types.CONVERSATION_METRICS_NOT_FETCHED;
}

export interface IConversationMetricsPeriodSet extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_METRICS_PERIOD_SET;
    endDate: number;
    startDate: number;
    type: types.CONVERSATION_METRICS_PERIOD_SET;
}

export interface IConversationNotCreated extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_NOT_CREATED;
    error: Error;
    type: types.CONVERSATION_NOT_CREATED;
}

export interface IConversationNotFetched extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_NOT_FETCHED;
    type: types.CONVERSATION_NOT_FETCHED;
}

export interface IConversationNotFinished extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_NOT_FINISHED;
    type: types.CONVERSATION_NOT_FINISHED;
}

export interface IConversationNotLoaded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_NOT_LOADED;
    type: types.CONVERSATION_NOT_LOADED;
}

export interface IConversationNotLoadedByCustomer extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_NOT_LOADED_BY_CUSTOMER;
    customer: number | string;
    type: types.CONVERSATION_NOT_LOADED_BY_CUSTOMER;
}

export interface IConversationNotLoadedByPeriod extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_NOT_LOADED_BY_PERIOD;
    agent: string;
    type: types.CONVERSATION_NOT_LOADED_BY_PERIOD;
}

export interface IConversationNotLoadedOngoingByCustomer extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_NOT_LOADED_ONGOING_BY_CUSTOMER;
    type: types.CONVERSATION_NOT_LOADED_ONGOING_BY_CUSTOMER;
}

export interface IConversationNotTransferred extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_NOT_TRANSFERRED;
    session: string;
    to: string;
    type: types.CONVERSATION_NOT_TRANSFERRED;
}

export interface IConversationOnlineAgentAdded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_ONLINE_AGENT_ADDED;
    agent: IAgentSession;
    type: types.CONVERSATION_ONLINE_AGENT_ADDED;
}

export interface IConversationOnlineAgentsLoaded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_ONLINE_AGENTS_LOADED;
    type: types.CONVERSATION_ONLINE_AGENTS_LOADED;
}

export interface IConversationOnlineAgentsLoading extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_ONLINE_AGENTS_LOADING;
    type: types.CONVERSATION_ONLINE_AGENTS_LOADING;
}

export interface IConversationOrderPrioritized extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_ORDER_PRIORITIZED;
    history: (number | string)[];
    ongoing: (number | string)[];
    type: types.CONVERSATION_ORDER_PRIORITIZED;
}


export interface IConversationOnlineAgentsNotLoaded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_ONLINE_AGENTS_NOT_LOADED;
    type: types.CONVERSATION_ONLINE_AGENTS_NOT_LOADED;
}

export interface IConversationPageLoaded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_PAGE_LOADED;
    history: (number | string)[];
    page: number;
    type: types.CONVERSATION_PAGE_LOADED;
}

export interface IConversationPageLoading extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_PAGE_LOADING;
    type: types.CONVERSATION_PAGE_LOADING;
}

export interface IConversationPageNotLoaded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_PAGE_NOT_LOADED;
    type: types.CONVERSATION_PAGE_NOT_LOADED;
}

export interface IConversationRealTimeMetricsFetched extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_REALTIME_METRICS_FETCHED;
    metrics: IRealTimeMetrics;
    type: types.CONVERSATION_REALTIME_METRICS_FETCHED;
}

export interface IConversationRealTimeMetricsFetching extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_REALTIME_METRICS_FETCHING;
    type: types.CONVERSATION_REALTIME_METRICS_FETCHING;
}

export interface IConversationRealTimeMetricsNotFetched extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_REALTIME_METRICS_NOT_FETCHED;
    type: types.CONVERSATION_REALTIME_METRICS_NOT_FETCHED;
}

export interface IConversationRemoved extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_REMOVED;
    history: (number | string)[];
    ongoing: (number | string)[];
    type: types.CONVERSATION_REMOVED;
}

export interface IConversationSessionClosed extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_SESSION_CLOSED;
    type: types.CONVERSATION_SESSION_CLOSED;
}

export interface IConversationSessionForciblyEnded extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_SESSION_FORCIBLY_ENDED;
    type: types.CONVERSATION_SESSION_FORCIBLY_ENDED;
}

export interface IConversationSessionNotOpened extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_SESSION_NOT_OPENED;
    type: types.CONVERSATION_SESSION_NOT_OPENED;
}

export interface IConversationSessionOpened extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_SESSION_OPENED;
    push: IPush;
    session: string;
    type: types.CONVERSATION_SESSION_OPENED;
}

export interface IConversationShouldExit extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_SHOULD_EXIT;
    type: types.CONVERSATION_SHOULD_EXIT;
}

export interface IConversationStarted extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_STARTED;
    type: types.CONVERSATION_STARTED;
}

export interface IConversationStopped extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_STOPPED;
    type: types.CONVERSATION_STOPPED;
}

export interface IConversationTemplateNotSent extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_TEMPLATE_NOT_SENT;
    originalId: string;
    type: types.CONVERSATION_TEMPLATE_NOT_SENT;
}

export interface IConversationTemplateSending extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_TEMPLATE_SENDING;
    payload: IMessage;
    type: types.CONVERSATION_TEMPLATE_SENDING;
}

export interface IConversationTemplateSent extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_TEMPLATE_SENT;
    oldOriginalId: string,
    payload: IMessage,
    type: types.CONVERSATION_TEMPLATE_SENT;
}

export interface IConversationTransferred extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_TRANSFERRED;
    session: string;
    to: string;
    type: types.CONVERSATION_TRANSFERRED;
}

export interface IConversationTransferring extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_TRANSFERRING;
    session: string;
    to: string;
    type: types.CONVERSATION_TRANSFERRING;
}

export interface IConversationTransferReset extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_TRANSFER_RESET;
    type: types.CONVERSATION_TRANSFER_RESET;
}

export interface IConversationTypedText extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_TYPED_TEXT;
    text: string;
    type: types.CONVERSATION_TYPED_TEXT;
}

export interface IConversationUnsupportedMessageSent extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_UNSUPPORTED_MESSAGE_SENT;
    body: string;
    originalId: string;
    type: types.CONVERSATION_UNSUPPORTED_MESSAGE_SENT;
}

export interface IConversationWithAnotherAgent extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_WITH_ANOTHER_AGENT;
    agent: string;
    type: types.CONVERSATION_WITH_ANOTHER_AGENT;
}

export interface IConversationWithAnotherApp extends IEvent
{
    _aggregate: _aggregates.CONVERSATIONS;
    _name: types.CONVERSATION_WITH_ANOTHER_APP;
    app: string;
    type: types.CONVERSATION_WITH_ANOTHER_APP;
}
