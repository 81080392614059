/**
 * IMPORTS
 */
import {IUserState} from 'src/aggregates/user/state.d';
import {Statuses} from 'src/aggregates/user/state.d';


/**
 * User initial state.
 */
const initialState: IUserState = {
    company: null,
    companyConfig: null,
    config: {
        answerAlert: null,
        messages: [],
    },
    hasLoadConfigError: false,
    hasStartError: false,
    hasUpdateConfigError: false,
    id: null,
    isAdmin: false,
    isAuthorized: false,
    isAuthorizing: false,
    isLoadingConfig: false,
    isStarting: false,
    isUpdatingConfig: false,
    loginError: null,
    name: null,
    notification: null,
    password: null,
    popup: null,
    rooms: [],
    serverUrl: null,
    started: false,
    status: Statuses.ONLINE,
    username: null,
};


/**
 * EXPORTS
 */
export default initialState;
