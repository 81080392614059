/**
 * IMPORTS
 */
import {ICommand} from 'src/aggregates/index.d';
import {_aggregates} from 'src/aggregates/index.d';
import {IConfig} from 'src/aggregates/user/state.d';
import {Statuses} from 'src/aggregates/user/state.d';


/**
 * EXPORTS
 */
export enum _names
{
    USER_CLEAR_POPUP = 'USER_CLEAR_POPUP',
    USER_LOAD_CONFIG = 'USER_LOAD_CONFIG',
    USER_LOGIN = 'USER_LOGIN',
    USER_LOGIN_TOKEN = 'USER_LOGIN_TOKEN',
    USER_LOGOUT = 'USER_LOGOUT',
    USER_NOTIFY = 'USER_NOTIFY',
    USER_SHOW_POPUP = 'USER_SHOW_POPUP',
    USER_SET_SERVER_URL = 'USER_SET_SERVER_URL',
    USER_START = 'USER_START',
    USER_UPDATE_CONFIG = 'USER_UPDATE_CONFIG',
    USER_UPDATE_STATUS = 'USER_UPDATE_STATUS',
}

export interface IUserLoadConfig extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_LOAD_CONFIG;
}

export interface IUserClearPopup extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_CLEAR_POPUP;
}

export interface IUserLogin extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_LOGIN;
    password: string;
    isAdmin: boolean;
    username: string;
}

export interface IUserLoginToken extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_LOGIN_TOKEN;
    role: string;
    token: string;
}

export interface IUserLogout extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_LOGOUT;
}

export interface IUserNotify extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_NOTIFY;
    message: string;
    reference: number;
    requireInteraction: boolean;
    title: string;
}

export interface IUserSetServerUrl extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_SET_SERVER_URL;
    serverUrl: string;
}

export interface IUserShowPopup extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_SHOW_POPUP;
    level: string;
    message: string;
    title: string;
}

export interface IUserStart extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_START;
}

export interface IUserUpdateConfig extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_UPDATE_CONFIG;
    config: IConfig;
}

export interface IUserUpdateStatus extends ICommand
{
    _aggregate: _aggregates.USER;
    _name: _names.USER_START;
    status: Statuses;
}
