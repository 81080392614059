/**
 * IMPORTS
 */
import React from 'react';
import {useMemo} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {Route} from 'react-router-dom';
import {Router} from 'react-router-dom';
import {Switch} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import Login from 'src/components/Login';
import mapStateToProps from 'src/config/mapstate';
import {Admin} from 'src/config/loadable/Pages';
import {Dashboard} from 'src/config/loadable/Pages';
import {Loading} from 'src/config/loadable/Pages';
import history from 'src/history';


/**
 * TYPES
 */
import {IPrivateRouteProps} from 'src/config/routes.d';
import {IRouteProps} from 'src/config/routes.d';


/**
 * CODE
 */

/**
 * I render the PrivateRoute component.
 *
 * :param component: component to render
 * :param isAuthorized: whether user is authorized or not
 * :param target: initially accessed url
 *
 * :returns: PrivateRoute component
 */
function PrivateRoute ({
    component: Component,
    isAuthorized,
    target,
    ...rest
}: IPrivateRouteProps): JSX.Element
{
    // get location state
    const location = useLocation();

    return (
        <Route
            {...rest}
            location={{...location, state: {...location.state, target}}}
            render={() =>
                isAuthorized ? (
                    <Component />
                ) : (
                    <Redirect to={{pathname: '/'}} />
                )}
        />
    );
}


/**
 * I render the Routes component.
 *
 * :param isAuthorized: whether user is authorized or not
 *
 * :returns: Routes component
 */
function Routes ({isAuthorized}: IRouteProps): JSX.Element
{
    // memoize initial target
    const target = useMemo(() => history.location.pathname, []);

    return (
        <Router history={history}>
            <Switch>
                <Route component={() => <Login />} exact path="/" />
                <PrivateRoute
                    component={() => <Loading />}
                    isAuthorized={isAuthorized}
                    path="/loading"
                />
                <PrivateRoute
                    component={() => <Dashboard />}
                    isAuthorized={isAuthorized}
                    path="/dashboard"
                    target={target}
                />
                <PrivateRoute
                    component={() => <Admin />}
                    isAuthorized={isAuthorized}
                    path="/admin"
                    target={target}
                />
            </Switch>
        </Router>
    );
}

const RoutesConnect = connect(mapStateToProps)(Routes);


/**
 * EXPORTS
 */
export default RoutesConnect;
