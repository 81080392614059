/**
 * IMPORTS
 */
import {Logger} from 'loguis';
import {messages} from 'src/messages';


/**
 * TYPES
 */
import {ILogger} from 'src/config/Metadata/index.d';


/**
 * GLOBALS
 */
let logger = null;


/**
 * CODE
 */

/**
 * I create the logger.
 *
 * :returns: nothing
 */
function createLogger(config: ILogger): void
{
    // config provided: create logger
    logger = new Logger(messages, config);
}


/**
 * EXPORTS
 */
export {
    createLogger,
    logger,
};
