/**
 * IMPORTS
 */
import {initialPreferences} from 'src/aggregates/preferences/initialstate';
import {initialState} from 'src/aggregates/preferences/initialstate';
import Store from 'src/store';


/**
 * TYPES
 */
import * as Events from 'src/aggregates/preferences/events.d';
import {types} from 'src/aggregates/preferences/events.d';
import {IPreferencesReducer} from 'src/aggregates/preferences/reducer.d';
import {IPreferencesState} from 'src/aggregates/preferences/state.d';


/**
 * CODE
 */

/**
 * Preferences events map
 */
const eventsMap: IPreferencesReducer = {

    /**
     * I set preferences state when added auto message.
     *
     * :param state: preferences state
     * :param event: preferences added auto message event
     *
     * :returns: preferences state
     */
    [types.PREFERENCES_ADDED_AUTO_MESSAGE]: (
        state: IPreferencesState,
        event: Events.IPreferencesAddedAutoMessage,
    ): IPreferencesState =>
    {
        // get user preferences
        const userPreferences = {...state.byId[event._target]};

        // add new auto message
        userPreferences.autoMessages.byId[event.messageId] = event.message;

        // return new preferences state
        return {
            ...state,
            byId: {
                ...state.byId,
                [event._target]: userPreferences,
            },
        };
    },


    /**
     * I set preferences state when email page size set.
     *
     * :param state: preferences state
     * :param event: preferences email page size set event
     *
     * :returns: preferences state
     */
    [types.PREFERENCES_EMAIL_PAGE_SIZE_SET]: (
        state: IPreferencesState,
        event: Events.IPreferencesEmailPageSizeSet,
    ): IPreferencesState =>
    {
        // get user preferences
        const userPreferences = {...state.byId[event._target]};

        // set email page size
        userPreferences.emailPageSize = event.size;

        // return new preferences state
        return {
            ...state,
            byId: {
                ...state.byId,
                [event._target]: userPreferences,
            },
        };
    },


    /**
     * I set preferences state when user feedback updates.
     *
     * :param state: preferences state
     * :param event: preferences user feedback set event
     *
     * :returns: preferences state
     */
    [types.PREFERENCES_FEEDBACK_SET]: (
        state: IPreferencesState,
        event: Events.IPreferencesFeedbackSet,
    ): IPreferencesState =>
    {
        // get user preferences
        const userPreferences = {...state.byId[event._target]};

        // set user feedback
        userPreferences.feedback = event.feedback;

        // return new preferences state
        return {
            ...state,
            byId: {
                ...state.byId,
                [event._target]: userPreferences,
            },
        };
    },


    /**
     * I set preferences status when removed auto message.
     *
     * :param state: preferences state
     * :param event: preferences removed auto message event
     *
     * :returns: preferences state
     */
    [types.PREFERENCES_REMOVED_AUTO_MESSAGE]: (
        state: IPreferencesState,
        event: Events.IPreferencesRemovedAutoMessage,
    ): IPreferencesState =>
    {
        // get user preferences
        const userPreferences = {...state.byId[event._target]};

        // remove auto message
        delete userPreferences.autoMessages.byId[event.message];

        // return new preferences state
        return {
            ...state,
            byId: {
                ...state.byId,
                [event._target]: userPreferences,
            },
        };
    },


    /**
     * I set preferences state when set current auto message.
     *
     * :param state: preferences state
     * :param event: preferences set current auto message event
     *
     * :returns: preferences state
     */
    [types.PREFERENCES_SET_CURRENT_AUTO_MESSAGE]: (
        state: IPreferencesState,
        event: Events.IPreferencesSetCurrentAutoMessage,
    ): IPreferencesState =>
    {
        // get user preferences
        const userPreferences = {...state.byId[event._target]};

        // set current auto message
        userPreferences.autoMessages.current = event.message;

        // return new preferences state
        return {
            ...state,
            byId: {
                ...state.byId,
                [event._target]: userPreferences,
            },
        };
    },


    /**
     * I set preferences state when start.
     *
     * :param state: preferences state
     * :param event: preferences start event
     *
     * :returns: preferences state
     */
    [types.PREFERENCES_STARTED]: (
        state: IPreferencesState,
        event: Events.IPreferencesStarted,
    ): IPreferencesState => ({
        ...state,
        byId: {
            ...state.byId,
            [event._target]: {
                ...initialPreferences,
                ...state.byId[event._target] || {},
            },
        },
    }),


    /**
     * I update preferences mute notifications when switched.
     *
     * :param state: preferences state
     * :param event: preferences mute notifications switched event
     *
     * :returns: preferences state
     */
    [types.PREFERENCES_SWITCHED_MUTE_NOTIFICATIONS]: (
        state: IPreferencesState,
        event: Events.IPreferencesSwitchedMuteNotifications,
    ): IPreferencesState => ({
        ...state,
        byId: {
            ...state.byId,
            [event._target]: {
                ...state.byId[event._target],
                muteNotifications: event.muteNotifications,
            },
        },
    }),


    /**
     * I update preferences paused when switched.
     *
     * :param state: preferences state
     * :param event: preferences paused switched event
     *
     * :returns: preferences state
     */
     [types.PREFERENCES_SWITCHED_PAUSED]: (
        state: IPreferencesState,
        event: Events.IPreferencesSwitchedPaused,
    ): IPreferencesState => ({
        ...state,
        byId: {
            ...state.byId,
            [event._target]: {
                ...state.byId[event._target],
                paused: event.paused,
            },
        },
    }),


    /**
     * I update preferences wallet reminder when switched.
     *
     * :param state: preferences state
     * :param event: preferences wallet reminder switched event
     *
     * :returns: preferences state
     */
    [types.PREFERENCES_SWITCHED_WALLET_REMINDER]: (
        state: IPreferencesState,
        event: Events.IPreferencesSwitchedWalletReminder,
    ): IPreferencesState => ({
        ...state,
        byId: {
            ...state.byId,
            [event._target]: {
                ...state.byId[event._target],
                walletReminder: event.walletReminder,
            },
        },
    }),


    /**
     * I set preferences state when a tag color is updated.
     *
     * :param state: preferences state
     * :param event: preferences updated tag color event
     *
     * :returns: preferences state
     */
    [types.PREFERENCES_TAG_COLOR_SET]: (
        state: IPreferencesState,
        event: Events.IPreferencesTagColorSet,
    ): IPreferencesState => ({
        ...state,
        byId: {
            ...state.byId,
            [event._target]: {
                ...state.byId[event._target],
                tagColors: {
                    ...state.byId[event._target].tagColors,
                    [event.tag]: event.color,
                },
            },
        },
    }),


    /**
     * I set preferences state on tour disabled event.
     *
     * :param state: preferences state
     * :param event: preferences tour disabled event
     *
     * :returns: preferences state
     */
    [types.PREFERENCES_TOUR_DISABLED]: (
        state: IPreferencesState,
        event: Events.IPreferencesTourDisabled,
    ): IPreferencesState =>
    {
        // get user preferences
        const userPreferences = {...state.byId[event._target]};

        // set email page size
        userPreferences.tour = false;

        // return new preferences state
        return {
            ...state,
            byId: {
                ...state.byId,
                [event._target]: userPreferences,
            },
        };
    },
};


/**
 * Create reducer
 */
const reducer = Store.createReducer(initialState, eventsMap);


/**
 * EXPORTS
 */
export default reducer;
