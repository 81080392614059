/**
 * IMPORTS
 */
import merge from 'lodash/merge';
import * as events from 'src/aggregates/user/events';
import {authenticate} from 'src/aggregates/user/utils';
import {authenticateToken} from 'src/aggregates/user/utils';
import {authorize} from 'src/aggregates/user/utils';
import {getUserConfig} from 'src/aggregates/user/utils';
import {getServer} from 'src/aggregates/user/utils';
import {reduceQuickMessages} from 'src/aggregates/user/utils';
import {start as userStart} from 'src/aggregates/user/utils';
import {updateUserConfig} from 'src/aggregates/user/utils';
import {logger} from 'src/logger';
import GraphisClient from 'src/services/graphis';


/**
 * TYPES
 */
import {IAppState} from 'src/aggregates/index.d';
import {IEvent} from 'src/aggregates/index.d';
import {IUserClearPopup} from 'src/aggregates/user/commands.d';
import {IUserLoadConfig} from 'src/aggregates/user/commands.d';
import {IUserLogin} from 'src/aggregates/user/commands.d';
import {IUserLoginToken} from 'src/aggregates/user/commands.d';
import {IUserLogout} from 'src/aggregates/user/commands.d';
import {IUserNotify} from 'src/aggregates/user/commands.d';
import {IUserSetServerUrl} from 'src/aggregates/user/commands.d';
import {IUserShowPopup} from 'src/aggregates/user/commands.d';
import {IUserStart} from 'src/aggregates/user/commands.d';
import {IUserUpdateConfig} from 'src/aggregates/user/commands.d';
import {IUserUpdateStatus} from 'src/aggregates/user/commands.d';
import {ICompanyConfig} from 'src/aggregates/user/state.d';
import {loginErrors} from 'src/aggregates/user/state.d';
import {ILoginTokenResponse} from 'src/aggregates/user/utils.d';
import {PreAuthorizationError}
    from 'src/aggregates/user/err/preauthorizationerror';


/**
 * CONSTANTS AND DEFINITIONS
 */
const LOCAL_STORAGE_SERVER_KEY = '@pecazap:server';
const LOCAL_STORAGE_SESSION_TOKEN = '@pecazap:token';


/**
 * CODE
 */

/**
 * I am an user clear popup command handler.
 *
 * :param command: user clear popup command
 * :param state: application state
 *
 * :generates: events
 */
async function* clearPopup (command: IUserClearPopup,
                            state: IAppState): AsyncIterable<IEvent>
{
    yield events.userPopupCleared();
}


/**
 * I am a user config command handler.
 *
 * :param command: user config command
 * :param state: application state
 *
 * :generates: events
 */
async function* loadConfig (command: IUserLoadConfig,
                            state: IAppState): AsyncIterable<IEvent>
{
    // emit loading event
    yield events.userConfigLoading();

    // get user config
    const config = await getUserConfig();

    // user config not found: emit not loaded event and return
    if (config === null)
    {
        yield events.userConfigNotLoaded();
        return;
    }

    // reduce quick messages
    config.messages = reduceQuickMessages(
        config.messages,
        state.user.isAdmin
    )

    // emit loaded event
    yield events.userConfigLoaded(config);
}


/**
 * I am an user login command handler.
 *
 * :param command: user login command
 * :param state: application state
 *
 * :generates: events
 */
async function* login (command: IUserLogin,
                       state: IAppState): AsyncIterable<IEvent>
{
    // generate user logging in event
    yield events.userLoggingIn();

    // initialize last logged server
    let server: string;

    // get command props
    const {isAdmin, password, username} = command;

    // log login try
    logger.info(
        'CVJPZ0047I',
        {
            role: isAdmin === true ? 'supervisor' : 'agent',
            user: username,
        },
    );

    // get server to authenticate with
    try
    {
        server = await getServer(isAdmin, password, username);
    }

    // no server responded: log error, generate failure and return
    catch (error)
    {
        // log error
        logger.error('CVJPZ0044E', {reason: error, user: username});

        // initialize error reason
        let reason = loginErrors.AUTHENTICATION;

        // authorization error: update error reason
        if (error instanceof PreAuthorizationError)
        {
            reason = loginErrors.AUTHORIZATION;
        }

        // other error: clear last server from local storage
        else
        {
            localStorage.removeItem(LOCAL_STORAGE_SERVER_KEY);
        }

        // generate failure event and return
        yield events.userNotLoggedIn(reason);
        return;
    }

    // add last server to local storage
    localStorage.setItem(LOCAL_STORAGE_SERVER_KEY, server);

    // format base url
    const url = `https://${server}`;

    // request authentication on XMPP
    const authError = await authenticate(password, url, username);

    // authentication failed: generate user not logged event in and return
    if (authError !== null)
    {
        // generate user not logged event in
        yield events.userNotLoggedIn(loginErrors.AUTHENTICATION);

        // clear last server from local storage and return
        localStorage.removeItem(LOCAL_STORAGE_SERVER_KEY);
        return;
    }

    // authentication succeeded: authorize
    const authorization = await authorize(isAdmin, server, username);

    // authorization failed: generate user not logged in event and return
    if (authorization === null)
    {
        yield events.userNotLoggedIn(loginErrors.AUTHORIZATION);
        return;
    }

    // authorization succeeded: retrieve company configuration
    const partialCompanyConfig = authorization?.data?.companies
        ?.[authorization.user.company] || {};

    // initialize with default config
    const companyConfig: ICompanyConfig = {
        visibility: {
            customers: {noWallet: true, onlyOwn: false},
        },
    };

    // merge company configs
    merge(companyConfig, partialCompanyConfig);

    // save last url logged in
    localStorage.setItem(LOCAL_STORAGE_SERVER_KEY, server);

    // save session token
    localStorage.setItem(LOCAL_STORAGE_SESSION_TOKEN, authorization.user.token);

    // create graphis client with connected base url
    GraphisClient.create(server,
                         authorization.user.company,
                         authorization.user.token);

    // generate user server url set event
    yield events.userServerUrlSet(url);

    // generate user logged in event and return
    yield events.userLoggedIn(
        authorization.user.id,
        authorization.user.company,
        companyConfig,
        authorization.user.name,
        authorization.rooms,
        username,
        isAdmin,
    );
}


/**
 * I am an user login token command handler.
 *
 * :param command: user login token command
 * :param state: application state
 *
 * :generates: events
 */
async function* loginToken (command: IUserLoginToken,
                            state: IAppState): AsyncIterable<IEvent>
{
    // generate user logging in event
    yield events.userLoggingIn();

    // get command props
    let {role, token} = command;

    // make role from plural to singular
    role = role.slice(0,-1);

    // get is Admin variable
    const isAdmin = role === 'supervisor' ? true : false;

    // get last server from local storage
    const server = localStorage.getItem(LOCAL_STORAGE_SERVER_KEY);

    // servir key does not exist: fail login
    if (server == null)
    {
        // clear last token from local storage
        localStorage.removeItem(LOCAL_STORAGE_SESSION_TOKEN);

        // generate user not logged in event
        yield events.userNotLoggedIn(loginErrors.AUTHENTICATION);
        return;
    }

    // initialize last logged server
    let response: ILoginTokenResponse;

    // authenticate with token
    try
    {
        response = await authenticateToken(role, server, token);
    }

    // authentication failed: generate failure and return
    catch (error)
    {
        // clear last token from local storage
        localStorage.removeItem(LOCAL_STORAGE_SESSION_TOKEN);

        // generate user not logged in event
        yield events.userNotLoggedIn(loginErrors.AUTHENTICATION);
        return;
    }

    // get user params
    const {password, username} = response;

    //get new token
    token = response.token;

    // save new token on local storage
    localStorage.setItem(LOCAL_STORAGE_SESSION_TOKEN, token);

    // format base url
    const url = `https://${server}`;

    // request authentication on XMPP
    const authError = await authenticate(password, url, username);

    // authentication failed: generate user not logged in event and return
    if (authError !== null)
    {
        // clear last token from local storage
        localStorage.removeItem(LOCAL_STORAGE_SESSION_TOKEN);

        // generate user not logged in event
        yield events.userNotLoggedIn(loginErrors.AUTHENTICATION);
        return;
    }

    // authentication succeeded: authorize
    const authorization = await authorize(isAdmin, server, username);

    // authorization failed: generate user not logged in event and return
    if (authorization === null)
    {
        // clear last token from local storage
        localStorage.removeItem(LOCAL_STORAGE_SESSION_TOKEN);

        // generate user not logged in event
        yield events.userNotLoggedIn(loginErrors.AUTHORIZATION);
        return;
    }

    // authorization succeeded: retrieve company configuration
    const partialCompanyConfig = authorization?.data?.companies
        ?.[authorization.user.company] || {};

    // initialize with default config
    const companyConfig: ICompanyConfig = {
        visibility: {
            customers: {noWallet: true, onlyOwn: false},
        },
    };

    // merge company configs
    merge(companyConfig, partialCompanyConfig);

    // save last url logged in
    localStorage.setItem(LOCAL_STORAGE_SERVER_KEY, server);

    // save session token
    localStorage.setItem(LOCAL_STORAGE_SESSION_TOKEN, authorization.user.token);

    // create graphis client with connected base url
    GraphisClient.create(server,
                         authorization.user.company,
                         authorization.user.token);

    // generate user server url set event
    yield events.userServerUrlSet(url);

    // generate user logged in event and return
    yield events.userLoggedIn(
        authorization.user.id,
        authorization.user.company,
        companyConfig,
        authorization.user.name,
        authorization.rooms,
        username,
        isAdmin,
    );
}


/**
 * I am an user logout command handler.
 *
 * :param command: user logout command
 * :param state: application state
 *
 * :generates: events
 */
async function* logout (command: IUserLogout,
                        state: IAppState): AsyncIterable<IEvent>
{
    // remove session token from local storage
    localStorage.removeItem(LOCAL_STORAGE_SESSION_TOKEN);

    // generate user logged out event
    yield events.userLoggedOut();
}


/**
 * I am an user notify command handler.
 *
 * :param command: user notify command
 * :param state: application state
 *
 * :generates: events
 */
async function* notify (command: IUserNotify,
                        state: IAppState): AsyncIterable<IEvent>
{
    // generate user notified event
    yield events.userNotified(command.message,
                              command.reference,
                              command.requireInteraction,
                              command.title);
}


/**
 * I am an user set server url command handler.
 *
 * :param command: user set server url command
 * :param state: application state
 *
 * :generates: events
 */
async function* setServerUrl (
    command: IUserSetServerUrl,
    state: IAppState,
): AsyncIterable<IEvent>
{
    yield events.userServerUrlSet(command.serverUrl);
}


/**
 * I am an user show popup command handler.
 *
 * :param command: user show popup command
 * :param state: application state
 *
 * :generates: events
 */
async function* showPopup (command: IUserShowPopup,
                           state: IAppState): AsyncIterable<IEvent>
{
    yield events.userPopupShown(command.level,
                                command.message,
                                command.title);
}


/**
 * I am an user start command handler.
 *
 * :param command: user start command
 * :param state: application state
 *
 * :generates: events
 */
async function* start (command: IUserStart,
                       state: IAppState): AsyncIterable<IEvent>
{
    // emit starting event
    yield events.userStarting();

    // start application
    const result = await userStart(state.user.id, state.user.rooms);

    // start failed: emit event and abort
    if (result === null)
    {
        yield events.userNotStarted();
        return;
    }

    // start successful: emit user started event
    yield events.userStarted();
}


/**
 * I am a user config update command handler.
 *
 * :param command: load command
 * :param state: application state
 *
 * :generates: events
 */
async function* updateConfig (
    command: IUserUpdateConfig,
    state: IAppState,
): AsyncIterable<IEvent>
{
    // emit updating event
    yield events.userConfigUpdating();

    // update user config
    const config = await updateUserConfig(command.config);

    // update user config failed: emit not updated event and return
    if (config === null)
    {
        yield events.userConfigNotUpdated();
        return;
    }

    // emit updated event
    yield events.userConfigUpdated(config);
}


/**
 * I am an user update status command handler.
 *
 * :param command: user update status command
 * :param state: application state
 *
 * :generates: events
 */
async function* updateStatus (command: IUserUpdateStatus,
                              state: IAppState): AsyncIterable<IEvent>
{
    yield events.userStatusUpdated(command.status);
}


/**
 * EXPORTS
 */
export {
    clearPopup,
    loadConfig,
    login,
    loginToken,
    logout,
    notify,
    setServerUrl,
    showPopup,
    start,
    updateConfig,
    updateStatus,
};
